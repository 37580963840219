<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we create your CII FACE Applicant Account!</p>
</ngx-spinner>
<section class="main-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="food-img-bg">
          <div class="top-logo"> <img src="../../assets/img/CII-Logo.png" alt="" class="cii-logo"> <img
              src="../../assets/img/CII-Face.png" alt="" class="cii-face"> </div>
          <div class="main-bg-con">
            <div class="row g-0">
              <div class="col-lg-8">
                <div class="left-content">
                  <h3>Welcome to <br>Food Safety Award</h3>
                  <span>Assessment Portal</span>
                  <p>The Award is one of its kind and focuses on Food Safety Maturity Assessment to promote excellence.
                    Since its inception in 2010, the CII Food Safety Award has become a symbol of credibility and pride
                    for Food Business operators. More than recognition, the Awards measure performance and provide
                    detailed feedback from an external perspective on opportunities to excel.</p>
                  <p>Please <a href="/login">login</a> to continue with your application if you have already created
                    your account.</p>
                  <h6>Awarding excellence in Food Safety!</h6>
                  <h5 class="moreDetail">For more details, please contact:</h5>
                  <h5>CII Food Safety Award Secretariat</h5>
                  <h5 class="colorBlue"><a href="mailto:fs.award@cii.in" target="_blank">fs.award@cii.in</a></h5>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="login-sec" *ngIf="!congPop && !maintainance">
                  <h2>Let's get started</h2>
                  <p>Create your account with CII FACE Assessment portal and complete your organization information</p>
                  <div class="login-sec-con">
                    <form [formGroup]="appForm" (ngSubmit)="clickFunction()">
                      <div class="col-md-12">
                        <!-- <div class="form-group userRadio">
                          <input type="radio"  formControlName="type" value="applicant"> Applicant
                          <input type="radio"  formControlName="type" value="assessor"> Assessor
                        </div> -->
                        <div class="ApplicantAssessorBtn form-group userRadio">
                          <div class="form-check form-check-inline Applicant">
                            <input type="radio" formControlName="type" value="applicant" id="appliCheck">
                            <label class="form-check-label" for="appliCheck">
                              <div class="appAss"> <img src="../../assets/img/Applicant-3.png" alt="">
                                <p>Applicant</p>
                              </div>
                            </label>
                          </div>
                          <div class="form-check form-check-inline Applicant">
                            <input type="radio" formControlName="type" value="assessor" id="assCheck">
                            <label class="form-check-label" for="assCheck">
                              <div class="appAss"> <img src="../../assets/img/Assessor.png" alt="">
                                <p>Assessor</p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control user-bg" id="email" formControlName="username"
                            placeholder="Full Name">
                        </div>
                        <div *ngIf="f.username.errors && f.username.errors.required && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.username.errors.required"><small>Name is required</small></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="email" formControlName="email" class="form-control email-bg email" id="email"
                            placeholder="Email">
                        </div>
                        <div *ngIf="f.email.errors && f.email.errors.required && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.email.errors.required"><small>Email is required</small></div>
                        </div>
                        <div *ngIf="f.email.errors && f.email.errors.pattern && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.email.errors.pattern"><small>Email is not valid</small></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="number" class="form-control mobile-bg" formControlName="mobile"
                            placeholder="Mobile No." type="text" maxlength="10" minlength="10"
                            (keypress)=_keyPress($event)>
                        </div>
                        <div *ngIf="f.mobile.errors && f.mobile.errors.required && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.mobile.errors.required"><small>Mobile No. is required</small></div>
                        </div>
                        <div *ngIf="f.mobile.errors && f.mobile.errors.pattern && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.mobile.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="password" class="form-control user-password" id="password" placeholder="Password"
                            formControlName="password">
                        </div>
                        <div *ngIf="f.password.errors && f.password.errors.required && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.password.errors.required"><small>Password is
                              required.</small>
                          </div>
                          <div *ngIf="f.password.errors.pattern"><small>Minimum 8 character password.</small></div>
                          <div *ngIf="f.password.errors.pattern"><small>Use uppercase and lowecase
                              letters(e.g.Aa)</small></div>
                          <div *ngIf="f.password.errors.pattern"><small>Use a number(e.g 123)</small></div>
                          <div *ngIf="f.password.errors.pattern"><small>Use special character (e.g @#%).</small></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="password" class="form-control confirm-password" id="confirm_password"
                            placeholder="Confirm Password" formControlName="confirm_password">
                        </div>
                        <div
                          *ngIf="f.confirm_password.errors && f.confirm_password.errors.required && isValidFormSubmitted"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.confirm_password.errors.required"><small>Confirm Password is required.</small>
                          </div>
                        </div>
                        <div *ngIf="f.confirm_password.errors && f.confirm_password.errors.mustMatch"
                          class="text-danger text-left mb-1 errorcontrols">
                          <div *ngIf="f.confirm_password.errors.mustMatch"><small>Password and Confirm Password are not
                              matching.</small></div>
                        </div>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-lg btn-block login_btn">Sign Up</button>
                      </div>
                      <p class="Create_Account text-center mt-2">Already have an account?<a href="/login"> Login
                          Here</a></p>
                    </form>
                  </div>
                </div>
                <div class="login-sec" *ngIf="congPop">
                  <br />
                  <br /><br /><br /><br />
                  <div class="card-body text-center">
                    <img src="https://img.icons8.com/bubbles/200/000000/trophy.png" style="width: 50%" />
                    <h4>CONGRATULATIONS!</h4>
                    <br />
                    <p style="font-size: 1.5rem;color: #739002;">Dear <b>{{ this.appForm.value.username
                        }}</b>, Thank you for signing up for CII FACE Application. Your account has been successfully
                      activated.</p>
                    <br />
                    <p>Kindly login to complete your application.</p>
                  </div>
                </div>
                <div class="login-sec" *ngIf="maintainance">
                  <br />
                  <br /><br /><br /><br />
                  <div class="card-body text-center">
                    <img src="../../assets/img/maintanance.png" style="width: 50%" />
                    <h4>System Under Maintenance!</h4>
                    <br />
                    <p style="font-size: 1.5rem;color: #739002;">We are improving your experience and shall be back by
                      2100 Hours (IST). Kindly visit again!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="troo-logo"> <a href="https://www.troology.com/" target="_blank"><img src="../../assets/img/Footer.svg" alt=""></a> </div>-->
      </div>
    </div>
  </div>
</section>