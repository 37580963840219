import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { TosterService } from '../services/toster.service';
import { CoApplicantService } from '../services/co-applicant.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-score-report',
  templateUrl: './score-report.component.html',
  styleUrls: ['./score-report.component.css'],
})
export class ScoreReportComponent implements OnInit {
  applicant_id: any;
  section: any;
  criteria: any = [];
  app_email: any;
  questions: any;
  subsection: any;
  answer: any;
  public model: any = {};
  modall: any = [];
  public modelscore: any = {};
  modallscore: any = [];
  udata: any;
  section_no: any;
  allocated_id: any;
  subSections: any;
  assessment_data: any;
  all_scores: any = [];
  scoreOne: any;
  SubSecmodel: any = {};
  tl: any;
  role: any;
  disableStatus: boolean = true;
  cosection: any = [];
  activeTab: string = 'applicant';
  sectionData: any = [];
  allocations: any = [];
  suggestionScore: any[] = [{}];
  saveCons: boolean = false;
  enableAddConsensus: boolean = false;
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private coapp: CoApplicantService
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.role = localStorage.getItem('role');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.applicant_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.allocated_id = this._Activatedroute.snapshot.paramMap.get('_id');
    this.tl = this._Activatedroute.snapshot.paramMap.get('tl');
    this.applicant
      .GetAdminApplicantSingle(this.applicant_id)
      .subscribe((data: any) => {
        if (
          data.applicanData[0]?.criteria != '' &&
          data.applicanData[0]?.userStatus === true
        ) {
          this.criteria = [data.applicanData[0].criteria];
          this.app_email = data.applicanData[0]?.email;
          this.quest
            .sectionGroupbyCriteria({
              criteria: this.criteria[0],
            })
            .subscribe((elem: any) => {
              this.section = elem;
              this.coapp.listCoApplicant(data.applicanData[0]._id).subscribe((resp: any) => {
                if (resp.result.length) {
                  let criterias = resp.result[0].co_criteria;
                  this.criteria = this.criteria.concat(criterias)
                }
                this.spinner.hide();
              });
            });
        } else {
        }
      });
  }
  switchTab(userType: any) {
    this.saveConsensusScore(false, 'suggestion');
    let criteria = this.criteria[0]
    if (userType == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show();
    this.activeTab = userType;
    this.sectionData = [];
    this.SubSecmodel = {};
    this.section_no = '';
    this.quest
      .sectionGroupbyCriteria({
        criteria: criteria,
      })
      .subscribe((elem: any) => {
        this.section = elem;
        this.spinner.hide();
      });
  }
  getQuestion(sec: any) {
    this.saveConsensusScore(false, 'suggestion');
    let criteria = this.criteria[0]
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show();
    this.section_no = sec;
    this.quest.getConsensus({
      criteria: criteria,
      section_no: sec,
      allocation_id: this.allocated_id
    }).subscribe((response: any) => {
      
      this.sectionData = response.sub_section
      this.sectionData.sort((a: any, b: any) => {
        const numA = parseInt(a.sub_section_no.match(/\d+/)[0], 10);
        const numB = parseInt(b.sub_section_no.match(/\d+/)[0], 10);
        const prefixA = a.sub_section_no.match(/[^\d]+/)?.[0];
        const prefixB = b.sub_section_no.match(/[^\d]+/)?.[0];
        if (prefixA === prefixB) {
          return numA - numB;
        }
        return prefixA.localeCompare(prefixB);
      });
      
      this.allocations = response.allocations
      let teamlead = this.allocations.find((allc: any) => allc.teamleader) || {};
      let calb = this.allocations.find((allc: any) => allc.calibrator) || {};
      if (this.udata.role.includes('admin') || this.udata.email == teamlead.email || this.udata.email == calb.email) {
        this.saveCons = true;
      }
      this.disableStatus = this.udata.role.includes('admin') || this.udata.email == teamlead.email || this.udata.email == calb.email ? false : true;
      this.suggestionScore = [{}];
      this.SubSecmodel = {};
      this.sectionData.forEach((data: any) => {
        this.SubSecmodel[data.sub_section_no] = data.conscore
        this.suggestionScore[0][data.sub_section_no] = data.suggestionScore || {}
      })
      this.spinner.hide()
    })
  }
  getScoreBySubSection(subSectionNo: any, email: any) {
    this.scoreOne = this.all_scores.find((a: any) => a[0]?.scoreBy == email);
    if (this.scoreOne === undefined) {
      return 'N/A';
    }
    else {
      return this.scoreOne[0].score[0][subSectionNo];
    }
  }
  // getScoreBySubSectionApplicable(subSectionNo: any, email: any, tl: any) {
  //   if (tl) {
  //     this.scoreOne = this.all_scores.find((a: any) => a[0]?.scoreBy == email);
  //     if (this.scoreOne === undefined)
  //       this.disableStatus[subSectionNo] = false;
  //     else
  //       this.disableStatus[subSectionNo] = this.scoreOne[0].applicable[0][subSectionNo];
  //   }
  //   return this.getScoreBySubSection(subSectionNo, email)
  // }
  getModelScore(e: any, subSectionNo: any) {
    this.SubSecmodel[subSectionNo] = e.target.value;
  }
  getSuggestScore(e: any, subSectionNo: any, email: any) {
    this.suggestionScore[0][subSectionNo][email] = e.target.value;
  }
  saveConsensusScore(showToast = true, saveArea: string) {
    let criteria = this.criteria[0]
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    let data: any = {
      allocation_id: this.allocated_id,
      criteria: criteria,
      section_no: this.section_no,
      workingUser: this.udata.email,
      saveCons: this.saveCons && this.enableAddConsensus
    }
    if (saveArea == 'consensus' && this.saveCons && this.enableAddConsensus) {
      data['Conscore'] = this.SubSecmodel
    } else if (saveArea == 'suggestion') {
      data['suggestionScore'] = this.suggestionScore[0]
    }
    if (this.section_no) {
      this.spinner.show();
      this.quest
        .addConsensusScore(data)
        .subscribe((resp: any) => {
          if (showToast) {
            this.toastr.success(resp.message)
          }
          this.spinner.hide();
        });
    }
  }
  getConsensus(data: any) {
    if (this.SubSecmodel[data] > 100) {
      this.SubSecmodel[data] = 100
    }
    else {
      this.SubSecmodel[data] = Math.round(this.SubSecmodel[data] / 5) * 5
    }
  }
  roundoff(data: any, email: any) {
    if (this.suggestionScore[0][data][email] > 95) {
      this.suggestionScore[0][data][email] = 95
    } else if (this.suggestionScore[0][data][email] < 5) {
      this.suggestionScore[0][data][email] = 5
    } else {
      this.suggestionScore[0][data][email] = Math.round(this.suggestionScore[0][data][email] / 5) * 5
    }
  }
  checkDisability(data: any): boolean {
    if (!this.enableAddConsensus) {
      return true;
    }
    const suggestions = Object.values(data).filter(item => item !== null)
    if (suggestions.length != this.allocations.length) return true;
    return false;
  }
  isNum(val: any): boolean {
    if (typeof val == 'number') return true;
    return false;
  }
  enableConsensus() {
    if (!this.enableAddConsensus) {
      this.saveConsensusScore(false, 'suggestion');
    }
    this.enableAddConsensus = true;
  }
}
