<app-header></app-header>
<app-sidenav-applicant></app-sidenav-applicant>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Applicant Dashboard</h2>
    <!-- <div class="card">
      <img src="../../../assets/img/background-default.png" style="width:40%;" />
      <p>This section shall be available once your Application is accepted and the assessment is undertaken.</p>
    </div> -->
    <section id="applicant-db">
      <div class="applicant-section-table">
        <div class="applicant-section-title">
          <h5>CII FACE Award Application Summary</h5>
        </div>
        <table class="table" *ngIf="applicantData">
          <tbody>
            <tr>
              <td scope="row">1</td>
              <td class="m-child">Lol Submission</td>
              <td>{{ this.applicantData.updated_at|date:'dd-MM-yyyy' }}</td>
            </tr>
            <tr>
              <td scope="row">2</td>
              <td class="m-child">Introduction Form</td>
              <td>
                <p class="label label-{{introStatus?'success':'danger'}}">
                  {{introStatus?'Completed':'Pending'}}</p>
              </td>
            </tr>
            <tr>
              <td scope="row">3</td>
              <td class="m-child">Criteria</td>
              <td>{{ this.applicantData.criteria}}</td>
            </tr>
            <tr>
              <td scope="row">4</td>
              <td class="m-child">Criteria Checklist For Self Assessment</td>
              <td>
                <p class="label label-{{this.applicantData.finalassessmentsData.length==1?'success':'warning'}}">
                  {{this.applicantData.finalassessmentsData.length==1?'Completed':'Process'}}</p>
            </tr>
            <tr>
              <td scope="row">5</td>
              <td class="m-child">Assessment Schedule</td>
              <td>{{ this.scheduledata }}</td>
            </tr>
            <tr>
              <td scope="row">6</td>
              <td class="m-child">Onsite Assessment Lead Assessor</td>
              <td>{{ this.seniorAssessor }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>Additional Documents</h3>
      <a class="btn btn-primary" data-toggle="modal" data-target=".bs-example-modal-sm">Add Documents</a>
      <table class="table table-bordered">
        <thead>
          <tr>
            <!-- <th>Sno.</th> -->
            <th>File Name</th>
            <th>Download</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of appdoc;let i=index">
            <!-- <td *ngIf="item.file">{{ i+1 }}</td> -->
            <td *ngIf="item.file">{{ item.documentname }}</td>
            <td *ngIf="item.file"><a href="{{ item.file }}" target="_blank"><i class="fa fa-download"></i></a></td>
            <td *ngIf="item.file"><a (click)="deleteFile(item._id, i)"><i class="fa fa-trash"></i></a></td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</div>
<div class="modal fade bs-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="resetForm()" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Add Additional Documents</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="myForm" *ngIf="myForm">
          <div class="input-group">
            <label>Document Name *</label>
            <input formControlName="documentname" class="form-control" />
            <p *ngIf="myForm.invalid" style="color: red;">
              Document Name Required
            </p>
          </div><!-- /input-group -->
          <div class="input-group">
            <label>Document *</label>
            <div class="document-uploads">
              <input formControlName="file" id="file" type="file" class="form-control" (change)="handleUpload($event)">
              <div *ngIf="uploading" class="upload-loader">
                <progress value="{{ uploadProgress }}" max="100"></progress>
                <span style="text-wrap: nowrap;">Uploading... {{ uploadProgress }}%</span>
              </div>
            </div>
            <p *ngIf="myForm.invalid" style="color: red;">
              Document Required
            </p>
          </div><!-- /input-group -->
          <div class="input-group">
            <button class="btn btn-info" (click)="submit()" type="submit" [disabled]="!this.imageUrl"
              [class.disabled]="!this.imageUrl">Save</button>
          </div><!-- /input-group -->
        </form>
      </div>
    </div>
  </div>
</div>