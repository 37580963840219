import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApplicantService } from 'src/app/services/applicant.service';
@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {
  udata: any;
  applicantData: any;
  allocationlist: any;
  scheduledata: any = 'pending';
  imageUrl: any;
  myForm!: FormGroup;
  appdoc: any;
  id: any;
  constructor(
    private applicants: ApplicantService,
    private fb: FormBuilder,
    private _Activatedroute: ActivatedRoute,
  ) {
    this.id = this._Activatedroute.snapshot.paramMap.get('id');
  }
  ngOnInit(): void {
    this.applicants.getAppDoc(this.id).subscribe((datar: any) => {
      this.appdoc = datar
    });
    this.udata = JSON.parse(localStorage.getItem('userdata') || '{}');
  }
}
