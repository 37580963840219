import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { delay } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ciifaceproject';
  status$!: Observable<boolean>
  isShow: boolean = false;
  constructor(
    private loader: LoaderService) {
    this.status$ = this.loader.getLoadingState().pipe(delay(0));
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.isShow = true;
    }, 200);
  }
}
