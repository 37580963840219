<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h4 class="m-n font-bold "><i class="fa fa-angle-left" aria-hidden="true"></i> Edit Assessment Team</h4>
        </div>
        <div class="clearfix"></div>
        <div class="col-sm-12 col-xs-12">
          <div class="card" style="margin-top: 1.5%;padding-top: 1%;min-height: 500px;padding-bottom:1rem">
            <form [formGroup]="allocationForm" (ngSubmit)="submitAllocation()">
              <input type="hidden" formControlName="period_from" />
              <input type="hidden" formControlName="period_to" />
              <div class="col-md-6 form-group" style="display: none;">
                <label>Applicant </label>
                <p class="labelInfo">Select Applicant</p>
                <select class="form-control" formControlName="applicant_id" [attr.disabled]="true" >
                  <option value="">Select Applicant</option>
                  <option *ngFor="let item of this.applicantlist" value="{{ item._id }}" [selected]="this.applicantdata._id">{{ item.organizationName }} ({{
                    item.sector }})</option>
                </select>
                <div *ngIf="f.applicant_id.errors && f.applicant_id.errors.required && submitValid"
                  class="text-danger text-left mb-1">
                  <div *ngIf="f.applicant_id.errors.required">This field is required</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-12 form-group">
                <table style="border-collapse: collapse; width: 100%;" >
                  <tbody>
                    <tr>
                      <th class="bgProperty">Unit Name
                      </th>
                      <th class="bgProperty">Contact
                        Person</th>
                      <th class="bgProperty">Criteria
                      </th>
                      <th class="bgProperty">Address</th>
                      <th class="bgProperty">Mobile No
                      </th>
                      <th class="bgProperty">Email:</th>
                      <th class="bgProperty">Sector/Product</th>
                    </tr>
                    <tr>
                      <td class="tdProperty">{{ this.applicantdata.organizationName }}</td>
                      <td class="tdProperty">{{ this.applicantdata.applicanthightestRanking }}, {{
                        this.applicantdata.applicantDesignation }}</td>
                      <td class="tdProperty">{{ this.applicantdata.criteria }}</td>
                      <td class="tdProperty">{{ this.applicantdata.applicantAddress1 }}, {{
                        this.applicantdata.applicantCity }}</td>
                      <td class="tdProperty">{{ this.applicantdata.mobileNo }}</td>
                      <td class="tdProperty"><a href="javascript:void(0)" style="color: #87a63f;">{{
                          this.applicantdata.email }}</a></td>
                      <td class="tdProperty">{{ this.applicantdata.sector }}, {{ this.applicantdata.product }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12">
                <!-- Nav tabs -->
                <div class="card no-shadow m-b-none">
                  <ul class="nav nav-tabs nav-justified m-t-md" role="tablist">
                    <li role="presentation"><a href="#stockyard" aria-controls="stockyard" role="tab"
                        data-toggle="tab" class="g-font-weight-600 text-uppercase" aria-expanded="true">All
                        Assessors </a>
                    </li>
                    <li role="presentation"  class="active"><a href="#showroom" aria-controls="showroom" role="tab"
                        data-toggle="tab" class="g-font-weight-600 text-uppercase" aria-expanded="false">Selected
                        Assessors <span class="badge color">{{ this.selectedAssessor }}</span></a>
                    </li>
                  </ul>
                  <!-- Tab panes -->
                  <div class="tab-content no-padding">
                    <div role="tabpanel" class="tab-pane no-padding" id="stockyard">
                      <!-- 1st table -->
                      <div class="col-md-12 table-responsive">
                        <label class="h3 mt-1 teamLabel">All Assessors </label>
                        <table class="table table-dark table-bordered nowrap" id="allAssessment" datatable
                          [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                          <thead>
                            <tr>
                              <th></th>
                              <th scope="col">Assessor Name</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Present Organization</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">E-Mail</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of arraydata">
                              <td><input type="checkbox" name="websitecheck" value="{{ item._id }}"
                                  (click)="checkuncheckall($event,item)" *ngIf="!checkAvailablity(item._id)"></td>
                              <td>{{ item.firstName }}</td>
                              <td>{{ item.designation }}</td>
                              <td>{{ item.Organization }}</td>
                              <td>{{ item.assessorsPhone }}</td>
                              <td>{{ item.assessorsEmail }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div role="tabpanel" class="tab-pane active" id="showroom">
                      <!-- 2nd table -->
                      <div class="col-md-12 table-responsive">
                        <label class="h3 mt-1 teamLabel">Selected Assessors </label>
                        <table class="table table-dark table-bordered nowrap mt-1" datatable [dtOptions]="dtOptions1"
                          [dtTrigger]="dtTrigger1">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th scope="col">TL & Calibrator</th>
                              <th scope="col">Assessor Name</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Present Organization</th>
                              <th scope="col">Product / Service (Present Org)</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">E-Mail</th>
                              <th scope="col">Previous Company</th>
                              <th scope="col">Product / Service (Previous Org)</th>
                              <th scope="col">Education</th>
                              <th scope="col">Expertise</th>
                              <th scope="col">Skill</th>
                              <th scope="col">Training</th>
                              <th scope="col">Certificate</th>
                              <th scope="col">Experience</th>
                              <th scope="col"><i class="fa fa-trash"></i></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of allocated_array;let j=index">
                              <td>
                                <select class="form-control" (change)="getChangeSelect($event,item._id,'allocationliststatus')">
                                  <option value="pending" [selected]="item.allocationliststatus==='pending'">Pending</option>
                                  <option value="accepted" [selected]="item.allocationliststatus==='accepted'">Accepted</option>
                                  <option value="rejected" [selected]="item.allocationliststatus==='rejected'">Rejected</option>
                                </select>
                              </td>
                              <td><input type="radio" name="teamleader" value="yes"
                                  (click)="getClickRadio($event,item._id,'teamleader')" [checked]="item.teamleader" required /> TL <br/>
                                  <input type="radio" name="calibrator" value="yes"
                                  (click)="getClickRadio($event,item._id,'calibrator')"  [checked]="item.calibrator" required /> Calibrator
                              </td>
                              <td>{{ item.firstName }}</td>
                              <td>{{ item.designation }}</td>
                              <td>{{ item.Organization }}</td>
                              <td>
                                <span class="label label-pill label-primary"
                                  *ngFor="let item11 of item.organizationProductservice;let i = index">{{
                                  item11.item_text }}
                                  <br *ngIf="(i+1)%4==0" /></span>
                              </td>
                              <td>{{ item.assessorsPhone }}</td>
                              <td>{{ item.assessorsEmail }}</td>
                              <td>{{ item.Organization2 }}</td>
                              <td>
                                <span class="label label-pill label-primary"
                                  *ngFor="let item12 of item.organizationProductservice2;let i = index">{{
                                  item12.item_text }} <br *ngIf="(i+1)%4==0" /></span>
                              </td>
                              <td>{{ item.assessorsEducation }}</td>
                              <td>
                                <span class="label label-pill label-primary"
                                  *ngFor="let item1 of item.domain;let i = index">{{
                                  item1.item_text
                                  }} <br *ngIf="(i+1)%4==0" /></span>
                              </td>
                              <td>{{ item.skill }}</td>
                              <td>{{ item.assessorsTraining }}</td>
                              <td>{{ item.fsmsCertificate }}</td>
                              <td>{{ item.experience }}</td>
                              <td>
                                <button class="btn btn-xs btn-danger"   (click)="removeItem(item._id)">
                                <i class="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 form-group mt-1">
                <label>Remark </label>
                <p class="labelInfo"></p>
                <textarea formControlName="remark" class="form-control"></textarea>
              </div>
              <div class="col-md-12" style="margin-top: 10px;">
                <button type="submit" class="btn btn-success">Update Allocation</button>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
