import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicantService } from 'src/app/services/applicant.service';
import { CoApplicantService } from 'src/app/services/co-applicant.service';
import { QuestionService } from 'src/app/services/question.service';
@Component({
  selector: 'app-sidenav-applicant',
  templateUrl: './sidenav-applicant.component.html',
  styleUrls: ['./sidenav-applicant.component.css']
})
export class SidenavApplicantComponent implements OnInit {
  udata: any;
  userStatusLi: any;
  username: any;
  section: any;
  criterias: any[] = [];
  navToCoDirectly: boolean = false;
  btnDisabled: boolean = false;
  showSectionsandPRev: boolean = false;
  coid: string = '';
  activeRoute: string = '';
  criteriaWise!: { [key: string]: string[] };
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicat: ApplicantService,
    private coapp: CoApplicantService,
    private route: ActivatedRoute
  ) { }
  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('username')
  }
  sortObjectKeysAlphabetically(obj: { [key: string]: any }): { key: string, value: any }[] {
    return Object.keys(obj)
      .sort((a, b) => a.localeCompare(b)) // Sort keys alphabetically
      .map(key => ({ key: key, value: obj[key] }));
  }
  ngOnInit(): void {
    this.username = localStorage.getItem('username') ? localStorage.getItem('username') : this.router.navigate(['/login'])
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.activeRoute = this.router.url;
    this.router.events.subscribe(() => {
      this.activeRoute = this.router.url;
    });
    this.applicat
      .GetAdminApplicantSinglebyemail(email)
      .subscribe((data: any) => {
        // this.userStatusLi=data.applicanData.userStatus;
        if (
          data.applicanData.criteria != '' &&
          data.applicanData.userStatus === true
        ) {
          this.coapp.listCoApplicant(data.applicanData._id).subscribe((resp: any) => {
            if (resp.result.length) {
              this.navToCoDirectly = true
              this.coid = resp.result[0]._id
              if (resp.result[0].userStatus) {
                this.showSectionsandPRev = true
              }
              let criterias = resp.result.map((coapps: any) => {
                return coapps.co_criteria
              })
              this.criterias = [data.applicanData.criteria].concat(criterias)
              this.criterias = Array.from(new Set(this.criterias))
            } else {
              this.criterias = [data.applicanData.criteria]
              this.showSectionsandPRev = true
            }
            this.quest.isFinalAssessed({
              email: this.udata.email,
              criteria: this.criterias[0],
              type: this.udata.role,
            }).subscribe((ittem: any) => {
              if (ittem.statuscode == 200) {
                this.btnDisabled = true;
              } else {
                this.btnDisabled = false;
              }
            });
            this.quest
              .viewQuestionSections({ criterias: this.criterias })
              .subscribe((item: any) => {
                this.section = item.sections;
                this.criteriaWise = item.criterias;
                for (const category in this.criteriaWise) {
                  if (this.criteriaWise.hasOwnProperty(category)) {
                    this.criteriaWise[category].sort((a, b) => a.localeCompare(b));
                  }
                }
              });
          })
          // this.quest
          //   .viewQuestionSec({ criteria: data.applicanData.criteria })
          //   .subscribe((item: any) => {
          //     this.section = item.sections;
          //   });
        } else {
        }
      })
  }
  goToSection(section: string, index: number) {
    let route = 'section';
    if (index == 1) {
      route = 'sectionco'
    }
    this.router.navigate([route, section, 0], { queryParams: { criteria: this.criterias[index] } })
  }
  isActive(route: string): boolean {
    return this.activeRoute.includes(route);
  }
  amIActive(section: string, index: number) {
    let route = 'section';
    if (index == 1) {
      route = 'sectionco'
    }
    return this.isActive(route + "/" + section + "/")
  }
  amIActiveInPrev(index: number) {
    let route = 'preview';
    if (index == 1) {
      route = 'prev-co'
    }
    return this.isActive(route)
  }
  goToPreview(index: number) {
    let route = 'preview';
    if (index == 1) {
      route = 'prev-co'
    }
    this.router.navigate([route])
  }
}
