<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12 mb-3">
          <h4 class="m-n font-bold "><i class="fa fa-angle-left" aria-hidden="true"></i> Financial Years</h4><br />
        </div>
        <div class="col-sm-6 col-xs-12 mb-3 text-right">
          <button class="btn btn-success" data-toggle="modal" data-target="#exampleModalLong" aria-hidden="true"
            *ngIf="canAdd || true">Start next financial year</button>
        </div>
        <div class="col-md-12 m-3">
          <div class="card p-3">
            <table class="table table-striper">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Financial Year</th>
                  <th>Active</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fin of finYears; index as i">
                  <td>{{i+1}}</td>
                  <td>{{fin.financialYear}}</td>
                  <td>{{fin.active ? 'Yes' : 'No'}}</td>
                  <td>{{fin.createdAt | date:'dd-MM-yyyy hh:mm:ss a'}}</td>
                  <td>
                    <div class="dropdown" *ngIf="!fin.default">
                      <button class="btn btn-default dropdown-toggle btn-cls" type="button" id="menu1"
                        data-toggle="dropdown"><span class="app-id"> <i class="fa fa-cog"
                            aria-hidden="true"></i>&nbsp;</span>
                        <span class="caret"></span></button>
                      <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                        <li role="presentation"><a role="menuitem" data-toggle="modal" data-target="#showWinners"
                            aria-hidden="true" (click)="changeWinner(fin)" tabindex="-1" *ngIf="fin.winners"> Show
                            winner </a></li>
                        <li role="presentation"><a role="menuitem" (click)="toggleFinYear(fin._id)" tabindex="-1">
                            {{fin.active ? 'Deactivate' : 'Activate'}}
                          </a></li>
                        <!-- <li role="presentation"><a role="menuitem" data-toggle="modal" data-target="#changeWinner"
                            aria-hidden="true" (click)="changeWinner(fin)" tabindex="-1" *ngIf="fin.winner"> Change
                            winner </a></li> -->
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLongTitle">Start New Financial Year</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="display: unset;">
          <div class="col-md-12 form-group mb-3">
            <label>Select Winners for {{ finYears[finYears.length - 1]?.financialYear }}</label>
          </div>
          <div class="form-group mb-3 col-md-12">
            <div class="row mb-3">
              <div class="col-md-4 col-sm-6">
                <label>Criteria</label>
                <select class="form-control" [(ngModel)]="form.crit" (ngModelChange)="getCriteriaSectors()">
                  <option value="">Select Crtieria</option>
                  <option [value]="criteria" *ngFor="let criteria of ceriterias">{{criteria}}</option>
                </select>
              </div>

              <div class="col-md-4 col-sm-6">
                <label>Sector</label>
                <select class="form-control" [(ngModel)]="form.sect" (ngModelChange)="getClassificationsNames()">
                  <option value="">Select Sector</option>
                  <option [value]="sector" *ngFor="let sector of sectors">{{sector}}</option>
                </select>
              </div>

              <div class="col-md-4 col-sm-6">
                <label>Classification</label>
                <select class="form-control" [(ngModel)]="form.classification" (ngModelChange)="getAwardNames()">
                  <option value="">Select Class</option>
                  <option [value]="class" *ngFor="let class of classifications">{{class}}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-12">
                <label>Award</label>
                <select class="form-control" [(ngModel)]="form.awardName" (ngModelChange)="getWinnerNames()">
                  <option value="">Select Award</option>
                  <option [value]="award" *ngFor="let award of sectAwards">{{award}}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-12">
                <label>Select Winner</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Winner'"
                  [settings]="dropdownSettings"
                  [data]="applicators"
                  [(ngModel)]="form.winner"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>

          <button type="button" class="btn btn-primary" (click)="addAwardName()">Add Award</button>
          </div>
          <br>
          <div class="col-md-12 form-group" *ngIf="winners.length">
            <hr>
            <label>Winners</label>
            <hr>
          </div>
          <div class="col-md-12" *ngFor="let winner of winners; let i = index">
            <div class="form-group mb-3">
              <label>{{ winner.criteria }}</label>
              <br>
              <span>{{ winner.sector }} - {{ winner.classification }}</span>
              <br><br>
              <div class="mt-3 mb-3" *ngFor="let award of winner.awards; let j = index">
                <span>{{ award.awardName }}</span>
                <div>
                  <ul>
                    <li *ngFor="let applicant of award.selectedWinner">{{applicant.firstName}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #closeModal>Close</button>
        <button type="button" class="btn btn-primary" (click)="addFinYear()">Start next financial year</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="changeWinner" tabindex="-1" role="dialog" aria-labelledby="changeWinnerTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="changeWinnerTitle">Change Winner</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="display: unset;">
        <form [formGroup]="finForm">
          <div class="col-md-12 form-group mb-3">
            <label>Change The Winner for {{selectedFin?.financialYear}} </label>
            <!-- <select class="form-control" formControlName="winner">
              <option value="">Select Winner</option>
              <option [value]="applicant._id" *ngFor="let applicant of applicants"
                [selected]="selectedFin.winner?._id == applicant._id">{{applicant.organizationName}},
                {{applicant.applicantCity}}</option>
            </select> -->
            <!-- <div *ngIf="finForm.get('winner')?.hasError('required')" class="text-danger">
              Winner of {{selectedFin?.financialYear}} is required.
            </div> -->
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #closeModal2>Close</button>
        <button type="button" class="btn btn-primary" (click)="changeFinYear()">Change winner</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="showWinners" tabindex="-1" role="dialog" aria-labelledby="showWinnersTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="showWinnersTitle">Winners {{selectedFin?.financialYear}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="display: unset;">
        <div class="col-md-12" *ngFor="let applicant of selectedFin.winners; let i = index">
          <div class="form-group mb-3">
            <hr>
            <label>{{ applicant.criteria }}</label>
            <br>
            <span>{{ applicant.sector }} - {{ applicant.classification }}</span>
            <hr>

            <div class="mt-3 mb-3" *ngFor="let award of applicant.awards; let j = index">
              <span>{{ award.awardName }}</span>
              <div>
                <ul>
                  <li *ngFor="let applicant of award.selectedWinner">{{applicant.firstName}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #closeModal3>Close</button>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we get information!</p>
</ngx-spinner>
