<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="wrapper-md">
      <div class="row">
        <div class="col-xs-12 mb-3 d-flex align-items-center justify-content-between">
          <h4 class="m-n font-bold "><i class="fa fa-angle-left" aria-hidden="true"></i> Questionaire List</h4><br />
          <button data-target="#addModal" class="btn btn-primary" data-toggle="modal">+ Add Question</button>
        </div>
        <div class="col-md-12 mb">
          <input accept=".csv,.xls,.xlsx" type="file" encType="multipart/form-data" class="form-control "
            (change)="onFileChange($event)" placeholder="Upload" id="file" formControlName="attach_file">
        </div>
        <div class="col-md-12">
          <kendo-grid #question [kendoGridBinding]="gridViewData" [pageSize]="11" [pageable]="true" [sortable]="true"
            [filterable]="false" [selectable]="true" (selectionChange)="onRowClick($event)" [groupable]="true"
            [height]="510">
            <ng-template kendoGridToolbarTemplate>
              <kendo-textbox placeholder="Search in all columns..." (valueChange)="onFilter($event)"
                [style.width.px]="180"></kendo-textbox>
              <kendo-grid-spacer></kendo-grid-spacer>
              <!-- <button kendoGridExcelCommand type="button">
                Export to Excel
              </button>
              <button kendoGridPDFCommand>Export to PDF</button> -->
            </ng-template>
            <kendo-grid-column field="s_No" title="S.No" [width]="80"></kendo-grid-column>
            <kendo-grid-column field="criteria" title="Criteria" [width]="80"></kendo-grid-column>
            <kendo-grid-column field="section_no" title="Sec. No." [width]="70"></kendo-grid-column>
            <kendo-grid-column field="section_title" title="Sec. Title" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="sub_section_no" title="Sub Sec. No." [width]="80"></kendo-grid-column>
            <kendo-grid-column field="sub_section_title" title="Sub Sec. Title" [width]="140"></kendo-grid-column>
            <kendo-grid-column field="type" [width]="100" title="Type"></kendo-grid-column>
            <kendo-grid-column field="question_no" title="Ques. No." [width]="70"></kendo-grid-column>
            <kendo-grid-column field="question_title" title="Ques. Title" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="question_description" title="Ques. Desc.." [width]="200"></kendo-grid-column>
            <kendo-grid-excel fileName="Questions.xlsx"></kendo-grid-excel>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>



<button type="button" data-target="#updateModal" class="btn btn-primary" data-toggle="modal" #showModal></button>

<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLabel">Update Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="updateQuestion()">
          <div class="form-group">
            <label for="questionTitle">Question Title</label>
            <input type="text" class="form-control" id="questionTitle" [(ngModel)]="selectedRowData.question_title"
              name="questionTitle" required>
            <div *ngIf="submitted && !selectedRowData.question_title?.trim()" class="text-danger">
              Question Title is required.
            </div>
          </div>

          <div class="form-group">
            <label for="questionDescription">Question Description</label>
            <textarea class="form-control" id="questionDescription" [(ngModel)]="selectedRowData.question_description"
              name="questionDescription" required></textarea>
            <div *ngIf="submitted && !selectedRowData.question_description?.trim()" class="text-danger">
              Question Description is required.
            </div>
          </div>

          <div class="form-group">
            <label for="questionSelect">Question Type</label>
            <select class="form-control" [(ngModel)]="selectedRowData.type" name="questionSelect" required>
              <option value="DD">Dropdown</option>
              <option value="T">Textarea</option>
            </select>
            <div *ngIf="submitted && !selectedRowData.type" class="text-danger">
              Question Type is required.
            </div>
          </div>

          <button type="submit" class="btn btn-primary" [disabled]="submitted">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center ">
        <h5 class="modal-title" id="updateModalLabel">Add Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="addQuestion()">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="criteria">Question Criteria <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="criteria" [(ngModel)]="newQuestion.criteria" name="criteria"
                  required placeholder="Criteria 1">
                <div *ngIf="submitted && !newQuestion.criteria?.trim()" class="text-danger">
                  Question Criteria is required.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="number">Question Number <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="number" [(ngModel)]="newQuestion.question_no" name="question_no"
                  required placeholder="2.01.01">
                <div *ngIf="submitted && !newQuestion.question_no?.trim()" class="text-danger">
                  Question Number is required.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="section">Question Section <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="section" [(ngModel)]="newQuestion.section_no" name="section_no"
                  required placeholder="B1">
                <div *ngIf="submitted && !newQuestion.section_no?.trim()" class="text-danger">
                  Question Section is required.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="sectionTitle">Question Section Title <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="sectionTitle" [(ngModel)]="newQuestion.section_title"
                  name="section_title" required placeholder="ABCD(!@#)">
                <div *ngIf="submitted && !newQuestion.section_title?.trim()" class="text-danger">
                  Question Section Title is required.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="subsection">Question Subsection <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="subsection" [(ngModel)]="newQuestion.sub_section_no"
                  name="sub_section_no" required placeholder="B1.02">
                <div *ngIf="submitted && !newQuestion.sub_section_no?.trim()" class="text-danger">
                  Question Subsection is required.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="subsectionTitle">Question Subsection Title <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="subsectionTitle" [(ngModel)]="newQuestion.sub_section_title"
                  name="sub_section_title" required placeholder="ABCD(!@#)">
                <div *ngIf="submitted && !newQuestion.sub_section_title?.trim()" class="text-danger">
                  Question Subsection Title is required.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="title">Question Title <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="title" [(ngModel)]="newQuestion.question_title" name="question_title"
                  required placeholder="ABCD(!@#)">
                <div *ngIf="submitted && !newQuestion.question_title?.trim()" class="text-danger">
                  Question Title is required.
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label for="questionSelect">Question Type <span class="text-danger">*</span></label>
                <select class="form-control" [(ngModel)]="newQuestion.type" name="questionSelect" required>
                  <option value="DD">Dropdown</option>
                  <option value="T">Textarea</option>
                </select>
                <div *ngIf="submitted && !newQuestion.type" class="text-danger">
                  Question Type is required.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="questionDescription">Question Description <span class="text-danger">*</span></label>
                <textarea class="form-control" id="questionDescription" [(ngModel)]="newQuestion.question_description"
                  name="questionDescription" required placeholder="ABCD(!@#)"></textarea>
                <div *ngIf="submitted && !newQuestion.question_description?.trim()" class="text-danger">
                  Question Description is required.
                </div>
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-primary">Add</button>
        </form>
      </div>
    </div>
  </div>
</div>