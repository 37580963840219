<app-header></app-header>
<app-sidenav-assessors></app-sidenav-assessors>
<div class="app-content ">
  <div class="row mt-4">
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr class="grayColor">
            <th class="lightBlue" rowspan="3" colspan="3">CII Award for Food Safety
              Score Sheet for Criteria 3 Large</th>
            <th >United_Breweries_3L_G_alcBev</th>
            <th >North_East_3L_RS_Bak</th>
            <th >Parle_3L_G_Bak</th>
            <th >Pepsico_Sang_3L_G_Bev</th>
            <th >Varun_Bazpur_3L_G_Bev</th>
            <th >Varun_Kol_3L_G_Bev</th>
            <th >Varun_Panipat_3L_Rs_Bev</th>
            <th >ITC_Malur_3L_G_Cereals</th>
            <th >HUL_Nala_3L_G_Dairy</th>
            <th >GSK_soni_3L_G_Dairy</th>
            <th >Adani_Haldia_3L_G_Fat</th>
            <th >Adani_Vidisha_3L_G_Fat</th>
            <th >OmniActive_3L_G_Fat</th>
            <th >Abbott_3L_G_Food_stuf</th>
            <th >Amway_3L_G_Food_stuf</th>
            <th >Nestle_3L_G_FoodStuf</th>
            <th >ITC_Hardwar_3L_RTE</th>
            <th >ITCIRanjan_3L_G_RTE</th>
            <th >FieldFresh_3L_G_Salt</th>
            <th >Dabur_3L_G_Sweet</th>
            <th >EIDParry_3L_Rs_Sweet</th>
            <th >TGBL_Damdim_3L_G_Tea</th>
            <th >TGBL_Blr_3L_G_Tea</th>
            <th >TGBL Sampla</th>
          </tr>
          <tr class="grayColor">
            <!-- <th colspan="3"></th> -->
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
            <th >Shahista Naaz</th>
          </tr>
          <tr class="grayColor">
            <!-- <th colspan="3"></th> -->
            <th >info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
            <th>info@example.com</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Section</td>
            <td>A : Self Declaration</td>
            <td rowspan="3">Mandatory</td>
            <td rowspan="3" colspan="24"></td>
          </tr>
          <tr>
            <td>A1</td>
            <td>Statutory and Regulatory Requirements</td>
            <td colspan="24"></td>
          </tr>
          <tr>
            <td>A2</td>
            <td>Social Compliance</td>
            <td colspan="24"></td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td>Alcoholic Beverages</td>
            <td colspan="2">Bakery</td>
            <td colspan="4">Beverages</td>
            <td>Cereals and cereal products</td>
            <td>Dairy</td>
            <td>Dairy-based drinks</td>
            <td colspan="2">Fats and oils</td>
            <td colspan="4">Foodstuffs intended for particular nutritional uses</td>
            <td colspan="2">Ready-to-eat savouries</td>
            <td>Salts, spices, soups, sauces, salads and protein products</td>
            <td colspan="2">Sweeteners, including honey</td>
            <td colspan="3">Tea</td>
          </tr>
          <tr>
            <td>Section B1 : </td>
            <td>Pre Requisite Programs (based on ISO 22002-1)</td>
            <td>Highest Possible Score</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
          </tr>
          <tr>
            <td>No.</td>
            <td>Checkpoints</td>
            <td></td>
            <td colspan="24"></td>
          </tr>
          <tr>
            <td>1</td>
            <td>Establishment Construction, Environment, Location</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Layout of Premises and  workplaces</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
          </tr>
          <tr>
            <td style="height: 30px;" colspan="27"></td>
          </tr>
          <tr>
            <td>Section B2 : </td>
            <td>HACCP based on ISO 22000</td>
            <td>Highest Possible Score</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
            <td>Score %</td>
          </tr>
          <tr>
            <td>No.</td>
            <td>Checkpoints</td>
            <td></td>
            <td colspan="24"></td>
          </tr>
          <tr>
            <td>1</td>
            <td>Establishment Construction, Environment, Location</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Layout of Premises and  workplaces</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
            <td>15.00</td>
          </tr>
          <tr>
            <td style="height: 30px;" colspan="27"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>