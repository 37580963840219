export class question {
  s_No:String='';
  criteria: String = '';
  section_no: String = '';
  section_title: String = '';
  sub_section_no: String = '';
  sub_section_title: String = '';
  type: string = '';
  question_no: String = '';
  question_title: String = '';
  question_description: String = '';
}
