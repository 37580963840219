import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FinancialsService } from 'src/app/service/financials.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import confetti from 'canvas-confetti';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-financial-years',
  templateUrl: './financial-years.component.html',
  styleUrls: ['./financial-years.component.css']
})
export class FinancialYearsComponent implements OnInit {

  finForm!: FormGroup;
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeModal2') closeModal2!: ElementRef;
  finYears: any[] = [];
  currentYear: number = new Date().getFullYear();
  canAdd: boolean = false;
  applicants: any[] = [];
  selectedFin: any = {};
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'firstName',
    enableCheckAll: false,
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  awardName: string = '';
  awards: any = {};
  awardCategories: any = {};
  ceriterias: string[] = [];
  form: any = {
    crit: '',
    sect: '',
    awardName: '',
    winner: '',
    classification: ''
  }
  sectors: any[] = [];
  sectAwards: any[] = [];
  applicators: any[] = [];
  winners: any = [];
  classifications: any[] = [];

  constructor(
    private fb: FormBuilder,
    private finService: FinancialsService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private applicantS: ApplicantService
  ) {
    this.finForm = this.fb.group({
      winners: this.fb.array([]), // Initialize with an empty array
      startYear: new FormControl('', [Validators.pattern(/^(202[0-9]|20[3-4][0-9]|2050)$/), Validators.max(new Date().getFullYear())]),
      endYear: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initialize();
  }

  initialize() {
    this.applicantS.getCritWiseApplicant().subscribe((data: any) => {
      this.applicants = data.applicants
      this.ceriterias = data.criterias
      this.applicants.forEach((award: any) => {
        this.awards[award.criteria] = this.awards[award.criteria] ?? {}
        let catKey = award.sector + " - " + award.classification
        this.awards[award.criteria][catKey] = []

        if (award.criteria.toLowerCase().trim() == 'criteria 11') {
          if (award.normal.length > 0) {
            this.awards[award.criteria][catKey].push(
              ...[
                { awardName: 'Award Trophy - For Outstanding performance', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Commendation for Significant Achievement', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Commendation for Strong commitment to excel', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Certificate of Appreciation', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal }
              ]
            );
          }

          if (award.risingStars.length > 0) {
            this.awards[award.criteria][catKey].push(
              ...[
                { awardName: 'Award Trophy (Rising Star) - For Outstanding performance', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Commendation for Significant Achievement', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Commendation for Strong commitment to excel', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Certificate of Appreciation', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars }
              ]
            );
          }
        } else if (award.criteria.toLowerCase().trim() == 'criteria 1' || award.criteria.toLowerCase().trim() == 'criteria 2a for smb') {
          if (award.normal.length > 0) {
            this.awards[award.criteria][catKey].push(
              ...[
                { awardName: 'Gold Star Award Trophy - For Outstanding performance', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Silver Star Certificate - Significant Achievement in food safety ', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Bronze Certificate - Strong commitment to food safety', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Certificate of Appreciation', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal }
              ]
            );
          }

          if (award.risingStars.length > 0) {
            this.awards[award.criteria][catKey].push(
              ...[
                { awardName: 'Award Trophy (Rising Star) - For Outstanding performance', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Commendation for Significant Achievement', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Commendation for Strong commitment to excel', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Certificate of Appreciation', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars }
              ]
            );
          }
        } else {
          if (award.normal.length > 0) {
            this.awards[award.criteria][catKey].push(
              ...[
                { awardName: 'Award Trophy - For Outstanding performance', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Significant Achievement in food safety', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Strong commitment to food safety', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Recommendation for strong commitment to GHP/GMP/GWP/GAP/GRP', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal },
                { awardName: 'Certificate - Certificate of Appreciation', category: 'normal', sector: award.sector, classification: award.classification, applicants: award.normal }
              ]
            );
          }

          if (award.risingStars.length > 0) {
            this.awards[award.criteria][catKey].push(
              ...[
                { awardName: 'Award Trophy (Rising Star) - For Outstanding performance', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Significant Achievement in food safety', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Strong commitment to food safety', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Recommendation for strong commitment to GHP/GMP/GWP/GAP/GRP', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars },
                { awardName: 'Certificate (Rising Star) - Certificate of Appreciation', category: 'rising star', sector: award.sector, classification: award.classification, applicants: award.risingStars }
              ]
            );
          }
        }
      })
      // this.initializeWinners();
    });
    this.finService.getFinYears().subscribe({
      next: (resp: any) => {
        this.finYears = resp.data;
        if (!this.finYears.length) {
          this.canAdd = true;
          this.spinner.hide();
          return;
        }
        let currentFin = this.finYears[this.finYears.length - 1]?.financialYear;
        let [startYear, endYear] = currentFin.split(" - ").map((year: string) => Number(year));
        let currentYear = new Date(2025, 3, 1).getFullYear();
        let currentMonth = new Date(2025, 3, 1).getMonth();
        if (currentMonth > 2 && endYear <= currentYear) {
          this.canAdd = true;
        }
        this.spinner.hide();
      },
      error: (err: any) => {
        this.toast.error(err.error);
        this.spinner.hide();
      }
    })
  }

  // initializeWinners() {
  //   const winnersArray = this.finForm.get('winners') as FormArray;

  //   this.applicants.forEach(applicant => {
  //     const applicantGroup = this.fb.group({
  //       criteria: [applicant.criteria],
  //       sector: [applicant.sector],
  //       classification: [applicant.classification],
  //       awards: this.fb.array([]),
  //     });

  //     const awardsArray = applicantGroup.get('awards') as FormArray;
  //     const awardsList = this.awards[applicant.criteria]?.[`${applicant.sector} - ${applicant.classification}`] || [];

  //     awardsList.forEach((award: any) => {
  //       awardsArray.push(
  //         this.fb.group({
  //           awardName: [award.awardName],
  //           selectedWinner: [[]],
  //         })
  //       );
  //     });

  //     winnersArray.push(applicantGroup);
  //   });
  // }

  get awardsFormArray() {
    return (this.finForm.get('winners') as FormArray).controls;
  }

  addFinYear() {
    this.finForm.markAllAsTouched();
    let currentYear = new Date().getFullYear();
    this.finForm.get('startYear')?.setValue(currentYear);
    this.finForm.get('endYear')?.setValue(currentYear + 1);

    if (this.finForm.invalid && this.finYears.length) {
      return;
    }

    this.canAdd = false;
    this.spinner.show();

    const submissionData = {
      ...this.finForm.getRawValue(),
      winners: this.winners.map((winner: any) => ({
        criteria: winner.criteria,
        sector: winner.sector,
        classification: winner.classification,
        awards: winner.awards.map((award: any) => ({
          awardName: award.awardName,
          selectedWinner: award.selectedWinner.map((winner: any) => winner._id)
        }))
      }))
    };

    this.finService.addFinYear(submissionData).subscribe({
      next: (resp: any) => {
        this.toast.success('Financial year added successfully');
        this.closeModal.nativeElement.click();
        this.launchConfetti();
        this.finForm.reset();
        this.initialize();
      },
      error: (error: any) => {
        this.toast.error('Some error occurred or financial year exists');
        this.spinner.hide();
      }
    });
  }


  toggleFinYear(id: string) {
    this.spinner.show();
    this.finService.toggleFinYear({}, id).subscribe({
      next: (resp: any) => {
        this.toast.success('Financial year updated successfully');
        const finYear = this.finYears.find((fin: any) => fin._id === id);
        if (finYear) {
          finYear.active = !finYear.active;
        }
        this.spinner.hide();
      },
      error: (err: any) => {
        this.toast.error(err.error);
        this.spinner.hide();
      }
    });
  }

  changeWinner(fin: any) {
    this.selectedFin = fin;
    // this.finForm.get('winner')?.setValue(fin.winner?._id);
  }

  changeFinYear() {
    let winner = this.finForm.get('winner')?.value;
    this.finService.updateWinFinYear({ winner }, this.selectedFin._id).subscribe({
      next: (resp: any) => {
        const finYear = this.finYears.find((fin: any) => fin._id === this.selectedFin._id);
        finYear.winner = this.applicants.find((appls: any) => appls._id === winner)
        this.closeModal2.nativeElement.click();
        this.launchConfetti()
        this.toast.success('Winner changed successfuly')
      },
      error: (err: any) => {
        this.toast.error('Problem updating winner')
      }
    });
  }

  addAward() {
    if (this.awardName.trim() !== '') {
      const awards = this.finForm.get('awards') as FormArray;
      awards.push(this.fb.group({
        name: new FormControl(this.awardName, Validators.required),
        selectedWinners: new FormControl([], Validators.required)
      }));
      this.awardName = '';
    }
  }

  launchConfetti() {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;

    var colors = ['#bb0000', '#5cb85c'];
    const interval = setInterval(function () {
      if (Date.now() > animationEnd) {
        return clearInterval(interval);
      }
      confetti({
        particleCount: 50,
        angle: 60,
        spread: 100,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 50,
        angle: 120,
        spread: 100,
        origin: { x: 1 },
        colors: colors
      });
    }, 200);
  }

  getFormattedWinners(selectedWinners: any[]): string {
    return selectedWinners
      .map(a => a.organizationName + ', ' + a.applicantCity)
      .join('<br>');
  }

  getSelectedWinnerControl(i: number, j: number): FormControl {
    return this.finForm.get(['winners', i, 'awards', j, 'selectedWinner']) as FormControl;
  }

  showWinners(applicants: any) {
    return applicants.map((a: any) => a.firstName).join(", ")
  }

  getCriteriaSectors() {
    let crit = this.form.crit;
    this.form = { ...this.form, sect: '', awardName: '', winner: '', classification: ''}
    this.sectors = Array.from(new Set(this.applicants.filter((app: any) => app.criteria == crit)?.map((app: any) => app.sector)))
  }

  getClassificationsNames() {
    let crit = this.form.crit;
    let sect = this.form.sect;
    this.form = { ...this.form, awardName: '', winner: '', classification: ''}
    this.classifications = Array.from(new Set(this.applicants.filter((app: any) => app.criteria == crit && app.sector == sect)?.map((app: any) => app.classification)))
  }

  getAwardNames() {
    let crit = this.form.crit;
    let sect = this.form.sect + ' - ' + this.form.classification;
    this.form = { ...this.form, awardName: '', winner: ''}
    this.sectAwards = this.awards[crit][sect].map((d: any) => d.awardName)
  }

  getWinnerNames() {
    let crit = this.form.crit;
    let sect = this.form.sect + ' - ' + this.form.classification;
    let award = this.form.awardName;
    let awardee = this.awards[crit][sect].find((d: any) => d.awardName == award);
    this.form = { ...this.form, classification: awardee.classification, winner: ''};
    this.applicators = awardee?.applicants;
  }

  addAwardName() {
    let winners = this.winners.find((win: any) => win.criteria == this.form.crit && win.sector ==this.form.sect && win.classification == this.form.classification)
    if(!winners) {
      const winner = {
        criteria: this.form.crit,
        sector: this.form.sect,
        classification: this.form.classification,
        awards: [
          {awardName: this.form.awardName, selectedWinner: this.form.winner}
        ],
      }
      this.winners.push(winner)
    } else {
      winners.awards.push({awardName: this.form.awardName, selectedWinner: this.form.winner})
    }
    this.form = {
      crit: '',
      sect: '',
      awardName: '',
      winner: '',
      classification: ''
    }
  }
}

