import { Component, OnInit, ViewChild } from '@angular/core';
import { AllocationService } from 'src/app/services/allocation.service';
import { Subject } from 'rxjs';
import { TosterService } from 'src/app/services/toster.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import { AuthService } from 'src/app/services/auth.service';
import { base_url_upload } from 'src/app/global';
import { CoApplicantService } from 'src/app/services/co-applicant.service';
@Component({
  selector: 'app-list-allocation',
  templateUrl: './list-allocation.component.html',
  styleUrls: ['./list-allocation.component.css'],
})
export class ListAllocationComponent implements OnInit {
  assessordata: any = [];
  allval_allocationlistData: any;
  wholedata: any;
  AdminEmail: any
  role: any
  loading: boolean = false;
  allocation_id: any;
  coApplicantAvailable: boolean = false;
  @ViewChild('closebutton') closebutton: any;
  constructor(
    public allocation: AllocationService,
    private toast: TosterService,
    private applicants: ApplicantService,
    private auth: AuthService,
    private coapp: CoApplicantService
  ) { }
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  AllocationList: any;
  emailList1: any = [];
  emailList2: any = [];
  emailList3: any = [];
  serialNumber: any = 1;
  ngOnInit(): void {
    this.AdminEmail = localStorage.getItem('user')
    this.role = localStorage.getItem('role')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
    };
    this.allocation.getAllAllocation().subscribe((item: any) => {
      this.AllocationList = item.result;
      this.AllocationList.map((item: any) => {
        if (item.applicantData[0] != undefined) {
          this.applicants.getAppDoc(item.applicantData[0]._id).subscribe((datar: any) => {
            item.applicantData[0].appdoc = datar[0]?.documentname ? true : false;
            if (datar[0] == undefined) {
              item.applicantData[0].appdoc = false;
            }
          });
        }
      });
      this.AllocationList = this.AllocationList.filter((item: any) => {
        return item.applicantData && item.applicantData[0];
      })
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  checkArray(arr: any, id: any, f: any) {
    let b = '';
    arr.find((user: any) => {
      if (user.assessor_id === id) {
        if (f == 'teamleader') {
          b = user[f] ? 'TeamLeader' : '';
        }
        if (f == 'calibrator') {
          b = user[f] ? 'Calibrator' : '';
        }
        if (f == 'allocationliststatus') {
          b = user[f];
        }
        if (f == 'first_comm') {
          b = user[f];
        }
        if (f == 'second_comm') {
          b = user[f];
        }
        if (f == 'third_comm') {
          b = user[f];
        }
        if (f == 'newapp') {
          b = user[f];
        }
      }
    });
    return b;
  }
  compareDates(dt: any): boolean | undefined {
    let currentDate = new Date();
    let applicantDate = new Date(dt);
    let threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
    if (applicantDate.getTime() >= threeYearsAgo.getTime() && applicantDate.getTime() <= currentDate.getTime()) {
      return true;
    }
    return false;
  }
  sentmail(id: any) {
    this.loading = true;
    this.emailList1 = [];
    this.emailList2 = [];
    this.emailList3 = [];
    this.allocation_id = id;
    this.allocation.getAllocation(id).subscribe((allocation: any) => {
      this.wholedata = allocation;
      this.assessordata = allocation["allocation_data"];
      this.coApplicantAvailable = allocation.coapplicant;
      this.loading = false;
    })
  }
  getEmail1(e: any, ass: any, all_id: any, ass_id: any) {
    if (e.target.checked) {
      if (ass.assessorsEmail) {
        this.emailList1.push(ass.assessorsEmail);
      }
      if (ass.assessorsSecondaryemail) {
        this.emailList1.push(ass.assessorsSecondaryemail);
      }
      if (ass.assessorsTertiaryemail) {
        this.emailList1.push(ass.assessorsTertiaryemail);
      }
    } else {
      var index = this.emailList1.findIndex(function (o: any) {
        return o === e.target.value;
      });
      if (index !== -1) this.emailList1.splice(index, 1);
    }
    this.allocation
      .updateMailStatus({
        allocation_id: all_id,
        assessor_id: ass_id,
        comm: '1',
        status: e.target.checked,
      })
      .subscribe();
  }
  getEmail2(e: any, ass: any, all_id: any, ass_id: any) {
    if (e.target.checked) {
      if (ass.assessorsEmail) {
        this.emailList2.push(ass.assessorsEmail);
      }
      if (ass.assessorsSecondaryemail) {
        this.emailList2.push(ass.assessorsSecondaryemail);
      }
      if (ass.assessorsTertiaryemail) {
        this.emailList2.push(ass.assessorsTertiaryemail);
      }
    } else {
      var index = this.emailList2.findIndex(function (o: any) {
        return o === e.target.value;
      });
      if (index !== -1) this.emailList2.splice(index, 1);
    }
    this.allocation
      .updateMailStatus({
        allocation_id: all_id,
        assessor_id: ass_id,
        comm: '2',
        status: e.target.checked,
      })
      .subscribe();
  }
  getEmail3(e: any, ass: any, all_id: any, ass_id: any) {
    if (e.target.checked) {
      if (ass.assessorsEmail) {
        this.emailList3.push(ass.assessorsEmail);
      }
      if (ass.assessorsSecondaryemail) {
        this.emailList3.push(ass.assessorsSecondaryemail);
      }
      if (ass.assessorsTertiaryemail) {
        this.emailList3.push(ass.assessorsTertiaryemail);
      }
    } else {
      var index = this.emailList3.findIndex(function (o: any) {
        return o === e.target.value;
      });
      if (index !== -1) this.emailList3.splice(index, 1);
    }
    this.allocation
      .updateMailStatus({
        allocation_id: all_id,
        assessor_id: ass_id,
        comm: '3',
        status: e.target.checked,
      })
      .subscribe();
  }
  SendMailApplicant() {
    this.allocation
      .SendMailApp({
        wholedata: this.wholedata,
        emails1: this.emailList1,
        emails2: this.emailList2,
        emails3: this.emailList3,
      })
      .subscribe((item: any) => {
        this.toast.showInfo('Mail sent successfully');
        this.closebutton.nativeElement.click();
        this.wholedata = [];
        this.assessordata = [];
        this.coApplicantAvailable = false;
      });
  }
  removeCachedData() {
    setTimeout(() => {
      this.wholedata = [];
      this.assessordata = [];
      this.coApplicantAvailable = false;
    }, 500);
  }
  SendMailCoApplicant() {
    this.allocation.SendMailCoApp({
      applicantId: this.wholedata.applicant_id
    }).subscribe((item: any) => {
      this.toast.showInfo('Mail sent successfully');
      this.closebutton.nativeElement.click();
      this.wholedata = [];
      this.assessordata = [];
      this.coApplicantAvailable = false;
    })
  }
  // SendMailCoApplicant() {
  //   this.allocation
  //     .SendMailApp({
  //       wholedata: this.wholedata,
  //       emails1: this.emailList1,
  //       emails2: this.emailList2,
  //       emails3: this.emailList3,
  //     })
  //     .subscribe((item: any) => {
  //
  //       this.toast.showInfo('Mail send Successfully');
  //       window.location.reload();
  //     });
  // }
  SendMail() {
    let counnt =
      this.emailList1.length + this.emailList2.length + this.emailList3.length;
    if (counnt != 0) {
      this.allocation
        .SendMail2ndComm({
          wholedata: this.wholedata,
          emails1: this.emailList1,
          emails2: this.emailList2,
          emails3: this.emailList3,
        })
        .subscribe((item: any) => {
          this.toast.showInfo('Mail send Successfully');
          this.closebutton.nativeElement.click();
          this.wholedata = [];
          this.assessordata = [];
          this.coApplicantAvailable = false;
        });
    } else {
      this.toast.showError('Please choose email');
    }
  }
  getAccepted(allocadata: any, status: any) {
    let count = 0;
    allocadata.map((item: any) => {
      if (item.allocationliststatus === status) {
        count++;
      }
    });
    return count;
  }
  exportbyid(id: string, ass_id: string) {
    this.loading = true;
    this.allocation
      .exportbyid(id, ass_id)
      .subscribe((res: any) => {
        var file_name = res.exported;
        let url = base_url_upload + "download-export/" + file_name;
        window.open(url);
        this.loading = false;
      });
  }
  exportconsolidatedbyid(id: string, ass_id: string) {
    this.loading = true;
    this.allocation
      .exportconsolidatedbyid(id, ass_id)
      .subscribe((res: any) => {
        var file_name = res.exported;
        let url = base_url_upload + "download-export/" + file_name;
        window.open(url);
        this.loading = false;
      });
  }
  exportconsolidatedwordbyid(id: string, ass_id: string) {
    this.loading = true;
    this.allocation
      .exportconsolidatedwordbyid(id, ass_id)
      .subscribe((res: any) => {
        var file_name = res.exported;
        let url = base_url_upload + "download-export/" + file_name;
        window.open(url);
        this.loading = false;
      });
  }
  loginAs(assessorData: any) {
    if (this.role) {
      let userData: any = localStorage.getItem('userdata')
      userData = JSON.parse(userData)
      this.auth.loginAsAssessor({ email: this.AdminEmail, role: this.role, assessorEmail: assessorData?.assessorsEmail, password: userData?.password })?.subscribe((user: any) => {
        localStorage.clear()
        localStorage.setItem('username', user.results?.username);
        localStorage.setItem('user', user.results?.email);
        localStorage.setItem('role', user.results?.role);
        localStorage.setItem('userdata', JSON.stringify(user?.results));
        window.open(
          '/assessors', "_blank");
        window.close()
        // setTimeout(() => {
        //   // this.router.navigate(['/assessors']);
        //   window.open(
        //     '/assessors', "_blank");
        //   // window.location.href = '/assessors';
        //
        // }, 1500);
      })
    } else {
      this.toast.showError('Please verify that you are not a robot.');
      // this.captchaError = 'Please verify that you are not a robot.';
      // this.spinner.hide();
    }
  }
}
