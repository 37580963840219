<app-header></app-header>
<app-sidenav></app-sidenav>
<div _ngcontent-tmj-c381="" id="content" role="main" class="app-content">
    <div class="app-content-body">
        <div class="wrapper-md">
            <!-- <div class="row"> -->
                <div class="report">
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Sector Grand Total</h4>
                        </div>
                    </div>
                    <div class="bb"></div>
                    <div class="row">
                        <!-- <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Assesseor Name</span>
                                </div>
                                <select class="form-control" name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Assesseor Name</span>
                                </div>
                                <select class="form-control" name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Status</span>
                                </div>
                                <select class="form-control" name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="col-md-12">
                            <!-- <div class="input-group mt-3 search_inp">
                                <div class="input-group-prepend">
                                    <input placeholder="Search" class="form-control" type="search" name="" id="">
                                </div>
                            </div> -->
                            <div class="clearfix"></div>
                            <!-- <div class="input-group mt-3 search_inp">
                                <div class="input-group-prepend mb-3" style="margin-bottom: 20px;">
                                    <button >Export to excel</button>
                                </div>
                            </div> -->
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 over overflow-scroll">
                            <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>S No</th>
                                    <th>Unit</th>
                                    <th>Applicant Name</th>
                                    <th>Criteria</th>
                                    <th>Sector</th>
                                    <th >Grand Total</th>
                                    <th>Sections Score</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of reportData;let i= index">
                                    <td>{{i+1}}</td>
                                    <td>{{item?.organizationName}}</td>
                                    <td>{{item?.firstName}}</td>
                                    <td>{{item?.criteria}}</td>
                                    <td>{{item?.sector}}</td>
                                    <td>{{item?.grandTotal}}</td>
                                     <td>{{item?.sctionScore}}</td>
                                   
                                  
                                 
                                </tr>
                            </tbody>
                              
                           
                            </table>
                        </div>
                       
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div>