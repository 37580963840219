<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12 mb-3">
          <h4 class="m-n font-bold "><i class="fa fa-angle-left" aria-hidden="true"></i> Questionaire List</h4><br />
        </div>
        <div class="col-md-12 mb">
          <input accept=".csv,.xls,.xlsx" type="file" encType="multipart/form-data" class="form-control "
            (change)="onFileChange($event)" placeholder="Upload" id="file" formControlName="attach_file">
        </div>
        <div class="col-md-12">
          <kendo-grid #question [kendoGridBinding]="gridViewData" [pageSize]="11" [pageable]="true" [sortable]="true" [filterable]="false" [selectable]="true" (selectionChange)="onRowClick($event)"
            [groupable]="true" [height]="510">
            <ng-template kendoGridToolbarTemplate>
              <kendo-textbox
                placeholder="Search in all columns..."
                (valueChange)="onFilter($event)"
                [style.width.px]="180"
              ></kendo-textbox>
              <kendo-grid-spacer></kendo-grid-spacer>
              <!-- <button kendoGridExcelCommand type="button">
                Export to Excel
              </button>
              <button kendoGridPDFCommand>Export to PDF</button> -->
            </ng-template>      
            <kendo-grid-column field="s_No" title="S.No" [width]="80"></kendo-grid-column>
            <kendo-grid-column field="criteria" title="Criteria" [width]="80"></kendo-grid-column>
            <kendo-grid-column field="section_no" title="Sec. No." [width]="70"></kendo-grid-column>
            <kendo-grid-column field="section_title" title="Sec. Title" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="sub_section_no" title="Sub Sec. No." [width]="80"></kendo-grid-column>
            <kendo-grid-column field="sub_section_title" title="Sub Sec. Title" [width]="140"></kendo-grid-column>
            <kendo-grid-column field="type" [width]="100" title="Type"></kendo-grid-column>
            <kendo-grid-column field="question_no" title="Ques. No." [width]="70"></kendo-grid-column>
            <kendo-grid-column field="question_title" title="Ques. Title" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="question_description" title="Ques. Desc.." [width]="200"></kendo-grid-column>
            <kendo-grid-excel fileName="Questions.xlsx"></kendo-grid-excel>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>



<button type="button" data-target="#updateModal" class="btn btn-primary" data-toggle="modal" #showModal></button>

<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLabel">Update Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="updateQuestion()">
          <div class="form-group">
            <label for="questionTitle">Question Title</label>
            <input type="text" class="form-control" id="questionTitle" 
                   [(ngModel)]="selectedRowData.question_title" name="questionTitle" required>
            <div *ngIf="submitted && !selectedRowData.question_title?.trim()" class="text-danger">
              Question Title is required.
            </div>
          </div>
        
          <div class="form-group">
            <label for="questionDescription">Question Description</label>
            <textarea class="form-control" id="questionDescription" 
                      [(ngModel)]="selectedRowData.question_description" name="questionDescription" required></textarea>
            <div *ngIf="submitted && !selectedRowData.question_description?.trim()" class="text-danger">
              Question Description is required.
            </div>
          </div>
        
          <div class="form-group">
            <label for="questionSelect">Question Type</label>
            <select class="form-control" [(ngModel)]="selectedRowData.type" name="questionSelect" required>
              <option value="DD">Dropdown</option>
              <option value="T">Textarea</option>
            </select>
            <div *ngIf="submitted && !selectedRowData.type" class="text-danger">
              Question Type is required.
            </div>
          </div>
        
          <button type="submit" class="btn btn-primary" [disabled]="submitted">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>