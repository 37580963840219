import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TosterService } from 'src/app/services/toster.service';
import { FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import { ApplicantService } from 'src/app/services/applicant.service';
import { FinancialsService } from 'src/app/service/financials.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  localdata: any;
  finYear: any = '';
  finYears: any = [];

  constructor(
    private router: Router,
    private toast: TosterService,
    private AuthS: AuthService,
    private formB: FormBuilder,
    private applicantS: ApplicantService,
    private financialService: FinancialsService
  ) { }
  username: any;
  appForm!: FormGroup;
  isPasswordSame: any;
  login_id: any;
  isValidFormSubmitted: any;
  udata: any;
  email: any;
  applicantSdata: any;
  private _id: any;
  Udata: any;
  ngOnInit(): void {
    this.getFinYear();
    this.username = localStorage.getItem('username')
      ? localStorage.getItem('username')
      : this.router.navigate(['/signUp']);
    this.Udata = localStorage.getItem('userdata');
    let uddd = JSON.parse(this.Udata);
    this.applicantSdata = JSON.parse(this.Udata);
    this.onforminit();
    this.financialService.getFinYears().subscribe({
      next: (resp: any) => {
        this.finYears = resp.data;
      },
      error: (err: any) => {}
    })
  }
  logout() {
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem('userdata');
    this.router.navigate(['/login']);
  }
  onforminit() {
    this.appForm = this.formB.group(
      {
        old_pass: ['', [Validators.required]],
        new_pass: ['', Validators.compose([Validators.required])],
        c_pass: ['', [Validators.required]],
      },
      { validators: this.checkPassword('new_pass', 'c_pass') }
    );
  }
  checkPassword(controlName: any, matchingControlName: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
        this.isPasswordSame = matchingControl.status == 'VALID' ? true : false;
      } else {
        matchingControl.setErrors(null);
        this.isPasswordSame = matchingControl.status == 'VALID' ? true : false;
      }
    };
  }
  changeSubmit(): void {
    this.isValidFormSubmitted = false;
    if (this.appForm.invalid) {
      this.isValidFormSubmitted = true;
    } else {
      this.AuthS.ChangePassword(
        this.appForm.value,
        this.applicantSdata._id
      ).subscribe((data: any) => {
        if (data.status == 200) {
          this.toast.showSuccess(data.message);
          window.location.reload();
          document.getElementById('closemodal')?.click();
          this.logout();
          window.location.href = '/login';
        } else {
          this.toast.showError(data.message);
        }
      });
    }
  }
  public get f() {
    return this.appForm.controls;
  }
  setFinYear() {
    this.financialService.setCookie({cookie: this.finYear, role: this.applicantSdata.role, email: this.applicantSdata.email }, 'financialYear').subscribe({
      next: (resp: any) => {
        if(this.applicantSdata.role == 'applicant') {
          this.financialService.getFinancialId(this.applicantSdata.email).subscribe((resp: any) => {
            if(resp.userData?._id) {
              this.applicantSdata._id = resp.userData._id
              localStorage.setItem('userdata', JSON.stringify(this.applicantSdata))
              window.location.href = "/dashboard";
            }
          })
        } else {
          window.location.href = "/dashboard";
        }
      },
      error: (err: any) => {
        window.alert('Some error occured!!!');
      }
    });
  }

  getFinYear() {
    this.financialService.getCookie({}, 'financialYear').subscribe({
      next: (resp: any) => {
        this.finYear = resp.cookie;
      },
      error: (err: any) => {
        window.alert('Some error occured!!!');
      }
    });
  }
}
