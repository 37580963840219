<app-header></app-header>
<app-sidenav *ngIf="udata.role=='admin' || udata.role=='super admin'"></app-sidenav>
<app-sidenav-assessors *ngIf="udata.role=='assessor'"></app-sidenav-assessors>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Applicant Documents</h2>
    <!-- <div class="card">
      <img src="../../../assets/img/background-default.png" style="width:40%;" />
      <p>This section shall be available once your Application is accepted and the assessment is undertaken.</p>
    </div> -->
    <section id="applicant-db">
      <!-- <h3>Additional Documents</h3>
        <a class="btn btn-primary" data-toggle="modal" data-target=".bs-example-modal-sm">Add Documents</a> -->
      <table class="table table-bordered">
        <thead>
          <tr>
            <!-- <th>Sno.</th> -->
            <th>File Name</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of appdoc;let i=index">
            <!-- <td>{{ i+1 }}</td> -->
            <td *ngIf="item.file">{{ item.documentname }}</td>
            <td *ngIf="item.file"><a href="{{ item.file }}" target="_blank"><i class="fa fa-download"></i></a></td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</div>