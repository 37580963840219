<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h4 class="m-n font-bold "><i class="fa fa-angle-left" aria-hidden="true"></i> Assessment List</h4>
          <hr />
        </div>
        <div class="clearfix"></div>
        <div class="col-md-12 table-responsive panel pd">
          <div *ngIf="loading" class="overlay">
            <div class="overlay__inner">
              <div class="overlay__content"><span class="spinner"></span></div>
            </div>
          </div>
          <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col" data-orderable="false">Assessment Start Date</th>
                <th scope="col">Applicant Detail</th>
                <!--  <th scope="col">Assessment Schedule</th>-->
                <th scope="col">Assessment</th>
                <!-- <th scope="col">Status</th>-->
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let allval of AllocationList;let i=index">
                <tr *ngIf="allval.applicantData[0]">
                  <td>{{ i+1 }}</td>
                  <td> {{ allval.period_from|date:'dd-MM-yyyy' }} </td>
                  <td>
                    <span class="app-id">Applicant Name: {{ allval.applicantData[0]?.firstName}}</span><br />
                    <span class="app-id">Org. Name: {{ allval.applicantData[0]?.organizationName}}</span><br />
                    <span>Reg. Email: {{ allval.applicantData[0]?.email}}</span><br />
                    <span>Highest Rank Email: {{ allval.applicantData[0]?.organizationEmail}}</span><br />
                    <span>Contact 1: {{ allval.applicantData[0]?.contactEmail}}</span><br />
                    <span>Contact 2: {{ allval.applicantData[0]?.contactEmail2}}</span><br />
                    <span>{{ allval.applicantData[0]?.criteria}}, {{ allval.applicantData[0]?.sector}}</span><br />
                    <span>Created On: {{ allval.created_at|date:'dd-MM-yyyy' }}</span><br />
                    <span>Operation On: {{ allval.applicantData[0]?.operationForm | date:'dd-MM-yyyy' }}</span><br />
                    <span>Rising Star: {{
                      this.compareDates(allval.applicantData[0]?.operationForm)?'Yes':'No' }}</span>
                  </td>
                  <td>
                    From:<b> {{ allval.period_from|date:'dd-MM-yyyy' }} </b> || To:<b> {{
                      allval.period_to|date:'dd-MM-yyyy' }}</b><br />
                    <table class="table table-bordered nowrap">
                      <thead>
                        <tr>
                          <th>Accepted: <label class="label label-info">{{
                              getAccepted(allval.allocationlistData,'accepted') }}</label></th>
                          <th>Pending: <label class="label label-info">{{
                              getAccepted(allval.allocationlistData,'pending') }}</label></th>
                          <th>Rejected: <label class="label label-info">{{
                              getAccepted(allval.allocationlistData,'rejected') }}</label></th>
                          <th>Team Status: <span
                              [class]="getAccepted(allval.allocationlistData,'accepted') >= 3 ?'text-success':'text-danger'">
                              {{ getAccepted(allval.allocationlistData,'accepted') >= 3 ?'Complete':'Incomplete'
                              }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Contact</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ab of allval.assessorData">
                          <td>{{ ab.firstName }}</td>
                          <td> <a (click)="loginAs(ab)">{{ ab.assessorsEmail }}</a> <label class="btn-xs btn-success"
                              *ngIf="checkArray(allval.allocationlistData,ab._id,'teamleader')==''?false:true">{{
                              checkArray(allval.allocationlistData,ab._id,'teamleader') }}</label><label
                              class="btn-xs btn-info"
                              *ngIf="checkArray(allval.allocationlistData,ab._id,'calibrator')==''?false:true">{{
                              checkArray(allval.allocationlistData,ab._id,'calibrator') }}</label></td>
                          <td> {{ ab.assessorsPhone }} </td>
                          <td>
                            <label class="btn-xs"
                              [class.btn-success]="checkArray(allval.allocationlistData,ab._id,'allocationliststatus') == 'accepted'"
                              [class.btn-danger]="checkArray(allval.allocationlistData,ab._id,'allocationliststatus') == 'rejected'"
                              [class.btn-warning]="checkArray(allval.allocationlistData,ab._id,'allocationliststatus') == 'pending'"
                              [class.text-dark]="checkArray(allval.allocationlistData,ab._id,'allocationliststatus') == 'pending'">
                              {{checkArray(allval.allocationlistData,ab._id,'allocationliststatus') | titlecase}}
                            </label>
                            &nbsp;&nbsp;
                            <button class="btn-xs btn-success"
                              *ngIf="checkArray(allval.allocationlistData,ab._id,'teamleader')==''?false:true">
                              <i class="fa fa-envelope"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <!-- <td ><label [class]="allval.allocation_status=='pending'?'label label-info':'label label-success'"> {{ allval.allocation_status }}</label> </td>-->
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-default dropdown-toggle btn-cls" type="button" id="menu1"
                        data-toggle="dropdown"><span class="app-id"> <i class="fa fa-cog"
                            aria-hidden="true"></i>&nbsp;</span>
                        <span class="caret"></span></button>
                      <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/edit-allocation/{{ allval._id }}">Edit Allocation
                          </a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/view-introduction/{{allval.applicantData[0]._id}}">Introduction
                            Form</a></li>
                        <li role="presentation"><a role="menuitem"
                            (click)="exportbyid(allval.applicantData[0]._id,allval._id)">Export Excel </a></li>
                        <li role="presentation"><a role="menuitem"
                            (click)="exportconsolidatedbyid(allval.applicantData[0]._id,allval._id)">Export Consolidated
                            Excel </a></li>
                        <li role="presentation"><a role="menuitem"
                            (click)="exportconsolidatedwordbyid(allval.applicantData[0]._id,allval._id)">Export Summary
                            Word </a></li>
                        <li role="presentation" (click)="sentmail(allval._id)"><a role="menuitem" tabindex="-1"
                            data-toggle="modal" data-target="#myModal"> Communication Mail
                          </a></li>
                        <li role="presentation" *ngIf="allval.applicantData[0].appdoc">
                          <a role="menuitem" tabindex="-1" href="/view-document/{{ allval.applicantData[0]._id }}">View
                            Document</a>
                        </li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/score-report/{{ allval.applicantData[0]._id }}/{{ allval._id }}">Consensus Score
                            Sheet</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/final-score-report/{{ allval.applicantData[0]._id }}/{{ allval._id }}">Score
                            Summary Sheet</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/checklist-summary/{{ allval.applicantData[0]._id }}/{{ allval._id }}">Checklist
                            Summary</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/executive-summary/{{ allval._id }}">Executive Summary</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="myModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true" (click)="removeCachedData()">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Communication Mail: CII Award for Food Safety 2024</h4>
      </div>
      <div class="modal-body">
        <table class="table table-bordered nowrap">
          <thead>
            <tr>
              <th>1st</th>
              <th>2nd</th>
              <th>3rd</th>
              <th>Name</th>
              <th>Email</th>
              <th>Designation</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ab of assessordata">
              <td><i class="fa fa-check-circle" style="font-size:24px;color:green" *ngIf="ab.first_comm"></i> <input
                  type="checkbox" (click)="getEmail1($event, ab, allocation_id, ab.assessor_id)"
                  *ngIf="ab.allocationliststatus!='rejected'">
              </td>
              <td><i *ngIf="ab.second_comm" class="fa fa-check-circle" style="font-size:24px;color:green"></i> <input
                  type="checkbox" (click)="getEmail2($event, ab, allocation_id, ab.assessor_id)"
                  *ngIf="ab.allocationliststatus!='rejected'">
              </td>
              <td><i *ngIf="ab.third_comm" class="fa fa-check-circle" style="font-size:24px;color:green"></i> <input
                  type="checkbox" (click)="getEmail3($event, ab, allocation_id, ab.assessor_id)"
                  *ngIf="ab.allocationliststatus!='rejected'">
              </td>
              <td>{{ ab.firstName }}</td>
              <td>{{ ab.assessorsEmail }}, {{ ab.assessorsSecondaryemail }}, {{ ab.assessorsTertiaryemail }}</td>
              <td>
                <label class="btn-xs btn-success" *ngIf="ab.teamleader">Team Leader</label>
                <label class="btn-xs btn-info" *ngIf="ab.calibrator">Calibrator</label>
              </td>
              <td>{{ ab.allocationliststatus | titlecase }}</td>
            </tr>
          </tbody>
        </table>
        <button type="button" class="btn btn-secondary" [class.disabled]="wholedata && wholedata.applicant_mail"
          (click)="SendMailApplicant()" [disabled]="wholedata && wholedata.applicant_mail">Send Communication Mail To
          Applicant</button>
        <button *ngIf="coApplicantAvailable" type="button" style="margin-inline-start: 10px;" class="btn btn-secondary"
          [class.disabled]="wholedata && wholedata.coapplicant_mail"
          [disabled]="wholedata && wholedata.coapplicant_mail" (click)="SendMailCoApplicant()">Send Communication Mail
          To
          Co-applicant</button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="SendMail()">Send Communication Mail</button>
      </div>
    </div>
  </div>
</div>
