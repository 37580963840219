import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { CoApplicantService } from '../services/co-applicant.service';
@Component({
  selector: 'app-view-checklist',
  templateUrl: './view-checklist.component.html',
  styleUrls: ['./view-checklist.component.css'],
})
export class ViewChecklistComponent implements OnInit {
  applicant_id: any;
  section: any;
  criteria: any;
  app_email: any;
  questions: any;
  subsection: any;
  activeTab: string = 'applicant';
  answer: any;
  section_no: any;
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private coapp: CoApplicantService
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.applicant_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.applicant
      .GetAdminApplicantSingle(this.applicant_id)
      .subscribe((data: any) => {
        if (
          data.applicanData[0].criteria != '' &&
          data.applicanData[0].userStatus === true
        ) {
          this.criteria = [data.applicanData[0].criteria];
          this.app_email = data.applicanData[0].email;
          this.quest
            .viewQuestionSec({ criteria: this.criteria[0] })
            .subscribe((item: any) => {
              this.section = item.sections;
              this.coapp.listCoApplicant(data.applicanData[0]._id).subscribe((resp: any) => {
                if (resp.result.length) {
                  let criterias = resp.result[0].co_criteria;
                  this.criteria = this.criteria.concat(criterias)
                }
                this.spinner.hide();
              });
            });
        } else {
          this.spinner.hide();
        }
      });
  }
  getQuestion(sec: any) {
    let criteria = this.criteria[0];
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show()
    this.section_no = sec;
    this.quest
      .viewQuestionByCriteria({
        criteria: criteria,
        section_no: sec,
      })
      .subscribe((itemQues: any) => {
        this.quest
          .viewAssessment({
            section_no: sec,
            email: this.app_email,
            criteria: criteria,
            type: 'applicant',
          })
          .subscribe(async (ele: any) => {
            if (ele.ass.length) {
              this.answer = ele.ass[0].assessment[0];
              this.questions = itemQues.sec;
              this.questions.map((items: any) => {
                if (this.answer.hasOwnProperty(items._id)) {
                  items.answer = this.answer[items._id];
                }
              });
              this.subsection = this.removeDuplicateObjectFromArray(
                this.questions,
                'sub_section_no'
              );
              this.spinner.hide();
            } else {
              this.spinner.hide();
              alert('No Data found');
            }
          });
      });
  }
  removeDuplicateObjectFromArray(array: any, key: any) {
    return array.filter(
      (obj: any, index: any, self: any) =>
        index === self.findIndex((el: any) => el[key] === obj[key])
    );
  }
  checkAndGetAnswer(id: any) {
    let milliseconds = 1000;
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.answer.hasOwnProperty(id)) {
          resolve(this.answer[id]);
        }
      }, milliseconds);
    });
  }
  switchTab(userType: any) {
    let criteria = this.criteria[0];
    if (userType == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show();
    this.section_no = '';
    this.activeTab = userType;
    this.subsection = []
    this.quest
      .viewQuestionSec({
        criteria: criteria,
      })
      .subscribe((item: any) => {
        this.section = item.sections;;
        this.spinner.hide();
      });
  }
}
