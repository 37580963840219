<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <div class="wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h4 class="m-n font-bold ">Applicant List</h4>
          <hr />
        </div>
        <div class="col-sm-6 col-xs-12 mb-3 text-right">
        </div>
        <div class="clearfix"></div>
        <div id="col-sm-6">
          <div class="col-md-3">
            <div
              class="panel padder-v-30 m-b-xs item widget-statstic-card r-1x b-a b-gray b-t b-t-3x b-t-success text-center">
              <span class="h4 font-medium text-dark-lter poppins ">Total</span>
              <div class="row no-gutter padder-v b-b b-light">
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.totalApplicant }}</p>
                  <span class="text-10 text-dark-lter poppins">Activated</span>
                </div>
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">0</p>
                  <span class="text-10 text-dark-lter poppins">Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="panel padder-v-30 m-b-xs item widget-statstic-card r-1x b-a b-gray b-t b-t-3x b-t-success text-center">
              <span class="h4 font-medium text-dark-lter poppins ">LOI</span>
              <div class="row no-gutter padder-v b-b b-light">
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.totalLOI }}</p>
                  <span class="text-10 text-dark-lter poppins">Submitted</span>
                </div>
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.totalApplicant-this.totalLOI }}</p>
                  <span class="text-10 text-dark-lter poppins">Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="panel padder-v-30 m-b-xs item widget-statstic-card r-1x b-a b-gray b-t b-t-3x b-t-success text-center">
              <span class="h4 font-medium text-dark-lter poppins ">Introductions</span>
              <div class="row no-gutter padder-v b-b b-light">
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.introSubmitted }}</p>
                  <span class="text-10 text-dark-lter poppins">Submitted</span>
                </div>
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.totalApplicant-this.introSubmitted }}</p>
                  <span class="text-10 text-dark-lter poppins">Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="panel padder-v-30 m-b-xs item widget-statstic-card r-1x b-a b-gray b-t b-t-3x b-t-success text-center">
              <span class="h4 font-medium text-dark-lter poppins ">Checklist</span>
              <div class="row no-gutter padder-v b-b b-light">
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.totalChecklist }}</p>
                  <span class="text-10 text-dark-lter poppins">Completed</span>
                </div>
                <div class="col-xs-6 b-r b-light text-center">
                  <p class="text-lite font-bold m-b-none">{{ this.totalApplicant-this.totalChecklist }}</p>
                  <span class="text-10 text-dark-lter poppins">Pending</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="this.query=='all'?false:true">
          <div class="col-md-4" *ngIf="this.query=='criteria'">
            <label>Criteria</label>
            <select class="form-control" (change)="getData($event,'criteria')">
              <option value="">Select Criteria</option>
              <option value="Criteria 1">Criteria 1</option>
              <option value="Criteria 2 Large">Criteria 2 Large</option>
              <option value="Criteria 2 for SMB">Criteria 2 for SMB</option>
              <option value="Criteria 3 Large">Criteria 3 Large</option>
              <option value="Criteria 3 for SMB">Criteria 3 for SMB</option>
              <option value="Criteria 4">Criteria 4</option>
              <option value="Criteria 5">Criteria 5</option>
              <option value="Criteria 6">Criteria 6</option>
              <option value="Criteria 7">Criteria 7</option>
              <option value="Criteria 8">Criteria 8</option>
              <option value="Criteria 9">Criteria 9</option>
              <option value="Criteria 10">Criteria 10</option>
              <option value="Criteria 11">Criteria 11</option>
              <option value="Criteria 12">Criteria 12</option>
            </select>
          </div>
          <div class="col-md-4" *ngIf="this.query=='state'">
            <label>State </label>
            <select class="form-control" (change)="getData($event,'state')">
              <option value=""> Select State</option>
              <option *ngFor="let obj of statedata" value={{obj}}> {{obj}} </option>
            </select>
          </div>
          <div class="col-md-4" *ngIf="this.query=='sector'">
            <label>Sector </label>
            <select class="form-control" (change)="getData($event,'sector')">
              <option value="">Select Sector</option>
              <option value="{{ sec }}" *ngFor="let sec of sector"> {{ sec }} </option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="clearfix"></div>
          <br /><br />
        </div>
        <div class="col-md-12">
          <div class="table-responsive panel pd">
            <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">LOI</th>
                  <!-- <th scope="col">Instruction</th>-->
                  <th scope="col">CheckList</th>
                  <th scope="col">Introduction</th>
                  <th scope="col">Created</th>
                  <th scope="col">Updated</th>
                  <th scope="col">Rising Star</th>
                  <th scope="col">Name of the Applicant Organisation</th>
                  <th scope="col" style="display: none;"> Name of the Highest Ranking Official of the Organisation</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Name of the Applicant Unit</th>
                  <th scope="col">Criteria</th>
                  <th scope="col">Sector</th>
                  <!--Applicant Name-->
                  <th scope="col">State</th>
                  <th scope="col">City</th>
                  <th scope="col" style="display: none;">Address of the Applicant Unit</th>
                  <th scope="col" style="display: none;">Applicant Unit is in operation from (DD/MM/YYYY)</th>
                  <!-- Applicant Unit is in operation from:  (DD/MM/YYYY)  -->
                  <th scope="col" style="display: none;">Name of the highest Ranking official in Applicant Unit</th>
                  <th scope="col" style="display: none;">Designation</th>
                  <th scope="col" style="display: none;">Email</th>
                  <th scope="col" style="display: none;">Mobile</th>
                  <th scope="col" style="display: none;">Name of the First Point of Contact in Applicant Unit</th>
                  <th scope="col" style="display: none;">Designation</th>
                  <th scope="col" style="display: none;">Email</th>
                  <th scope="col" style="display: none;">Mobile</th>
                  <th scope="col" style="display: none;">Name of the Second Point of Contact Person in Applicant Unit
                  </th>
                  <th scope="col" style="display: none;">Designation</th>
                  <th scope="col" style="display: none;">Email</th>
                  <th scope="col" style="display: none;">Mobile</th>
                  <th scope="col" style="display: none;">Select the sector of operation of the proposed unit for
                    assessment
                    <!-- assessment</th>Product and Services Offered by the Applicant Unit -->
                  <th scope="col" style="display: none;">Product and Services Offered by the Applicant Unit</th>
                  <th scope="col" style="display: none;">Food Category Name and Number under which FSSAI License has
                    been issued</th>
                  <th scope="col" style="display: none;">Total Number of Employees in the Applicant Unit </th>
                  <th scope="col" style="display: none;">Have you applied for the CII Award for Food Safety Award in
                    the past?</th>
                  <th scope="col" style="display: none;">Have you received Highest level of recognition (Outstanding
                    Performance Trophy) in last three years Yes / No</th>
                  <th scope="col" style="display: none;">Investment in Plant & Machinery of the Unit (for
                    Manufacturing) </th>
                  <th scope="col" style="display: none;">Investment in Equipment (for Service Sector) at the proposed
                    unit for assessment</th>
                  <!-- Annual Sales turnover of the Organisation for previous year (INR/USD) 2023-2024: -->
                  <th scope="col" style="display: none;">Annual Sales turnover of the Organisation for previous year
                    (INR/USD) 2023-2024</th>
                  <th scope="col" style="display: none;">Annual Sales turnover of the Applicant unit for previous year
                    (INR/USD) 2023-2024</th>
                  <th scope="col" style="display: none;">Classification</th>
                  <th scope="col" style="display: none;">Invoice Type</th>
                  <th scope="col" style="display: none;">GST NO.</th>
                  <th scope="col" style="display: none;">Billing Address</th>
                  <th scope="col" style="display: none;">Country of the Billing Address</th>
                  <!-- Country of the Billing Address -->
                  <th scope="col" style="display: none;">State of the Billing Address</th>
                  <th scope="col" style="display: none;">City of the Billing Address</th>
                  <th scope="col" style="display: none;">Zip Code of the Billing Address</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody class="assessors-xss">
                <tr *ngFor="let assessorsval of assessors">
                  <td>
                    <span class="app-id">{{ assessorsval?.applicantId}}</span>
                  </td>
                  <td>
                    <p class="label label-{{assessorsval?.UserData[0]?.status?'success':'danger'}}">
                      {{assessorsval?.UserData[0]?.status?'Activated':'Pending'}}</p>
                  </td>
                  <td>
                    <label class="label label-{{ assessorsval?.userStatus?'success':'danger' }}">LOI {{
                      assessorsval?.userStatus?'Submitted':'Pending' }}</label>
                  </td>
                  <!--<td>
                    <p class="label label-{{assessorsval?.InstructionData.length==1?'success':'danger'}}">
                      {{assessorsval?.InstructionData?(assessorsval?.InstructionData.length==1?'Accepted':'Pending'):'N/A'}}
                    </p>
                    <span style="display: block;font-size: 10px;"
                      *ngIf="assessorsval?.InstructionData.length==1">{{assessorsval?.InstructionData[0].created_at|date:'dd-MM-yyyy'}}</span>
                  </td>-->
                  <td>
                    <p class="label label-{{assessorsval?.finalassessmentsData?.length==1?'success':'warning'}}">
                      {{assessorsval?.finalassessmentsData?.length==1?'Completed':'Process'}}</p>
                    <span style="display: block;font-size: 10px;"
                      *ngIf="assessorsval?.finalassessmentsData?.length==1">{{assessorsval?.finalassessmentsData?.length==1?(assessorsval?.finalassessmentsData[0].created_at|date:'dd-MM-yyyy'):''}}</span>
                  </td>
                  <td>
                    <p
                      class="label label-{{assessorsval?.Intro && assessorsval?.Intro.userStatus ? 'success':'warning'}}">
                      {{assessorsval?.Intro && assessorsval?.Intro.userStatus ? 'Submitted':'Pending'}}</p>
                  </td>
                  <td>{{ assessorsval?.created_at|date:'dd-MM-yyyy'}}</td>
                  <td>{{ assessorsval?.updated_at|date:'dd-MM-yyyy'}}</td>
                  <td>{{ this.compareDates(assessorsval?.operationForm)?'Yes':'No' }}</td>
                  <td><span class="app-id">{{ assessorsval?.organizationName}}</span></td>
                  <td style="display: none;">{{assessorsval?.hightestRanking}}</td>
                  <td>{{ assessorsval?.designation}}</td>
                  <td>{{ assessorsval?.email}} </td>
                  <td>{{ assessorsval?.mobileNo}}</td>
                  <td>{{ assessorsval?.firstName}}</td>
                  <td>{{ assessorsval?.criteria}}</td>
                  <td><span class="app-id" title="{{assessorsval?.product}}">{{ assessorsval?.sector}}</span></td>
                  <td><span class="app-id">{{ assessorsval?.applicantState}}</span> </td>
                  <td>{{ assessorsval?.applicantCity}} </td>
                  <td style="display: none;">{{assessorsval?.applicantAddress1}}</td>
                  <td style="display: none;">{{ assessorsval?.operationForm|date:'dd-MM-yyyy' }}</td>
                  <td style="display: none;">{{assessorsval?.applicanthightestRanking}}</td>
                  <td style="display: none;">{{assessorsval?.applicantDesignation}}</td>
                  <td style="display: none;">{{assessorsval?.organizationEmail}}</td>
                  <td style="display: none;">{{assessorsval?.organizationMobile}}</td>
                  <td style="display: none;">{{assessorsval?.contactPerson}}</td>
                  <td style="display: none;">{{assessorsval?.contactDesignation}}</td>
                  <td style="display: none;">{{assessorsval?.contactEmail}}</td>
                  <td style="display: none;">{{assessorsval?.contactMobile}}</td>
                  <td style="display: none;">{{assessorsval?.contactPerson2}}</td>
                  <td style="display: none;">{{assessorsval?.contactDesignation2}}</td>
                  <td style="display: none;">{{assessorsval?.contactEmail2}}</td>
                  <td style="display: none;">{{assessorsval?.contactMobile2}}</td>
                  <td style="display: none;">{{assessorsval?.othersector}}</td>
                  <td style="display: none;">{{assessorsval?.product}}</td>
                  <td style="display: none;">{{assessorsval?.FoodCategoryNameNumber}}</td>
                  <td style="display: none;">{{assessorsval?.totalEmployee}}</td>
                  <td style="display: none;">{{assessorsval?.isAppliedInAward}}</td>
                  <td style="display: none;">{{assessorsval?.highestPerformance}}</td>
                  <td style="display: none;">{{assessorsval?.applicantProduct}}</td>
                  <td style="display: none;">{{assessorsval?.applicantEquipment}}</td>
                  <td style="display: none;">{{assessorsval?.annualOrganization}}</td>
                  <td style="display: none;">{{assessorsval?.annualApplicant}}</td>
                  <td style="display: none;">{{assessorsval?.classification}}</td>
                  <td style="display: none;">{{assessorsval?.applicantYear}}</td>
                  <td style="display: none;">{{assessorsval?.annualYear}}</td>
                  <td style="display: none;">{{assessorsval?.contactAddress2}}</td>
                  <td style="display: none;">{{assessorsval?.contactCountry2}}</td>
                  <td style="display: none;">{{assessorsval?.contactState2}}</td>
                  <td style="display: none;">{{assessorsval?.contactCity2}}</td>
                  <td style="display: none;">{{assessorsval?.contactZipCode2}}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-default dropdown-toggle btn-cls" type="button" id="menu1"
                        data-toggle="dropdown"><span class="app-id"> <i class="fa fa-cog"
                            aria-hidden="true"></i>&nbsp;</span>
                        <span class="caret"></span></button>
                      <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                        <li role="presentation" *ngIf="!assessorsval?.__v"><a role="menuitem" tabindex="-1"
                            href="/edit-Loi/{{assessorsval?._id}}">Access Details</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            href="/view-checklist/{{ assessorsval?._id }}">Applicant Checklist</a></li>
                        <li role="presentation" *ngIf="!assessorsval?.__v"><a role="menuitem" tabindex="-1"
                            href="/view-introduction/{{ assessorsval?._id }}">Introduction Form</a></li>
                        <li role="presentation" *ngIf="assessorsval?.appdoc"><a role="menuitem" tabindex="-1"
                            href="/view-document/{{ assessorsval?._id }}">View Documents</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1"
                            (click)="deleteApplicante(assessorsval?._id)">Delete</a>
                        </li>
                        <!-- <li *ngIf="assessorsval?.email=='rajesh.kopparapu@varuna.net'"><a
                            href="/joint-applicant-varuna" target="_blank">View Joint Applicant</a></li> -->
                        <li *ngIf="assessorsval?.sector==='Joint Application'"><a
                            routerLink="/co-applicant/{{ assessorsval?.codata[0]?._id }}">View Joint
                            Applicant</a>
                        </li>
                        <!-- <li *ngIf="assessorsval?.email==='negi@tirupatilifesciences.com'"><a
                            href="/joint-applicant-tirupati" target="_blank">View Joint Applicant</a></li> -->
                        <!-- <li *ngIf="assessorsval?.email==='manish@britindia.com'"><a
                            href="/joint-applicant-aico" target="_blank">View Joint Applicant</a></li> -->
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <!-- <div class="modal fade" id="exampleModalCenter12" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle" style="font-size: 28px;">Delete</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4>Are you sure you want to delete this user?</h4>
          <p style="color: red;">Recovery of the data will not be possible once deleted.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="deleteApplicante()">Delete</button>
        </div>
      </div>
    </div>
  </div> -->
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we are getting the applicants. </p>
</ngx-spinner>
