import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AllocationService } from 'src/app/services/allocation.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { ApplicantService } from 'src/app/services/applicant.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { base_url_upload } from 'src/app/global';
import { TosterService } from 'src/app/services/toster.service';
@Component({
  selector: 'app-ass-assessment-information',
  templateUrl: './ass-assessment-information.component.html',
  styleUrls: ['./ass-assessment-information.component.css'],
})
export class AssAssessmentInformationComponent implements OnInit {
  udata: any;
  shownotif: any;
  constructor(public allocation: AllocationService,
    private toast: TosterService,
    private spinner: NgxSpinnerService,
    private applicants: ApplicantService,) { }
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  AllocationList: any;
  loading: boolean = false;
  hideStatus: Boolean = false;
  ngOnInit(): void {
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lfrtip',
    };
    this.allocation
      .getAllAllocationByAssessor(this.udata._id)
      .subscribe((item: any) => {
        this.AllocationList = item.result.filter((data: any) => data.allocationliststatus !== 'rejected');
        this.AllocationList.map((item: any) => {
          if (item.applicantData[0] && item.applicantData[0]._id) {
            this.applicants.getAppDoc(item?.applicantData[0]?._id).subscribe((datar: any) => {
              if (item.applicantData && item.applicantData[0]) {
                item.applicantData[0].appdoc = datar[0] && datar[0].documentname ? true : false;
              }
            });
          }
          item.enableStatus = true;
        });
        this.dtTrigger.next();
      });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  compareDates(dt: any, createdDate: any): boolean | undefined {
    let currentDate = new Date(createdDate);
    let applicantDate = new Date(dt);
    let threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
    if (applicantDate.getTime() >= threeYearsAgo.getTime() && applicantDate.getTime() <= currentDate.getTime()) {
      return true;
    }
    return false;
  }
  updateStatus(
    e: any,
    _id: any,
    status: any,
    email: any,
    tl: any,
    applicantName: any
  ) {
    Swal.fire({
      title: 'Are you sure?',
      text:
        'You want to ' +
        status +
        ' your allocation. Once submitted, you will not be able to make any changes.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit',
    }).then((result) => {
      if (result.isConfirmed) {
        this.allocation
          .updateStatusAllocationByAssessor(_id, {
            status: status,
            email: email,
            tl: tl,
            assessor: this.udata.username,
            applicantName: applicantName,
          })
          .subscribe((item: any) => {
            window.location.reload();
          });
      }
    });
  }
  checkValidity(status: any, from: any, to: any): boolean | undefined {
    if (status != 'accepted' || from == null || to == null) {
      return false;
    } else {
      let mm_from = moment(from).startOf('day');
      let mm_to = moment(to).endOf('day').add(30, 'days');
      let modifyDate_from = mm_from.subtract(7, 'days');
      let ass_status = moment().isBetween(modifyDate_from, mm_to);
      return ass_status;
    }
  }
  updateView(i: any, statuss: any) {
    this.AllocationList[i].enableStatus = !statuss;
  }
  viewDocument(data: any) {}
  exportbyid(id: string, ass_id: string) {
    this.loading = true;
    this.allocation
      .exportbyid(id, ass_id)
      .subscribe((res: any) => {
        var file_name = res.exported;
        let url = base_url_upload + "download-export/" + file_name;
        window.open(url);
        this.loading = false;
      });
  }
  exportconsolidatedbyid(id: string, ass_id: string) {
    this.loading = true;
    this.allocation
      .exportconsolidatedbyid(id, ass_id)
      .subscribe((res: any) => {
        var file_name = res.exported;
        let url = base_url_upload + "download-export/" + file_name;
        window.open(url);
        this.loading = false;
      });
  }
  exportconsolidatedwordbyid(id: string, ass_id: string) {
    this.loading = true;
    this.allocation
      .exportconsolidatedwordbyid(id, ass_id)
      .subscribe((res: any) => {
        var file_name = res.exported;
        let url = base_url_upload + "download-export/" + file_name;
        window.open(url);
        this.loading = false;
      });
  }
  notify(data?: any) {
    this.toast.showError(data + " has not submitted their questionnaire yet.")
  }
}
