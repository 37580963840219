<app-header></app-header>
<app-sidenav></app-sidenav>
<div _ngcontent-tmj-c381="" id="content" role="main" class="app-content">
    <div class="app-content-body">
        <div class="wrapper-md">
            <!-- <div class="row"> -->
                <div class="report">
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Report 1</h4>
                        </div>
                    </div>
                    <div class="bb"></div>
                    <div class="row">
                        <!-- <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Assesseor Name</span>
                                </div>
                                <select class="form-control" name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Assesseor Name</span>
                                </div>
                                <select class="form-control" name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Status</span>
                                </div>
                                <select class="form-control" name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="col-md-12">
                            <!-- <div class="input-group mt-3 search_inp">
                                <div class="input-group-prepend">
                                    <input placeholder="Search" class="form-control" type="search" name="" id="">
                                </div>
                            </div> -->
                            <div class="clearfix"></div>
                            <!-- <div class="input-group mt-3 search_inp">
                                <div class="input-group-prepend mb-3" style="margin-bottom: 20px;">
                                    <button >Export to excel</button>
                                </div>
                            </div> -->
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>S No</th>
                                    <th>Assessor Name</th>
                                    <th>Number of Assessment</th>
                                    <th>Applicant Name</th>
                                    <th>Schedule</th>
                                    <th>Status</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of AllocationList; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{item._id.assessorName[0]}} <span *ngIf="item._id.teamleader" style="color: rgb(31, 70, 245);">(TL)</span> <span *ngIf="item._id.teamleader.calibrator " style="color: rgb(4, 248, 236);">(C)</span></td>
                                    <td>{{item.count}}</td>
                                    <td>{{item._id.applicantName[0]}}</td>
                                    <td > <span *ngIf="item._id.period_from[0]"> <span>{{item._id.period_from[0] |date:'dd-MM-yyyy'}}</span> to <span> {{item._id.period_to[0] |date:'dd-MM-yyyy'}}</span> </span> </td>
                                    <td> <span *ngIf="item._id.allocationliststatus==='accepted'" style="color:rgb(105, 201, 105);" >Accepted</span>  
                                        <span *ngIf="item._id.allocationliststatus==='rejected'" style="color:rgb(233, 104, 104);" >Rejected</span>  
                                        <span *ngIf="item._id.allocationliststatus==='pending'" style="color:rgb(243, 207, 88);" >Pending</span>  
                              
                                    </td>
                                 
                                </tr>
                            </tbody>
                              
                           
                            </table>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div>