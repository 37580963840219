import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-criteria-report',
  templateUrl: './criteria-report.component.html',
  styleUrls: ['./criteria-report.component.css']
})
export class CriteriaReportComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
