<app-header></app-header>
<app-sidenav></app-sidenav>
<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <form [formGroup]="assessorsForm" (ngSubmit)="clickFunction()">
      <div class="b-b-none wrapper-md main">
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <h4 class="m-n font-bold ">Create Assessors</h4>
          </div>
          <div class="col-sm-6 text-right poppins hidden-xs pull-right header-btn">
          </div>
        </div>
      </div>
      <div class="wrapper-md" ng-controller="FormDemoCtrl">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><b>Basic Information</b>
                <p class="labelInfo">Please complete the basic information section</p>
              </div>
              <div class="panel-body">
                <div class="col-md-6 form-group">
                  <label>Name of the Assessor </label>
                  <p class="labelInfo">Enter the full name of the assessor</p>
                  <input type="text" class="form-control" placeholder=" Enter" formControlName="firstName">
                </div>
                <div class="col-md-6 form-group">
                  <label>Date of Birth </label>
                  <p class="labelInfo">Enter the date of birth of the assessor</p>
                  <input type="date" class="form-control" placeholder="Please input Date (dd/mm/yy)"
                    formControlName="assessorsDate">
                </div>
                <div class="col-md-6 form-group">
                  <label>Designation </label>
                  <p class="labelInfo">Enter the designation of the assessor</p>
                  <input type="text" class="form-control" placeholder="Enter" formControlName="designation"
                    style="margin-top: 2.7rem;">
                </div>
                <div class="col-md-6 form-group">
                  <label>Email </label>
                  <p class="labelInfo">Enter the official email address of the assessor for communication (link for
                    account activation shall be sent on this e-mail id)</p>
                  <input type="text" class="form-control email" placeholder="Enter " formControlName="assessorsEmail">
                  <div *ngIf="f.assessorsEmail.errors && f.assessorsEmail.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.assessorsEmail.errors.pattern">Email is not valid</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>Secondary Email </label>
                  <p class="labelInfo">Enter the secondary email address </p>
                  <input type="text" class="form-control email" placeholder="Enter" formControlName="assessorsSecondaryemail">
                  <div
                    *ngIf="f.assessorsSecondaryemail.errors && f.assessorsSecondaryemail.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.assessorsSecondaryemail.errors.pattern">Email is not valid</div>
                  </div>
                </div>
                
                <div class="col-md-6 form-group">
                  <label>Tertiary Email </label>
                  <p class="labelInfo">Enter the tertiary email address </p>
                  <input type="text" class="form-control email" placeholder="Enter" formControlName="assessorsSecondaryemail">
                  <div
                    *ngIf="f.assessorsTertiaryemail.errors && f.assessorsTertiaryemail.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.assessorsTertiaryemail.errors.pattern">Email is not valid</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>Mobile No </label>
                  <p class="labelInfo">Enter the official mobile number </p>
                  <div class="input-group">
                    <span class="input-group-addon">+91
                    </span>
                    <input type="text" class="form-control" placeholder="Enter " formControlName="assessorsPhone"
                      maxlength="10" minlength="10">
                  </div>
                  <div *ngIf="f.assessorsPhone.errors && f.assessorsPhone.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.assessorsPhone.errors.pattern">Please Enter 10 digit Mobile Number.</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>Alternate Mobile No. </label>
                  <p class="labelInfo">Enter the alternate mobile no </p>
                  <div class="input-group">
                    <span class="input-group-addon">+91
                    </span>
                    <input type="text" class="form-control" placeholder="Enter Phone Number"
                      formControlName="assessorsAlternateno" maxlength="10" minlength="10">
                  </div>
                  <div
                    *ngIf="f.assessorsAlternateno.errors && f.assessorsAlternateno.errors.pattern && isValidFormSubmitted"
                    class="text-danger text-left mb-1">
                    <div *ngIf="f.assessorsAlternateno.errors.pattern">Please Enter 10 digit Mobile Number.</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>Education Qualification </label>
                  <p class="labelInfo">Enter the highest qualification</p>
                  <input type="text" class="form-control" placeholder="Enter Education"
                    formControlName="assessorsEducation">
                </div>
                <div class="row">
                  <div class="col-md-12" id="">
                    <div class="bTop"></div>
                    <div class="col-md-6">
                      <!-- <h3 class="assessorsAddress">Residential Address	</h3> -->
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Residential Address </label>
                          <p class="labelInfo">Enter residential address </p>
                          <textarea type="text" rows="3.5" class="form-control " placeholder="Enter" id=""
                            formControlName="residentialAddressline"></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Country </label>
                          <select class="form-control" formControlName="residentialCountry">
                            <option value="India">India</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>State </label>
                          <select class="form-control" formControlName="residentialState"
                            (change)="getdistrictResidential($event)">
                            <option value=""> Select State</option>
                            <option *ngFor="let obj of statedata" value={{obj}}> {{obj}} </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>City </label>
                          <select class="form-control" formControlName="residentialCity">
                            <option> Select City</option>
                            <option *ngFor="let dobj of getdistrictApplicant" value={{dobj}}> {{dobj}} </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label> Zip Code </label>
                          <input type="text" class="form-control " placeholder="Enter "
                            formControlName="residentialZipcode" maxlength="6" minlength="6">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <!-- <h3 class="assessorsAddress">Official Address	</h3> -->
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Official Address </label>
                          <p class="labelInfo">Enter the current official address </p>
                          <textarea type="text" rows="3.5" class="form-control " placeholder="Enter" id=""
                            formControlName="organizationAddressline1"></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Country </label>
                          <select class="form-control" formControlName="organizationCountry">
                            <option value="India">India</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>State </label>
                          <select class="form-control" formControlName="organizationState"
                            (change)="getdistrictOffice($event)">
                            <option value=""> Select State</option>
                            <option *ngFor="let obj of statedata" value={{obj}}> {{obj}} </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>City </label>
                          <select class="form-control" formControlName="organizationCity">
                            <option value=""> Select City</option>
                            <option *ngFor="let dobj of districtdataOffice" value={{dobj}}> {{dobj}} </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label> Zip Code </label>
                          <input type="text" class="form-control " placeholder="Enter "
                            formControlName="organizationZipcode" maxlength="6" minlength="6">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- start  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class=" panel-heading"><b>Organization Information</b> </div>
              <div class="panel-body">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Zone </label>
                      <p class="labelInfo">Select the zone</p>
                      <select class="form-control" formControlName="zone">
                        <option value="">Select Zone </option>
                        <option value="East"> East</option>
                        <option value="North"> North</option>
                        <option value="Central"> Central</option>
                        <option value="South"> South</option>
                        <option value="West"> West</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Location </label>
                      <p class="labelInfo">Enter location</p>
                      <input type="text" class="form-control " placeholder="Enter"
                        formControlName="OrganizationLocation">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Batch </label>
                      <p class="labelInfo">Enter the batch</p>
                      <input type="text" formControlName="batch" class="form-control" placeholder="Enter ">
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="row">
                  <div class="col-md-12" id="">
                    <div class="bTop"></div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Organization Name </label>
                          <p class="labelInfo">Enter current organization name</p>
                          <input type="text" class="form-control" placeholder="Enter " formControlName="Organization">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Designation </label>
                          <p class="labelInfo">Enter your designation at your current organization </p>
                          <input type="text" class="form-control" placeholder="Enter Designation"
                            formControlName="organizationDesignation">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Product / Service of the Organisation </label>
                          <p class="labelInfo">Enter the current organization’s product/ service</p>
                          <input type="text" class="form-control" placeholder="Enter "
                            formControlName="organizationProductservice">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="row">
                  <div class="col-md-12" id="">
                    <div class="bTop"></div>
                    <div class="row">
                      <div class="col-md-12">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12 form-group ">
                          <label>Organization Name</label>
                          <p class="labelInfo">Enter your previous organization name</p>
                          <input type="text" class="form-control" placeholder="Enter " formControlName="Organization2">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group ">
                          <label>Designation</label>
                          <p class="labelInfo">Enter your designation at your previous organization </p>
                          <input type="text" class="form-control" placeholder="Enter "
                            formControlName="organizationDesignation2">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <label>Product / Service of the Organisation </label>
                          <p class="labelInfo">Enter the previous organization’s product/ service</p>
                          <input type="text" class="form-control" placeholder="Enter "
                            formControlName="organizationProductservice2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->
        <!-- start  -->
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><b>Reporting to / Official to whom 'Assessor Invitation' copy is to be
                  marked</b>
                <p class="labelInfo">Enter the details of the official to whom you report in your current organization
                  (copy of assessor invitation shall be marked)</p>
              </div>
              <div class="panel-body">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Name </label>
                      <input type="text" class="form-control " placeholder="Enter" formControlName="org_Fullname">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Designation</label>
                      <input type="text" class="form-control " placeholder="Enter" formControlName="report_Designation">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>FSMS Certificate </label>
                      <p class="labelInfo">Enter certificate name</p>
                      <input type="text" class="form-control " placeholder="Enter" formControlName="fsmsCertificate"
                        style="margin-top: 1.5rem;">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Exposure / Experience </label>
                      <p class="labelInfo">Enter experience in (tqm / tpm / six sigma / business excellence /
                        improvement initiatives (whether trend / implemented / facilitated))</p>
                      <input type="text" class="form-control " placeholder="Enter" formControlName="exposure">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>SKILLS </label>
                      <p class="labelInfo">Enter the skills name </p>
                      <input type="text" class="form-control " placeholder="Enter" formControlName="skill">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Email </label>
                      <input type="text" class="form-control email" placeholder="Enter " formControlName="org_Email">
                      <div *ngIf="f.org_Email.errors && f.org_Email.errors.pattern && isValidFormSubmitted"
                        class="text-danger text-left mb-1">
                        <div *ngIf="f.org_Email.errors.pattern">Email is not valid</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Are you a qualified lead auditor in FSMS </label>
                      <div class="input-group">
                        <input type="radio" formControlName="assessorsFsmsqualified" value="Yes"> Yes
                        &nbsp; <input type="radio" formControlName="assessorsFsmsqualified" value="No"> No
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Domain Expertise </label>
                      <p class="labelInfo">Enter domain name (e.g.: bakery / dairy / confectionary / beverages /
                        nutraceuticals or any other food categories)</p>
                      <input type="text" class="form-control" placeholder="Enter " formControlName="domain">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label>Professional Training </label>
                      <p class="labelInfo">Enter the list of training undergone</p>
                      <input type="text" class="form-control " placeholder="Enter" formControlName="assessorsTraining"
                        style="margin-top: 2.3rem;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->
        <div style="text-align: right; margin:10px 0px;">
          <button type="submit" class=" bt danger">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
