<app-header></app-header>
<app-sidenav-applicant *ngIf="this.udata.role=='applicant'"></app-sidenav-applicant>
<app-sidenav *ngIf="this.udata.role!='applicant' && this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Please wait while CII FACE Introduction Form is loading... </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body " [ngClass]="this.submitbutton?'after-submit':'before-submit'">
    <form [formGroup]="appForm" (ngSubmit)="submitIntro()" id="applicantForm">
      <div class="b-b-none wrapper-md main">
        <div class="row alignMid">
          <div class="col-sm-4 col-xs-12">
            <h4 class="m-n font-bold " #progress>Introduction Form</h4>
          </div>
        </div>
      </div>
      <div class="wrapper-md" ng-controller="FormDemoCtrl">
        <div class="alert alert-info info-circle-alert">
          <i class="fa fa-info-circle"></i>
          Introduction: Key Information
          <br>(All sections are mandatory)
        </div>
        <div id="progress-div">
          <ul id="progressbar">
            <li *ngFor="let steps of stepArray; let i = index" [class.pending]="stepArray[i]"
              [class.active]="stepCounter>i? true:false" id="stepicon">Step {{i+1}}</li>
          </ul>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
        <div class="text-center">
          <div style="margin:10px 0px;">
            <button type="button" class="btn btn-info steps" (click)="stepBackward(progress)"
              [disabled]="stepArray?.[0]"><i class="fa fa-step-backward"></i> Previous Step
            </button>&nbsp;
            <button type="button" class="btn btn-danger steps" (click)="stepForward(progress); "
              [disabled]="stepArray[8]">Next Step <i class="fa fa-step-forward"></i>
            </button>&nbsp;
          </div>
        </div>
        <div [hidden]="!stepArray[0]" [@formfade]="stepArray[0] ? 'active' : 'inactive' " class="step1">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-body">
                  <div formGroupName="organizationProfile">
                    <button type="button" (click)="ExportToExcel()">Export</button>
                    <table id="example" class="display nowrap" #table>
                      <tbody>
                        <tr>
                          <td>Organization Profile</td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Location Details</td>
                        </tr>
                        <tr>
                          <td><u>Location name </u></td>
                          <td> {{applicantSdata.organizationProfile?.[0] === undefined?'':
                            applicantSdata.organizationProfile?.[0].locationName}}</td>
                        </tr>
                        <tr>
                          <td>Location Address </td>
                          <td> {{applicantSdata.organizationProfile?.[0] === undefined?'':
                            applicantSdata.organizationProfile?.[0].locationAddress}} </td>
                        </tr>
                        <tr>
                          <td>Location City </td>
                          <td> {{applicantSdata.organizationProfile?.[0] === undefined?'':
                            applicantSdata.organizationProfile?.[0].locationCity}} </td>
                        </tr>
                        <tr>
                          <td>Location State </td>
                          <td> {{applicantSdata.organizationProfile?.[0] === undefined?'':
                            applicantSdata.organizationProfile?.[0].locationState}}</td>
                        </tr>
                        <tr>
                          <td>Location Zip </td>
                          <td> {{applicantSdata.organizationProfile?.[0] === undefined?'':
                            applicantSdata.organizationProfile?.[0].locationZip}}</td>
                        </tr>
                        <tr>
                          <td>Scope of Award Application </td>
                          <td>
                            <div
                              [innerHtml]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].scopeAward">
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!--
               <ckeditor [config]="{ readOnly: 'true' }"  [config]="{ readOnly: 'true' }" required [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].scopeAward" placeholder=""  formControlName="scopeAward" >
                </ckeditor> -->
                    <div class="clearfix"></div>
                    <div class="bTop"></div>
                    <h4>Location Head Details</h4>
                    <div class="col-md-6 form-group">
                      <label>Name of location head </label>
                      <p class="labelInfo"> </p>
                      <input required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].locationHeadName"
                        type="text" class="form-control " placeholder="" formControlName="locationHeadName">
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','locationHeadName'])?.touched && this.appForm.get(['organizationProfile','locationHeadName'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>E-mail of location head </label>
                      <p class="labelInfo"> </p>
                      <input required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].locationHeadEmail"
                        type="text" class="form-control " placeholder="" formControlName="locationHeadEmail">
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','locationHeadEmail'])?.touched && this.appForm.get(['organizationProfile','locationHeadEmail'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Mobile No. of location head </label>
                      <p class="labelInfo"> </p>
                      <input required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].locationHeadMobile"
                        type="text" class="form-control " placeholder="" formControlName="locationHeadMobile">
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','locationHeadMobile'])?.touched && this.appForm.get(['organizationProfile','locationHeadMobile'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="bTop"></div>
                    <h4>Location Contact Details</h4>
                    <div class="col-md-6 form-group">
                      <label>Name of location Contact </label>
                      <p class="labelInfo"> </p>
                      <input required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].locationContactdName"
                        type="text" class="form-control " placeholder="" formControlName="locationContactName">
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','locationContactName'])?.touched && this.appForm.get(['organizationProfile','locationContactName'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>E-mail of location Contact </label>
                      <p class="labelInfo"> </p>
                      <input required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].locationContactEmail"
                        type="text" class="form-control " placeholder="" formControlName="locationContactEmail">
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','locationContactEmail'])?.touched && this.appForm.get(['organizationProfile','locationContactEmail'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Mobile No. of location Contact </label>
                      <p class="labelInfo"> </p>
                      <input required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'': applicantSdata.organizationProfile?.[0].locationContactMobile"
                        type="text" class="form-control " placeholder="" formControlName="locationContactMobile">
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','locationContactMobile'])?.touched && this.appForm.get(['organizationProfile','locationContactMobile'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="bTop"></div>
                    <div class="col-md-6 form-group">
                      <label>Products/Services</label>
                      <p class="labelInfo"> Types, Food Sector, Numbers</p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].productsServices"
                        placeholder="" formControlName="productsServices">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','productsServices'])?.touched && this.appForm.get(['organizationProfile','productsServices'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Customers </label>
                      <p class="labelInfo">Main Customers/ Customer segments, Share of Business </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].customers"
                        placeholder="" formControlName="customers">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','customers'])?.touched && this.appForm.get(['organizationProfile','customers'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Suppliers </label>
                      <p class="labelInfo"> Main Suppliers, material </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].suppliers"
                        placeholder="" formControlName="suppliers">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','suppliers'])?.touched && this.appForm.get(['organizationProfile','suppliers'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Annual Sales of last 3 years in a graph </label>
                      <p class="labelInfo">Domestic, Exports </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].annualSales"
                        placeholder="" formControlName="annualSales">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','annualSales'])?.touched && this.appForm.get(['organizationProfile','annualSales'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Distributors/ Franchisees, if any </label>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].distributors"
                        placeholder="" formControlName="distributors">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','distributors'])?.touched && this.appForm.get(['organizationProfile','distributors'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Competitors </label>
                      <p class="labelInfo">Main Competitors </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].competitors"
                        placeholder="" formControlName="competitors">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','competitors'])?.touched && this.appForm.get(['organizationProfile','competitors'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>People</label>
                      <p class="labelInfo"> Numbers, Managerial, Supervisory and Workers, Casuals </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].people"
                        placeholder="" formControlName="people">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','people'])?.touched && this.appForm.get(['organizationProfile','people'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Organisation Structure </label>
                      <p class="labelInfo">Attach chart </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].orgStructure"
                        placeholder="" placeholder="" formControlName="orgStructure">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','orgStructure'])?.touched && this.appForm.get(['organizationProfile','orgStructure'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Collaborations, if any</label>
                      <p class="labelInfo"> - List details </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].collaborations"
                        placeholder="" formControlName="collaborations">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','collaborations'])?.touched && this.appForm.get(['organizationProfile','collaborations'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Certifications, awards, accolades received </label>
                      <p class="labelInfo">List details </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].certifications"
                        placeholder="" formControlName="certifications">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','certifications'])?.touched && this.appForm.get(['organizationProfile','certifications'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Technologies</label>
                      <p class="labelInfo">-List main technologies </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].technologies"
                        placeholder="" formControlName="technologies">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','technologies'])?.touched && this.appForm.get(['organizationProfile','technologies'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Key Business Challenges </label>
                      <p class="labelInfo"> List details </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].keyChallenges"
                        placeholder="" formControlName="keyChallenges">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','keyChallenges'])?.touched && this.appForm.get(['organizationProfile','keyChallenges'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>IT Facilities </label>
                      <p class="labelInfo"> - List details </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].itFacilities"
                        placeholder="" formControlName="itFacilities">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','itFacilities'])?.touched && this.appForm.get(['organizationProfile','itFacilities'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Any challenging or adverse business situations overcome successfully </label>
                      <p class="labelInfo"> </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].overcome"
                        placeholder="" formControlName="overcome">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','overcome'])?.touched && this.appForm.get(['organizationProfile','overcome'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-6 form-group">
                      <label>Process Flow Chart of each Product</label>
                      <p class="labelInfo"> Attach chart </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.organizationProfile?.[0] === undefined?'':applicantSdata.organizationProfile?.[0].processFlow"
                        placeholder="" formControlName="processFlow">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['organizationProfile','processFlow'])?.touched && this.appForm.get(['organizationProfile','processFlow'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[1]" [@formfade]="stepArray[1] ? 'active' : 'inactive' " class="step2">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b>Key Information related to Food Safety over the last 3 years</b>
                  <p class="labelinfo">Please fill in this key information related to Food Safety over the last 3 years
                    in the template provided below. The information should cover the following, as applicable:
                  </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="keyFoodSafety">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th></th>
                            <th>2021-2022</th>
                            <th>2022-2023</th>
                            <th>2023-2024 till date</th>
                          </tr>
                          <tr>
                            <td>Any Food Safety Incidents (absolute numbers)</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].incidents1920"
                                placeholder="Incidents 2021-22" formControlName="incidents1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].incidents2021"
                                placeholder="Incidents 2022-23" formControlName="incidents2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].incidents2122"
                                placeholder="Incidents 2023-2024" formControlName="incidents2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Last date of inspection by Regulatory body (specify Regulatory body/bodies) </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].inspection1920"
                                placeholder="" formControlName="inspection1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].inspection2021"
                                placeholder="" formControlName="inspection2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].inspection2122"
                                placeholder="" formControlName="inspection2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Number of NCRs of last inspection (extent of compliance) </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].ncr1920"
                                placeholder="Number of NCRs of last inspection (extent of compliance) 2021-22"
                                formControlName="ncr1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].ncr2021"
                                placeholder="Number of NCRs of last inspection (extent of compliance) 2022-23"
                                formControlName="ncr2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.keyFoodSafety?.[0] === undefined?'': applicantSdata.keyFoodSafety?.[0].ncr2122"
                                placeholder="Number of NCRs of last inspection (extent of compliance) 2023-2024"
                                formControlName="ncr2122">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[2]" [@formfade]="stepArray[2] ? 'active' : 'inactive' " class="step3">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b>Organisation Performance (Results) </b>
                  <p class="labelinfo">Applicants are required to present results achieved with respect to Business
                    Performance
                    <br> Results of the Applicant Unit may be presented in the form of line graphs, bar charts, tables,
                    numbers, figures, data as appropriate for past 3 years
                  </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="organizationPerformance">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th></th>
                            <th>2021-2022</th>
                            <th>2022-2023</th>
                            <th>2023-2024 till date</th>
                          </tr>
                          <tr>
                            <td>Total Revenue/ Turnover/ Export if any of products in the scope</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].revenue1920"
                                placeholder="" formControlName="revenue1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].revenue2021"
                                placeholder="" formControlName="revenue2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].revenue2122"
                                placeholder="" formControlName="revenue2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Inventory levels </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].inventory1920"
                                placeholder="" formControlName="inventory1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].inventory2021"
                                placeholder="" formControlName="inventory2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].inventory2122"
                                placeholder="" formControlName="inventory2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>New Products (Number and types) </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].products1920"
                                placeholder="" formControlName="products1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].products2021"
                                placeholder="" formControlName="products2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].products2122"
                                placeholder="" formControlName="products2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Any other as relevant </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].other1920"
                                placeholder="" formControlName="other1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].other2021"
                                placeholder="" formControlName="other2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.organizationPerformance?.[0] === undefined?'':applicantSdata.organizationPerformance?.[0].other2122"
                                placeholder="" formControlName="other2122">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[3]" [@formfade]="stepArray[3] ? 'active' : 'inactive' " class="step4">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b>Trend Results as Applicable: </b>
                  <p class="labelinfo"> </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="trendResults">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th></th>
                            <th>2021-2022</th>
                            <th>2022-2023</th>
                            <th>2023-2024 till date</th>
                          </tr>
                          <tr>
                            <td>1. Segmented Customer Complaints on Food Safety (Absolute Numbers)</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].a_1920"
                                placeholder="" formControlName="a_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].a_2021"
                                placeholder="" formControlName="a_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].a_2122"
                                placeholder="" formControlName="a_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>2. Segmented Customer Satisfaction Indices (if available)</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].b_1920"
                                placeholder="" formControlName="b_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].b_2021"
                                placeholder="" formControlName="b_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].b_2122"
                                placeholder="" formControlName="b_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>3. Rework%</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].c_1920"
                                placeholder="" formControlName="c_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].c_2021"
                                placeholder="" formControlName="c_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].c_2122"
                                placeholder="" formControlName="c_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>4. Scrap %</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].d_1920"
                                placeholder="" formControlName="d_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].d_2021"
                                placeholder="" formControlName="d_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].d_2122"
                                placeholder="" formControlName="d_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>5. Process / Product Audit Scores</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].e_1920"
                                placeholder="" formControlName="e_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].e_2021"
                                placeholder="" formControlName="e_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].e_2122"
                                placeholder="" formControlName="e_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>6. Finished Good Test Results</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].f_1920"
                                placeholder="" formControlName="f_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].f_2021"
                                placeholder="" formControlName="f_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].f_2122"
                                placeholder="" formControlName="f_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>7.       Stratified Pest Trends</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].g_1920"
                                placeholder="" formControlName="g_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].g_2021"
                                placeholder="" formControlName="g_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].g_2122"
                                placeholder="" formControlName="g_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>8.       Employee Participation % in Improvement Projects</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].h_1920"
                                placeholder="" formControlName="h_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].h_2021"
                                placeholder="" formControlName="h_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].h_2122"
                                placeholder="" formControlName="h_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>9.       Innovative Reward Recognition Schemes for all levels of employees</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].i_1920"
                                placeholder="" formControlName="i_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].i_2021"
                                placeholder="" formControlName="i_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].i_2122"
                                placeholder="" formControlName="i_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>10.   No of recognitions awarded for each level of employees</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].j_1920"
                                placeholder="" formControlName="j_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].j_2021"
                                placeholder="" formControlName="j_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].j_2122"
                                placeholder="" formControlName="j_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>11.   Key Equipment wise Machine Break Down Trend Data</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].k_1920"
                                placeholder="" formControlName="k_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].k_2021"
                                placeholder="" formControlName="k_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].k_2122"
                                placeholder="" formControlName="k_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>12.   Overall Equipment Effectiveness</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].l_1920"
                                placeholder="" formControlName="l_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].l_2021"
                                placeholder="" formControlName="l_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].l_2122"
                                placeholder="" formControlName="l_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>13.   Inventory Level (RM, FG, PM)</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].m_1920"
                                placeholder="" formControlName="m_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].m_2021"
                                placeholder="" formControlName="m_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].m_2122"
                                placeholder="" formControlName="m_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>14.   Absenteeism Rate</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].n_1920"
                                placeholder="" formControlName="n_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].n_2021"
                                placeholder="" formControlName="n_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].n_2122"
                                placeholder="" formControlName="n_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>15.   Delayed Delivery % </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].o_1920"
                                placeholder="" formControlName="o_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].o_2021"
                                placeholder="" formControlName="o_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].o_2122"
                                placeholder="" formControlName="o_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>16.   Returned Products</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].p_1920"
                                placeholder="" formControlName="p_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].p_2021"
                                placeholder="" formControlName="p_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].p_2122"
                                placeholder="" formControlName="p_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>17.   Recalls</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].q_1920"
                                placeholder="" formControlName="q_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].q_2021"
                                placeholder="" formControlName="q_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].q_2122"
                                placeholder="" formControlName="q_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>18.   Process Parameter Non Conformances</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].r_1920"
                                placeholder="" formControlName="r_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].r_2021"
                                placeholder="" formControlName="r_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].r_2122"
                                placeholder="" formControlName="r_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>19.   RM Test Results</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].s_1920"
                                placeholder="" formControlName="s_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].s_2021"
                                placeholder="" formControlName="s_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].s_2122"
                                placeholder="" formControlName="s_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>20.   RM Rejects</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].t_1920"
                                placeholder="" formControlName="t_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].t_2021"
                                placeholder="" formControlName="t_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].t_2122"
                                placeholder="" formControlName="t_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>21.   Air Pathogen Monitoring Results</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].u_1920"
                                placeholder="" formControlName="u_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].u_2021"
                                placeholder="" formControlName="u_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].u_2122"
                                placeholder="" formControlName="u_2122">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>22.   Any Others</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].v_1920"
                                placeholder="" formControlName="v_1920">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].v_2021"
                                placeholder="" formControlName="v_2021">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.trendResults?.[0] === undefined?'':applicantSdata.trendResults?.[0].v_2122"
                                placeholder="" formControlName="v_2122">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[4]" [@formfade]="stepArray[4] ? 'active' : 'inactive' " class="step5">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b> Sector Specific Practices (GMP/GWP/GHP/GAP/others as applicable) </b>
                  <p class="labelinfo"> </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="sectorPractices">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th>Define Process
                              <hr> Step/ Ingredient
                            </th>
                            <th>Technology / Equipment / Software</th>
                            <th>Material of Construction / Program, as Applicable</th>
                            <th>Mistake Proofing for Food Safety, Quality</th>
                            <th>Instrumentation/ Control/ Any Others</th>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].a_1"
                                placeholder="" formControlName="a_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].a_2"
                                placeholder="" formControlName="a_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].a_3"
                                placeholder="" formControlName="a_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].a_4"
                                placeholder="" formControlName="a_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].b_1"
                                placeholder="" formControlName="b_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].b_2"
                                placeholder="" formControlName="b_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].b_3"
                                placeholder="" formControlName="b_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].b_4"
                                placeholder="" formControlName="b_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].c_1"
                                placeholder="" formControlName="c_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].c_2"
                                placeholder="" formControlName="c_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].c_3"
                                placeholder="" formControlName="c_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].c_4"
                                placeholder="" formControlName="c_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].d_1"
                                placeholder="" formControlName="d_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].d_2"
                                placeholder="" formControlName="d_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].d_3"
                                placeholder="" formControlName="d_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].d_4"
                                placeholder="" formControlName="d_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].e_1"
                                placeholder="" formControlName="e_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].e_2"
                                placeholder="" formControlName="e_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].e_3"
                                placeholder="" formControlName="e_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].e_4"
                                placeholder="" formControlName="e_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].f_1"
                                placeholder="" formControlName="f_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].f_2"
                                placeholder="" formControlName="f_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].f_3"
                                placeholder="" formControlName="f_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].f_4"
                                placeholder="" formControlName="f_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].g_1"
                                placeholder="" formControlName="g_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].g_2"
                                placeholder="" formControlName="g_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].g_3"
                                placeholder="" formControlName="g_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].g_4"
                                placeholder="" formControlName="g_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].h_1"
                                placeholder="" formControlName="h_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].h_2"
                                placeholder="" formControlName="h_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].h_3"
                                placeholder="" formControlName="h_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].h_4"
                                placeholder="" formControlName="h_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].i_1"
                                placeholder="" formControlName="i_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].i_2"
                                placeholder="" formControlName="i_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].i_3"
                                placeholder="" formControlName="i_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].i_4"
                                placeholder="" formControlName="i_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].j_1"
                                placeholder="" formControlName="j_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].j_2"
                                placeholder="" formControlName="j_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].j_3"
                                placeholder="" formControlName="j_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].j_4"
                                placeholder="" formControlName="j_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }"
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].k_1"
                                placeholder="" formControlName="k_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }"
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].k_2"
                                placeholder="" formControlName="k_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }"
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].k_3"
                                placeholder="" formControlName="k_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }"
                                [(ngModel)]="applicantSdata.sectorPractices?.[0] === undefined?'':applicantSdata.sectorPractices?.[0].k_4"
                                placeholder="" formControlName="k_4">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[5]" [@formfade]="stepArray[5] ? 'active' : 'inactive' " class="step6">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b>Other Sector Specific Processes:</b>
                  <p class="labelinfo"></p>
                </div>
                <div class="panel-body">
                  <div formGroupName="otherSpecific">
                    <div class="col-md-12 form-group">
                      <label>1.       Primary Production Chemical Residues control </label>
                      <p class="labelInfo"> </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.otherSpecific?.[0] === undefined?'':applicantSdata.otherSpecific?.[0].primaryChemical"
                        placeholder="" formControlName="primaryChemical">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['otherSpecific','primaryChemical'])?.touched && this.appForm.get(['otherSpecific','primaryChemical'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-12 form-group">
                      <label>2.       Water Treatment Technology (if applicable for the sector)</label>
                      <p class="labelInfo"> </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.otherSpecific?.[0] === undefined?'':applicantSdata.otherSpecific?.[0].waterTreatment"
                        placeholder="" formControlName="waterTreatment">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['otherSpecific','waterTreatment'])?.touched && this.appForm.get(['otherSpecific','waterTreatment'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-12 form-group">
                      <label>3.       Sensitive Ingredient release and Raw Material Quality Assurance</label>
                      <p class="labelInfo"> </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.otherSpecific?.[0] === undefined?'':applicantSdata.otherSpecific?.[0].ingredients"
                        placeholder="" formControlName="ingredients">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['otherSpecific','ingredients'])?.touched && this.appForm.get(['otherSpecific','ingredients'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-12 form-group">
                      <label>4.       Check practices on sector specific regulations (e.g. Direction Compliance FSS
                        contaminants Antibiotics for Vet. Drugs / Regulations Health Supplements & others as
                        applicable.)</label>
                      <p class="labelInfo"> </p>
                      <ckeditor [config]="{ readOnly: 'true' }" required
                        [(ngModel)]="applicantSdata.otherSpecific?.[0] === undefined?'':applicantSdata.otherSpecific?.[0].regultions"
                        placeholder="" formControlName="regultions">
                      </ckeditor>
                      <div
                        *ngIf="this.appForm.get(['otherSpecific','regultions'])?.touched && this.appForm.get(['otherSpecific','regultions'])?.invalid"
                        class="alert alert-danger">This field is required</div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[6]" [@formfade]="stepArray[6] ? 'active' : 'inactive' " class="step7">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b> Key Improvements related to Food Safety & Change Management Initiatives Project (Last 3-5 years)-
                    Top 10 only</b>
                  <p class="labelinfo"> </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="foodImprovements">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th>S.No.</th>
                            <th>Improvement Activity (Theme /Title)</th>
                            <th>Project Dates</th>
                            <th>Team Members involved</th>
                            <th>Benefits Obtained (Provide result trend data wherever possible)</th>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].a_1"
                                placeholder="" formControlName="a_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].a_2"
                                placeholder="" formControlName="a_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].a_3"
                                placeholder="" formControlName="a_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].a_4"
                                placeholder="" formControlName="a_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].b_1"
                                placeholder="" formControlName="b_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].b_2"
                                placeholder="" formControlName="b_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].b_3"
                                placeholder="" formControlName="b_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].b_4"
                                placeholder="" formControlName="b_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].c_1"
                                placeholder="" formControlName="c_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].c_2"
                                placeholder="" formControlName="c_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].c_3"
                                placeholder="" formControlName="c_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].c_4"
                                placeholder="" formControlName="c_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].d_1"
                                placeholder="" formControlName="d_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].d_2"
                                placeholder="" formControlName="d_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].d_3"
                                placeholder="" formControlName="d_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].d_4"
                                placeholder="" formControlName="d_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].e_1"
                                placeholder="" formControlName="e_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].e_2"
                                placeholder="" formControlName="e_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].e_3"
                                placeholder="" formControlName="e_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].e_4"
                                placeholder="" formControlName="e_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].f_1"
                                placeholder="" formControlName="f_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].f_2"
                                placeholder="" formControlName="f_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].f_3"
                                placeholder="" formControlName="f_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].f_4"
                                placeholder="" formControlName="f_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].g_1"
                                placeholder="" formControlName="g_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].g_2"
                                placeholder="" formControlName="g_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].g_3"
                                placeholder="" formControlName="g_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].g_4"
                                placeholder="" formControlName="g_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].h_1"
                                placeholder="" formControlName="h_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].h_2"
                                placeholder="" formControlName="h_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].h_3"
                                placeholder="" formControlName="h_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].h_4"
                                placeholder="" formControlName="h_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].i_1"
                                placeholder="" formControlName="i_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].i_2"
                                placeholder="" formControlName="i_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].i_3"
                                placeholder="" formControlName="i_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].i_4"
                                placeholder="" formControlName="i_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].j_1"
                                placeholder="" formControlName="j_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].j_2"
                                placeholder="" formControlName="j_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].j_3"
                                placeholder="" formControlName="j_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].j_4"
                                placeholder="" formControlName="j_4">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].k_1"
                                placeholder="" formControlName="k_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].k_2"
                                placeholder="" formControlName="k_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].k_3"
                                placeholder="" formControlName="k_3">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.foodImprovements?.[0] === undefined?'':applicantSdata.foodImprovements?.[0].k_4"
                                placeholder="" formControlName="k_4">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[7]" [@formfade]="stepArray[7] ? 'active' : 'inactive' " class="step8">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b> Checkpoints for Assessors on Applicants Regulatory Excellence (As applicable)
                  </b>
                  <p class="labelinfo"> </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="checkpoints">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th>Field</th>
                            <th style="width:70%">Provide details if applicable</th>
                          </tr>
                          <tr>
                            <td>Formulations (Check against standards available)</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].a_1"
                                placeholder="" formControlName="a_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Vendors Traceability, backward traceability and recall plans</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].b_1"
                                placeholder="" formControlName="b_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Labelling vs Nutrients testing to verify nutritional claims</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].c_1"
                                placeholder="" formControlName="c_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Frequency of testing your Finished Goods </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].d_1"
                                placeholder="" formControlName="d_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Have you carried out a Risk Assessment for Lead and other Heavy metal and other key food
                              safety elements and identified the source.</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].e_1"
                                placeholder="" formControlName="e_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>What are the limits of Lead and Heavy Metals and other hazards as per FSSR norms?</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].f_1"
                                placeholder="" formControlName="f_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Test Reports of NABL accredited Labs available?</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].g_1"
                                placeholder="" formControlName="g_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>What is the quality of Water being used? Does it comply with the BIS norms of potable
                              water IS 10500 Revision 2, 2012 more specifically on the Lead and Heavy metal content?
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].h_1"
                                placeholder="" formControlName="h_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Have you documented  the  findings and have initiated correction, corrective action and
                              preventive action such as Product recall,  Product hold, revisiting the formulations, risk
                              analysis from the current perspective and the labelling.</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].i_1"
                                placeholder="" formControlName="i_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Does your organization have any external communication where in claims which are
                              non-substantiated are being aired. Claim Substantiation Process?</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].j_1"
                                placeholder="" formControlName="j_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Have you got a system to reach your valuable customers and end consumers in case of
                              emergencies , such as Electronic media communication such as Whatsapp, Twitter, SMS
                              messaging, email etc., where in your organization is able to communicate the
                              organizational stand  on the observations and the truthfulness of your product and the
                              transparency of your organization in sharing consumer information.</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].k_1"
                                placeholder="" formControlName="k_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Do you carry out regular testing of your RM/PM and FG for Food safety elements as
                              stipulated in the standards for your sector?  If so what is the methodology you follow and
                              is the method validated, verified and is in sync with the procedures followed Nationally
                              and Internationally.</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].l_1"
                                placeholder="" formControlName="l_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Are these Test Reports complying with standards?
                              If so  does your lab have any PT (Proficiency Test) for which PT scores are Z scores for
                              elements such as Microbiological pathogens, Heavy Metal Testing , Pesticide Residues,
                              Mycotoxins etc.,  If so to which countries and  how many companies  have taken part in
                              the  PT test.
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].m_1"
                                placeholder="" formControlName="m_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Organisational Response to the current Regulatory scenario
                              <ul>
                                <li> Advertisements</li>
                                <li> Compliance to ASCI code</li>
                                <li> Checks and balances in system (Quality and legal team) to ensure that
                                  advertisements are not misleading </li>
                              </ul>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].n_1"
                                placeholder="" formControlName="n_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Dedicated / Part Resource / team accountable for Regulatory Compliance</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].o_1"
                                placeholder="" formControlName="o_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>In-house Lab infrastructure classified in 3 categories
                              <ul>
                                <li> No Lab</li>
                                <li> Lab. But not accredited by NABL</li>
                                <li> Lab accredited by NABL</li>
                              </ul>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].p_1"
                                placeholder="" formControlName="p_1">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>Documented Regulatory Compliance Procedure (Regulatory Compliance SOP)
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.checkpoints?.[0] === undefined?'':applicantSdata.checkpoints?.[0].q_1"
                                placeholder="" formControlName="q_1">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
        <div [hidden]="!stepArray[8]" [@formfade]="stepArray[8] ? 'active' : 'inactive' " class="step9">
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-sm-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <b> Environmental Impact </b>
                  <p class="labelinfo">Define Indices used if any </p>
                </div>
                <div class="panel-body">
                  <div formGroupName="envImpact">
                    <div class="col-md-12 form-group">
                      <table class="table table-bordered table-condensed">
                        <tbody>
                          <tr>
                            <th>S.No.</th>
                            <th>Parameter</th>
                            <th>2021-2022</th>
                            <th>2022-2023</th>
                            <th>2023-2024 till date</th>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>Carbon Dioxide Emission</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].a_1"
                                placeholder="" formControlName="a_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].a_2"
                                placeholder="" formControlName="a_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].a_3"
                                placeholder="" formControlName="a_3">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Power Consumption</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].b_1"
                                placeholder="" formControlName="b_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].b_2"
                                placeholder="" formControlName="b_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].b_3"
                                placeholder="" formControlName="b_3">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Water Consumption </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].c_1"
                                placeholder="" formControlName="c_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].c_2"
                                placeholder="" formControlName="c_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].c_3"
                                placeholder="" formControlName="c_3">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Energy Consumption</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].d_1"
                                placeholder="" formControlName="d_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].d_2"
                                placeholder="" formControlName="d_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].d_3"
                                placeholder="" formControlName="d_3">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Use & Disposal of Plastic (scrap)</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].e_1"
                                placeholder="" formControlName="e_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].e_2"
                                placeholder="" formControlName="e_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].e_3"
                                placeholder="" formControlName="e_3">
                              </ckeditor>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Use of Non-Biodegradable Packaging Material</td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].f_1"
                                placeholder="" formControlName="f_1">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required
                                [(ngModel)]="applicantSdata.envImpact?.[0] === undefined?'':applicantSdata.envImpact?.[0].f_2"
                                placeholder="" formControlName="f_2">
                              </ckeditor>
                            </td>
                            <td>
                              <ckeditor [config]="{ readOnly: 'true' }" required (ready)="onNamespaceLoaded()"
                                placeholder="" formControlName="f_3">
                              </ckeditor>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!this.applicantSdata.userStatus">
            <div style="margin:10px 0px;">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>