import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApplicantService } from 'src/app/services/applicant.service';
import { CoApplicantService } from 'src/app/services/co-applicant.service';
import { LoaderService } from 'src/app/services/loader.service';
import { QuestionService } from 'src/app/services/question.service';
import { TosterService } from 'src/app/services/toster.service';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css'],
})
export class SectionComponent implements OnInit {
  section_no: any = '';
  udata: any;
  questions: any;
  subsection: any;
  criteria: any;
  criterias: any;
  public model: any = {};
  modall: any = [];
  isView: boolean = false;
  Sectionform!: FormGroup;
  flag: any;
  finalStatus: boolean = true;
  queryCriteria: string = '';
  constructor(
    private toast: TosterService,
    private _Activatedroute: ActivatedRoute,
    private quest: QuestionService,
    private applicat: ApplicantService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private coapp: CoApplicantService
  ) { }
  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe((params: any) => {
      this.section_no = params.get('section_no');
      this.flag = params.get('flag');
      if (this.section_no === '' || this.section_no === null) {
        window.location.href = '/dashboard';
        return;
      }
      this.loadData();
    });
    this._Activatedroute.queryParamMap.subscribe((query: any) => {
      this.queryCriteria = query.params.criteria
    })
  }
  private loadData(): void {
    this.modall = [];
    this.udata = localStorage.getItem('userdata') || '{}';
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.loaderService.showLoading();
    this.applicat
      .GetAdminApplicantSinglebyemail(email)
      .subscribe((data: any) => {
        if (data.applicanData.criteria != '') {
          this.criteria = data.applicanData.criteria;
          this.criterias = [data.applicanData.criteria];
          this.coapp.listCoApplicant(data.applicanData._id).subscribe((resp: any) => {
            if (resp.result.length) {
              // this.navToCoDirectly = true
              // this.coid = resp.result[0]._id
              let criterias = resp.result.map((coapps: any) => {
                return coapps.co_criteria
              })
              this.criterias = [data.applicanData.criteria].concat(criterias)
              this.criterias = Array.from(new Set(this.criterias))
            } else {
              this.criterias = [data.applicanData.criteria]
            }
            this.quest
              .viewQuestionByCriterias({
                criterias: this.criterias,
                section_no: this.section_no,
              })
              .subscribe((item: any) => {
                let a: any[] = item.sec.filter((item: any) => {
                  return item.question_title !== 'Findings / Remarks'
                });
                let b: any[] = item.sec.filter((item: any) => {
                  return item.question_title == 'Findings / Remarks'
                });
                this.questions = [...a, ...b];
                this.subsection = this.removeDuplicateObjectFromArray(
                  item.sec,
                  'sub_section_no'
                );
                this.model = []
                item.sec.map((element: any) => {
                  this.model[element._id] = '';
                });
                this.quest.viewFinallyWithCriterias({
                  email: this.udata.email,
                  criterias: this.criterias,
                  type: this.udata.role,
                })
                  .subscribe(async (elem: any) => {
                    this.finalStatus = (elem.ass.length == 0 ? true : false)
                  })
                this.quest
                  .viewAssessment({
                    section_no: this.section_no,
                    email: this.udata.email,
                    criteria: this.queryCriteria,
                    type: this.udata.role,
                  })
                  .subscribe(async (ele: any) => {
                    if (ele.ass.length) {
                      let lastIndex = ele.ass.length - 1;
                      this.modall = await ele.ass[lastIndex].assessment[0];
                      let finalModal: any = {};
                      this.questions.forEach((element: any) => {
                        if (this.modall && this.modall[element._id]) {
                          finalModal[element._id] = this.modall[element._id];
                        } else {
                          finalModal[element._id] = '';
                        }
                      });
                      this.Sectionform = this.fb.group(finalModal);
                    } else {
                      this.formInit(this.model);
                    }
                    if (this.flag != 0) {
                      this.onSubmit();
                    }
                    this.loaderService.hideLoading();
                  });
              });
          })
        }        
      });
  }
  formInit(model: any) {
    this.Sectionform = this.fb.group(model);
  }
  public get f() {
    return this.Sectionform.controls;
  }
  removeDuplicateObjectFromArray(array: any, key: any) {
    return array.filter(
      (obj: any, index: any, self: any) =>
        index === self.findIndex((el: any) => el[key] === obj[key])
    );
  }
  onChangeControl(id: any, e: any, chk: any) {
    //undefined
    this.Sectionform.controls[id]?.setValue(chk == null ? e.target.value : e);
    // if (this.flag != 0) {
    // }
    // else{
    //   this.Sectionform.value[id] = chk == null ? e.target.value : e;
    // }
  }
  checkOFIs(item_inner: any) {
    if (this.udata.role == 'applicant' || this.udata.role == 'Applicant') {
      const { question_title, question_description } = item_inner;
      return !(question_title && question_title.includes('OFIs')) && !(question_description && question_description.includes('OFIs'));
    }
    else
      return true;
  }
  onSubmit() {
    if (this.flag == 0) {
      for (var key in this.modall) {
        this.Sectionform.controls[key]?.clearValidators();
        this.Sectionform.controls[key]?.updateValueAndValidity();
      }
      this.quest
        .saveAssessment({
          assessment: this.Sectionform.value,
          section_no: this.section_no,
          email: this.udata.email,
          criteria: this.queryCriteria,
          type: this.udata.role,
          applicant_id: '',
          status: false
        })
        .subscribe((item: any) => {
          this.toast.showInfo('Section Saved.');
          this.quest
            .viewQuestionSec({ criteria: this.queryCriteria })
            .subscribe((item: any) => {
              let ind = item.sec.indexOf(this.section_no);
              if (item.sec.length == ind + 1) {
                window.location.href = '/preview';
              } else {
                window.location.href = '/section/' + item.sec[ind + 1] + '/0';
              }
            });
        });
    } else {
      for (var key in this.modall) {
        this.Sectionform?.controls[key]?.setValidators([Validators.required, noWhitespaceValidator]);
        this.Sectionform?.controls[key]?.updateValueAndValidity();
      }
      if (this.Sectionform.invalid) {
      }
      else {
        this.quest
          .saveAssessment({
            assessment: this.Sectionform.value,
            section_no: this.section_no,
            email: this.udata.email,
            criteria: this.queryCriteria,
            type: this.udata.role,
            applicant_id: '',
            status: true
          })
          .subscribe((item: any) => {
            this.toast.showInfo('Section Saved.');
            this.quest
              .viewQuestionSec({ criteria: this.criteria })
              .subscribe((item: any) => {
                let ind = item.sec.indexOf(this.section_no);
                if (item.sec.length == ind + 1) {
                  window.location.href = '/review';
                } else {
                  window.location.href = '/section/' + item.sec[ind + 1] + '/0';
                }
              });
          });
      }
    }
  }
  saveCsv() {
    let csvData: any = [];
    this.questions.forEach((question: any) => {
      const key = `${question.question_title} - ${question.question_description}`;
      const value = this.modall[question._id];
      csvData.push([key, value]);
    });
    const csvRows = [];
    csvRows.push(['--Questions--', '--Answers--'].join(','));
    csvData.forEach((row: any) => {
      csvRows.push(row.map((field: any) => `"${field?.toString().replace(/"/g, '""')}"`).join(','));
    });
    const csvString = csvRows.join('\r\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'section-' + this.section_no + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  // saveCsv() {
  //   let csvData: any = {};
  //   this.questions.forEach((question: any) => {
  //     csvData[question.question_title + " - " + question.question_description] = this.modall[question._id];
  //   });
  //   let data = [csvData];
  //   const header = Object.keys(data[0]);
  //   const csv = data.map(row => header.map(fieldName => `"${row[fieldName]?.replace(/"/g, '""')}"` // Quote and handle double quotes in the content
  //   ).join(','));
  //   csv.unshift(header.join(','));
  //   const csvArray = csv.join('\r\n');
  //   const a = document.createElement('a');
  //   const blob = new Blob([csvArray], {
  //     type: 'text/csv'
  //   });
  //   const url = window.URL.createObjectURL(blob);
  //   a.href = url;
  //   a.download = 'section-' + this.section_no + '.csv';
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  //   a.remove();
  // }
}
