import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AllocationService } from 'src/app/services/allocation.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import { LocationService } from 'src/app/services/location.service';
import { TosterService } from 'src/app/services/toster.service';
@Component({
  selector: 'app-sector-grand-total',
  templateUrl: './sector-grand-total.component.html',
  styleUrls: ['./sector-grand-total.component.css']
})
export class SectorGrandTotalComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  assessors: any;
  assessorsForm: any;
  isValidFormSubmitted: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  totalApplicant: any = 0;
  totalLOI: any = 0;
  totalInstruction: any = 0;
  totalChecklist: any = 0;
  statedata: any;
  sector: any;
  query: any;
  reportData: any
  constructor(
    public allocation: AllocationService,
    private fb: FormBuilder,
    private applicantS: ApplicantService,
    private toast: TosterService,
    private locationS: LocationService,
    private _Activatedroute: ActivatedRoute
  ) {
  }
  term: any;
  cc: any = '';
  ngOnInit(): void {
    this.query = this._Activatedroute.snapshot.paramMap.get('id');
    $.fn['dataTable'].ext.search.push(
      (settings: any, data: any, dataIndex: any) => {
        const criteria = parseFloat(data[8]) || ''; // use data for the id column
        if (isNaN(this.cc) || this.cc == criteria) {
          return true;
        }
        return false;
      }
    );
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lBfrtip',
    };
    this.allocation.getReportByGrandTotal().subscribe((item: any) => {
      item.map((applicantdata: any) => {
        applicantdata.grandTotal = 0;
        applicantdata.sctionScore = 0;
        applicantdata.generatedscoresData = this.getUniqueListBy(applicantdata?.generatedscoresData);
        applicantdata?.generatedscoresData.map((scoreData: any) => {
          scoreData.finalscore.map((it_num: any) => {
            applicantdata.grandTotal += parseFloat(it_num.highestscore);
            applicantdata.sctionScore += parseFloat(it_num.score);
            // this.totalCS += parseFloat(it_num.consensusscore);
          });
        })
      })
      this.reportData = item
      this.dtTrigger.next()
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn.dataTable.ext.search.pop();
  }
  getUniqueListBy(arr: any) {
    return [
      ...new Map(arr.map((item: any) => [item.section_no, item])).values(),
    ];
  }
  getData(e: any, field: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (field == 'criteria') {
        dtInstance
          .column(8)
          .search('^' + e.target.value + '$', true, false, true)
          .draw();
      } else if (field == 'state') {
        dtInstance
          .column(13)
          .search('^' + e.target.value + '$', true, false, true)
          .draw();
      } else {
        dtInstance
          .column(15)
          .search('^' + e.target.value + '$', true, false, true)
          .draw();
      }
    });
  }
  compareDates(dt: any): boolean | undefined {
    let currentDate = new Date();
    let applicantDate = new Date(dt);
    let threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
    if (applicantDate.getTime() >= threeYearsAgo.getTime() && applicantDate.getTime() <= currentDate.getTime()) {
      return true;
    }
    return false;
  }
}
