import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicantService } from 'src/app/services/applicant.service';
import { QuestionService } from 'src/app/services/question.service';
import { ToastrService } from 'ngx-toastr';
interface Result {
  [key: string]: {
    [key: string]: number;
  };
}
@Component({
  selector: 'app-add-highest-score',
  templateUrl: './add-highest-score.component.html',
  styleUrls: ['./add-highest-score.component.css'],
})
export class AddHighestScoreComponent implements OnInit {
  section: any;
  criteria: any;
  sec: any;
  subSections: any;
  highestScrmodel: any = {};
  udata: any = {};
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.udata = JSON.parse(localStorage.getItem('userdata') || '{}')
    if (this.udata.role !== 'super admin') {
      this.toastr.error('Not authorized!')
      this.router.navigate(['login'])
      localStorage.removeItem('user')
      localStorage.removeItem('userdata')
      localStorage.removeItem('username')
    }
  }
  ngOnInit(): void { }
  getSections(cri: any) {
    this.spinner.show()
    this.criteria = cri.target.value;
    this.section = [];
    this.sec = '';
    this.quest
      .sectionGroupbyCriteria({
        criteria: this.criteria,
      })
      .subscribe((elem: any) => {
        this.section = elem;
        this.spinner.hide();
      });
  }
  getSubSections(sec: any) {
    this.spinner.show();
    this.highestScrmodel = {};
    this.sec = sec.target.value;
    this.quest
      .findSubSection({
        criteria: this.criteria,
        section_no: this.sec,
      })
      .subscribe((itemQ: any) => {
        this.subSections = itemQ.subsections;
        this.subSections.forEach((sect: any) => {
          this.highestScrmodel[sect.sub_section_no] = sect.score
        })
        this.spinner.hide()
      });
  }
  getModelScore(e: any, subSectionNo: any) {
    this.highestScrmodel[subSectionNo] = e.target.value;
  }
  saveHighestScore() {
    this.spinner.show()
    let values = Object.values(this.highestScrmodel)
    const result: any = {};
    for (let [index, val] of values.entries()) {
      let newIndex = index < 9 ? "0" + (index + 1) : (index + 1);
      result[newIndex] = val
    }
    let keyname = this.sec.split('').join('')
    let score = [{ [keyname]: result }]
    this.quest.saveHighScore({
      criteria: this.criteria,
      section_no: this.sec,
      score: score
    }).subscribe((resp: any) => {
      this.spinner.hide()
      this.toastr.success(resp.message)
    });
  }
  getFormattedIndex(i: number): string {
    return i < 10 ? '0' + i : '' + i;
  }
}
