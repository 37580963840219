<div class="flexside">
    <div class="mainbodyhead" id="main">
        <div role="main">
            <router-outlet></router-outlet>
            <div *ngIf="(status$ | async)">
                <div class="overlay">
                    <div class="wrapper">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="shadow"></div>
                        <div class="shadow"></div>
                        <div class="shadow"></div>
                        <span>Loading</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>