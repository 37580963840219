<!-- <div class="headerActionBtn">
    <a routerLink="/team-allocation/{{ allocation_id }}" class="btn btn-info btn-xs" style="margin: 6px;"
        [class.btn-success]="router.url.includes('/team-allocation/')">Manage
        Assessment</a>
    <a routerLink="/score-report/{{ applicant_id }}/{{ allocation_id }}" class="btn btn-info btn-xs"
        [class.btn-success]="router.url.includes('/score-report/')" style="margin: 6px;">Consensus Score Sheet</a>
    <a routerLink="/final-score-report/{{ applicant_id }}/{{ allocation_id }}" class="btn btn-info btn-xs"
        [class.btn-success]="router.url.includes('/final-score-report/')" style="margin: 6px;">Score Summary Sheet</a>
    <a routerLink="/checklist-summary/{{ applicant_id }}/{{ allocation_id }}" class="btn btn-info btn-xs"
        [class.btn-success]="router.url.includes('checklist-summary')" style="margin: 6px;">Checklist Summary</a>
    <a routerLink="/grand-list/{{ allocation_id }}/{{ applicant_id }}" class="btn btn-info btn-xs"
        [class.btn-success]="router.url.includes('/grand-list/')" style="margin: 6px;">Consolidated List</a>
    <a routerLink="/assess-checklist/{{applicant_id}}/{{ allocation_id }}" class="btn btn-info btn-xs" style="margin: 6px;"
        [class.btn-success]="router.url.includes('/assess-checklist/')">
        Assess Applicant</a>
</div> -->
<div class="dropdown pull-right">
    <button style="border: 1px solid #00000059;border-radius: 8px;" class="btn btn-default dropdown-toggle btn-cls"
        type="button" id="menu1" data-toggle="dropdown">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
                d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
        </svg>
    </button>
    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
        <li role="presentation" [class.active]="router.url.includes('/team-allocation/')">
            <a role="menuitem" tabindex="-1" routerLink="/team-allocation/{{ allocation_id }}">
                Manage Assessment
            </a>
        </li>
        <li role="presentation" [class.active]="router.url.includes('/score-report/')">
            <a role="menuitem" tabindex="-1" routerLink="/score-report/{{ applicant_id }}/{{ allocation_id }}">
                Consensus Score Sheet
            </a>
        </li>
        <li role=" presentation" [class.active]="router.url.includes('/final-score-report/')">
            <a role="menuitem" tabindex="-1" routerLink="/final-score-report/{{ applicant_id }}/{{ allocation_id }}">
                Score Summary Sheet
            </a>
        </li>
        <li role="presentation" [class.active]="router.url.includes('checklist-summary')">
            <a role="menuitem" tabindex="-1" routerLink="/checklist-summary/{{ applicant_id }}/{{ allocation_id }}">
                Checklist Summary
            </a>
        </li>
        <li role=" presentation" [class.active]="router.url.includes('/grand-list/')">
            <a role="menuitem" tabindex="-1" routerLink="/grand-list/{{ allocation_id }}/{{ applicant_id }}">
                Consolidated List
            </a>
        </li>
        <li role=" presentation" *ngIf="!udata.role.includes('admin')"
            [class.active]="router.url.includes('/assess-checklist/')">
            <a role="menuitem" tabindex="-1" routerLink="/assess-checklist/{{applicant_id}}/{{ allocation_id }}">
                Assess Applicant
            </a>
        </li>
        <li role=" presentation" *ngIf="udata.role.includes('admin')"
            [class.active]="router.url.includes('/executive-summary/')">
            <a role="menuitem" tabindex="-1" routerLink="/executive-summary/{{ allocation_id }}">
                Executive Summary
            </a>
        </li>
        <li role=" presentation"
            [class.active]="router.url.includes('/view-introduction/')">
            <a role="menuitem" tabindex="-1" routerLink="/view-introduction/{{ applicant_id }}">
                Introduction Form
            </a>
        <li role=" presentation"
            [class.active]="router.url.includes('/view-document/')">
            <a role="menuitem" tabindex="-1" routerLink="/view-document/{{ applicant_id }}">
                View Documents
            </a>
        </li>
    </ul>
</div>
