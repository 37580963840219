import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllocationService } from 'src/app/services/allocation.service';
import { ApplicantService } from 'src/app/services/applicant.service';
import { QuestionService } from 'src/app/services/question.service';
import { TosterService } from 'src/app/services/toster.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import html2pdf from 'html2pdf.js';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-executive-summary',
  templateUrl: './executive-summary.component.html',
  styleUrls: ['./executive-summary.component.css'],
})
export class ExecutiveSummaryComponent implements OnInit {
  executiveForm!: FormGroup;
  allocationId: any;
  udata: any;
  record: any = [];
  arr: any = [];
  isValidFormSubmitted: boolean = false;
  isValidFormSubmitted1: boolean = false;
  isLeader: boolean = false;
  isCallibrator: boolean = false;
  exporting: boolean = false;
  summary: any;
  rec: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    // upload: (file: File) => { ... }
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };
  criteria: any = '';
  constructor(
    public allocation: AllocationService,
    private _Activatedroute: ActivatedRoute,
    private applicant: ApplicantService,
    private fb: FormBuilder,
    private toast: TosterService,
    public datepipe: DatePipe,
    private quest: QuestionService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    this.allocationId = this._Activatedroute.snapshot.paramMap.get('id');
    this.executiveForm = this.fb.group({
      scope_of_application: ['', [noWhitespaceValidator]],
      product: ['', [noWhitespaceValidator]],
      processes: ['', [noWhitespaceValidator]],
      unit_licensed: ['yes', [noWhitespaceValidator]],
      ofis: ['', [noWhitespaceValidator]],
      scr1: ['', [noWhitespaceValidator]],
      scr2: ['', [noWhitespaceValidator]],
      scr3: ['', [noWhitespaceValidator]],
      scr4: ['', [noWhitespaceValidator]],
      scr5: ['', [noWhitespaceValidator]],
      scr6: ['', [noWhitespaceValidator]],
      scr7: ['', [noWhitespaceValidator]],
      scr8: ['', [noWhitespaceValidator]],
      scr9: ['', [noWhitespaceValidator]],
      scr10: ['', [noWhitespaceValidator]],
      conclusion: ['', [noWhitespaceValidator]],
      recommendation: ['', [noWhitespaceValidator]],
      allocation_id: ['', [noWhitespaceValidator]],
      key_strength: ['', [noWhitespaceValidator]],
    });
    this.allocation
      .getExecutiveSummary(this.allocationId)
      .subscribe((execItem: any) => {
        if (execItem.length != 0) {
          this.isValidFormSubmitted = false;
          this.summary = execItem[execItem.length - 1];
          this.isValidFormSubmitted1 = this.summary.status;
          if (!this.isValidFormSubmitted1) {
            this.isValidFormSubmitted = true;
            this.executiveForm.patchValue(this.summary);
          }
          this.allocation
            .getAllAllocationByAssessorOne(this.allocationId)
            .subscribe((item: any) => {
              this.record = item.result;
              this.criteria = this.record[0]?.applicantData[0].criteria
              this.record.map((om: any) => {
                if (om.allocationliststatus != 'rejected') {
                  this.arr.push(om);
                }
                if (om.assessor_id == this.udata._id) {
                  if (om.teamleader)
                    this.isLeader = true;
                  if (om.callibrator)
                    this.isCallibrator = true;
                }
              });
              this.spinner.hide();
            });
        } else {
          this.isValidFormSubmitted = true;
          this.isValidFormSubmitted1 = false;
          this.allocation
            .getAllAllocationByAssessorOne(this.allocationId)
            .subscribe((item: any) => {
              this.record = item.result;
              this.criteria = this.record[0]?.applicantData[0].criteria
              this.record.map((om: any) => {
                if (om.allocationliststatus != 'rejected') {
                  this.arr.push(om);
                }
                if (om.assessor_id == this.udata.id) {
                  if (om.teamleader)
                    this.isLeader = true;
                  if (om.callibrator)
                    this.isCallibrator = true;
                }
              });
              this.spinner.hide();
            });
        }
      });
  }
  // edit() {
  //   this.form_init()
  //   this.isValidFormSubmitted = true;
  //   this.isValidFormSubmitted1 = false;
  //   this.executiveForm.get('scope_of_application')?.setValue(this.summary.scope_of_application)
  //   this.executiveForm.get('scope_of_application')?.updateValueAndValidity()
  //   this.executiveForm.get('product')?.setValue(this.summary.product)
  //   this.executiveForm.get('product')?.updateValueAndValidity()
  //   this.executiveForm.get('processes')?.setValue(this.summary.processes)
  //   this.executiveForm.get('processes')?.updateValueAndValidity()
  //   this.executiveForm.get('unit_licensed')?.setValue(this.summary.unit_licensed)
  //   this.executiveForm.get('unit_licensed')?.updateValueAndValidity()
  //   this.executiveForm.get('ofis')?.setValue(this.summary.ofis)
  //   this.executiveForm.get('ofis')?.updateValueAndValidity()
  //   this.executiveForm.get('scr1')?.setValue(this.summary.scr1)
  //   this.executiveForm.get('scr1')?.updateValueAndValidity()
  //   this.executiveForm.get('scr2')?.setValue(this.summary.scr2)
  //   this.executiveForm.get('scr2')?.updateValueAndValidity()
  //   this.executiveForm.get('scr3')?.setValue(this.summary.scr3)
  //   this.executiveForm.get('scr3')?.updateValueAndValidity()
  //   this.executiveForm.get('scr4')?.setValue(this.summary.scr4)
  //   this.executiveForm.get('scr4')?.updateValueAndValidity()
  //   this.executiveForm.get('scr5')?.setValue(this.summary.scr5)
  //   this.executiveForm.get('scr5')?.updateValueAndValidity()
  //   this.executiveForm.get('scr6')?.setValue(this.summary.scr6)
  //   this.executiveForm.get('scr6')?.updateValueAndValidity()
  //   this.executiveForm.get('scr7')?.setValue(this.summary.scr7)
  //   this.executiveForm.get('scr7')?.updateValueAndValidity()
  //   this.executiveForm.get('scr8')?.setValue(this.summary.scr8)
  //   this.executiveForm.get('scr8')?.updateValueAndValidity()
  //   this.executiveForm.get('scr9')?.setValue(this.summary.scr9)
  //   this.executiveForm.get('scr9')?.updateValueAndValidity()
  //   this.executiveForm.get('scr10')?.setValue(this.summary.scr10)
  //   this.executiveForm.get('scr10')?.updateValueAndValidity()
  //   this.executiveForm.get('conclusion')?.setValue(this.summary.conclusion)
  //   this.executiveForm.get('conclusion')?.updateValueAndValidity()
  //   this.executiveForm.get('recommendation')?.setValue(this.summary.recommendation)
  //   this.executiveForm.get('recommendation')?.updateValueAndValidity()
  //   this.executiveForm.get('allocation_id')?.setValue(this.summary.allocation_id)
  //   this.executiveForm.get('allocation_id')?.updateValueAndValidity()
  //   this.executiveForm.get('key_strength')?.setValue(this.summary.key_strength)
  //   this.executiveForm.get('key_strength')?.updateValueAndValidity()
  // }

  setradio(t: any) {
    this.rec = t.target.value;
  }
  askToSubmit() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to submit. Once submitted, you will not be able to make any changes.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit',
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.saveExecutiveForm('final')
      }
    })
  }
  saveExecutiveForm(type: string) {
    this.executiveForm.value.allocation_id = this.allocationId || this.executiveForm.value.allocation_id;
    this.executiveForm.value.recommendation = this.rec || this.executiveForm.value.recommendation;
    this.executiveForm.value['status'] = type == 'final' ? true : false;
    if (type == 'final') {
      this.isValidFormSubmitted = false;
      this.isValidFormSubmitted1 = true;
    }
    if (this.summary) {
      this.allocation
        .editExecutiveSummary({ id: this.summary._id, formData: this.executiveForm.value })
        .subscribe((item: any) => {
          this.toast.showInfo(item.message);
          // window.location.href = '/assessors-assess-information';
        });
    }
    else {
      this.allocation
        .saveExecutiveSummary(this.executiveForm.value)
        .subscribe((item: any) => {
          this.toast.showInfo(item.message);
          // window.location.href = '/assessors-assess-information';
        });
    }
  }
  editSubmit() {
    this.isValidFormSubmitted = false;
    this.executiveForm.value.allocation_id = this.allocationId;
    this.executiveForm.value.recommendation = this.rec;
  }
  exportToPdf() {
    this.exporting = true;
    const division = document.getElementById('print-section');
    if (division) {
      html2pdf().set({
        html2canvas: { scale: 4 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).from(division).save().then(() => {
        this.exporting = false;
        this.cdr.detectChanges();
      }).catch((err: any) => {
        console.error('Error exporting to PDF:', err);
        this.exporting = false;
      });
    }
  }
  printJS(g: any) {
    // this.printJS(g);
  }
}
