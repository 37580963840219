import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = new BehaviorSubject(false);
  constructor() { }
  //Loader functionality start
  showLoading() {
    this.isLoading.next(true);
  }
  hideLoading() {
    this.isLoading.next(false);
  }
  getLoadingState() {
    return this.isLoading.asObservable();
  }
  //Loader functionality end
}
