<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body">
    <div class="d-flex align-items-center mar-in-20">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{isAdmin ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
          Assessment
          Information</span>
        <h2 class="mt-0">
          <span *ngIf="current_sec && activeTab === 'applicant'">{{ headers[criteria[0]][current_sec]}}</span>
          <span *ngIf="current_sec && activeTab === 'coapplicant'">{{ headers[criteria[1]][current_sec] }}</span>
          <span *ngIf="!current_sec">Checklist Summary </span>
        </h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocated_id" [applicant_id]="this.applicant_id"></app-commonhead>
      </div>
    </div>
    <div class="clearfix"></div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteria.length > 1">
      <li class="nav-item" role="presentation" (click)="switchTab('applicant')"
        [class.active]="activeTab == 'applicant'">
        <a>Applicant</a>
      </li>
      <li class="nav-item" role="presentation" (click)="switchTab('coapplicant')"
        [class.active]="activeTab == 'coapplicant'">
        <a>Co-Applicant</a>
      </li>
    </ul>
    <div style="margin-bottom: 1.5rem;">
      <ul class="d-flex bb-0 list-style-none sub-checklist flex-wrap">
        <li role="presentation" [class.active]="current_sec == item._id.section_no" class="nav-tabs-item"
          *ngFor="let item of this.section" (click)="getQuestion(item._id.section_no)"><a
            style="font-size: 14px;">Sec-{{ item._id.section_no }}</a>
        </li>
      </ul>
    </div>
    <ng-container *ngIf="questions.length">
      <div class="mar-in-20">
        <table class="table table-bordered" *ngIf="!isEmpty(questModels)">
          <ng-container *ngFor="let itemd of questions; let i = index">
            <thead *ngIf="isFirstOccurrence(itemd._id.sub_section_no, i)" style="width:100%">
              <tr style="border-top: 1px solid #dddddd;">
                <th colspan="3" style="background-color: #F8FDEC; color: #424750; height: 50px;">
                  <span>
                    Section {{ itemd._id.sub_section_no }} {{ itemd._id.sub_section_title }}
                  </span>
                </th>
                <th style="background-color: #F8FDEC; color: #424750; height: 50px;">
                  Score: {{SubSecmodel?.[itemd._id.sub_section_no]}}
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Question Title</th>
                <th>Question Description</th>
                <th style="width:30%">
                  Assessor Response
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let itemd of itemd.ques">
                <tr>
                  <td>{{ itemd.ques_no }}</td>
                  <td>{{ itemd.ques_title }}</td>
                  <td>{{ itemd.ques_des }}</td>
                  <ng-container *ngFor="let item of assessor_data">
                    <td *ngIf="checkass(item, itemd)" class="test">
                      <div *ngIf="isAdmin || canChange">
                        <ng-container *ngIf="questModels[item.assessor_data[0].assessorsEmail]">

                          <textarea class="form-control resizable"
                            [(ngModel)]="questModels[item.assessor_data[0].assessorsEmail][itemd.id]"
                            *ngIf="itemd.type=='T'">{{getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail)}}</textarea>
                          <select class="form-control" *ngIf="itemd.type=='DD'"
                            [(ngModel)]="questModels[item.assessor_data[0].assessorsEmail][itemd.id]">
                            <option value=""
                              [selected]="getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) == 'N/A'">
                              Choose
                              any
                              option</option>
                            <option value="Yes"
                              [selected]="getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) == 'Yes'">
                              Yes
                            </option>
                            <option value="No"
                              [selected]="getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) == 'No'">
                              No
                            </option>
                            <option value="Not Applicable"
                              [selected]="getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) == 'Not Applicable'">
                              Not
                              Applicable
                            </option>
                            <option value="Partly"
                              [selected]="getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) == 'Partly'">
                              Partly</option>
                          </select>
                        </ng-container>
                      </div>
                      <div *ngIf="!isAdmin && !canChange">
                        {{ getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) }}
                      </div>
                      <!-- <div>
                        {{ getScoreBySubSection(itemd.id,item.assessor_data[0].assessorsEmail) }}
                      </div> -->
                    </td>
                  </ng-container>
                </tr>
                <tr *ngIf="itemd.ques_title=='Findings / Remarks' && calibrator_remarks">
                  <td colspan="3">Calibrator's remark</td>
                  <ng-container *ngIf="isCallibrator">
                    <ng-container>
                      <td>
                        <div>
                          <textarea class="form-control resizable" [(ngModel)]="calibrator_remarks[itemd.id]"
                            style="width: 100%; max-width: 100%;" rows="3" cols="8"></textarea>
                        </div>
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!isCallibrator">
                    <td>
                      {{calibrator_remarks[itemd.id]}}
                    </td>
                  </ng-container>
                </tr>
                <tr *ngIf="itemd.ques_title=='Findings / Remarks' && admin_remarks">
                  <td colspan="3">Reviewer's remark</td>
                  <ng-container *ngIf="isAdmin">
                    <td>
                      <div>
                        <textarea class="form-control resizable" [(ngModel)]="admin_remarks[itemd.id]"
                          style="width: 100%; max-width: 100%;" rows="3" cols="8"></textarea>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!isAdmin">
                    <td>
                      {{admin_remarks[itemd.id]}}
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </ng-container>
        </table>
        <button *ngIf="isAdmin || canChange" (click)="saveAssessment()" class="saveBtn floatBtn1">Update
          Assessment</button>
        <button *ngIf="isCallibrator && calibrator_remarks" (click)="onSubmit()" class="saveBtn floatBtn2">Save</button>
        <button *ngIf="isAdmin && admin_remarks" (click)="onAdminSubmit()" class="saveBtn floatBtn2">Save</button>
      </div>
    </ng-container>
  </div>
</div>
