import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { base_url } from '../global';

@Injectable({
  providedIn: 'root'
})
export class FinancialsService {
constructor(private http: HttpClient) {}
  addFinYear(data: any) {
    let api_url = base_url + 'add-financial-year';
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }
  getFinYears() {
    let api_url = base_url + 'get-financial-year';
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.get(api_url, httpOptions);
  }
  updateFinYear(data: any, id: string) {
    let api_url = base_url + 'update-financial-year' + '/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.put(api_url, data, httpOptions);
  }
  toggleFinYear(data: any, id: string) {
    let api_url = base_url + 'toggle-financial-year' + '/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.put(api_url, data, httpOptions);
  }
  makeDefFinYear(data: any, id: string) {
    let api_url = base_url + 'default-financial-year' + '/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.put(api_url, data, httpOptions);
  }
  getCookie(data: any, name: string) {
    let api_url = base_url + 'get-cookie' + '/' + name;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  setCookie(data: any, name: string) {
    let api_url = base_url + 'set-cookie' + '/' + name;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }
  updateWinFinYear(data: any, id: string) {
    let api_url = base_url + 'financial-year' + '/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.put(api_url, data, httpOptions);
  }
  getFinancialId(email: any) {
    let api_url = base_url + 'financial-year-id' + '/' + email;
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json;charset=UTF-8',
      }),
    };
    return this.http.get(api_url, httpOptions);
  }
}
