import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicantService } from 'src/app/services/applicant.service';
import { CoApplicantService } from 'src/app/services/co-applicant.service';
import { LoaderService } from 'src/app/services/loader.service';
import { QuestionService } from 'src/app/services/question.service';
import { TosterService } from 'src/app/services/toster.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
})
export class PreviewComponent implements OnInit {
  section_no: any = '';
  udata: any;
  questions: any;
  subsection: any;
  criteria: any;
  isLoaded: boolean = false;
  public model: any = {};
  modall: any = [];
  Sectionform!: FormGroup;
  secArr: { [key: string]: any[] } = { 'Applicant': [], 'Co-applicant': [] };
  btnDisabled: any = true;
  finalStatus: boolean = true;
  constructor(
    private toast: TosterService,
    private _Activatedroute: ActivatedRoute,
    private quest: QuestionService,
    private applicat: ApplicantService,
    private fb: FormBuilder,
    private coapp: CoApplicantService,
    private loaderService: LoaderService,
    private route: Router
  ) { }
  ngOnInit(): void {
    this.loaderService.showLoading();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.applicat
      .GetAdminApplicantSinglebyemail(email)
      .subscribe((data: any) => {
        if (data.applicanData.criteria != '') {
          this.coapp.listCoApplicant(data.applicanData._id).subscribe((resp: any) => {
            if (resp.result.length) {
              let criterias = resp.result.map((coapps: any) => {
                return coapps.co_criteria
              })
              this.criteria = [data.applicanData.criteria].concat(criterias)
              this.criteria = Array.from(new Set(this.criteria))
            } else {
              this.criteria = [data.applicanData.criteria]
            }
            this.quest
              .viewFinallyWithCriterias({
                email: this.udata.email,
                criterias: this.criteria,
                type: this.udata.role,
              })
              .subscribe(async (elem: any) => {
                this.finalStatus = elem.ass.length == 0 ? true : false;
                this.loaderService.hideLoading();
              });
            for (let [index, criteria] of this.criteria.entries()) {
              this.quest
                .viewQuestionSec({ criteria: criteria })
                .subscribe(async (item: any) => {
                  this.section_no = item.sections;
                  const sectionKeys = Object.keys(this.section_no);
                  sectionKeys.map(async (sec: any) => {
                    let no_of_field = 0;
                    let no_of_field_empty = 0;
                    this.quest.viewAssessment({
                      section_no: sec,
                      email: this.udata.email,
                      criteria: criteria,
                      type: this.udata.role,
                    }).subscribe((ele: any) => {
                      let arr = ele.ass[0]?.assessment[0];
                      let psh = 'Applicant';
                      if (index !== 0) {
                        psh = 'Co-applicant';
                      }
                      if (arr) {
                        for (var key in arr) {
                          if (!arr[key] || arr[key] == '') {
                            ++no_of_field_empty;
                          }
                        }
                        ++no_of_field;
                        let val = {
                          count: no_of_field,
                          emptycount: no_of_field_empty,
                          isreviewed: ele.ass[0]?.status,
                          section_no: sec,
                        };
                        if (no_of_field_empty || !ele.ass[0]?.status) {
                          this.btnDisabled = false
                        }
                        this.secArr[psh].push(val);
                      } else {
                        let val = {
                          count: undefined,
                          emptycount: undefined,
                          isreviewed: false,
                          section_no: sec,
                        };
                        this.secArr[psh].push(val)
                      }
                      this.secArr[psh].sort((a, b) => a.section_no.localeCompare(b.section_no));
                      this.loaderService.hideLoading();
                    });
                  });
                });
            }
            this.isLoaded = true;
          });
        } else {
          this.loaderService.hideLoading();
        }
      });
  }
  onsubmit() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to submit your application for review. Once submitted, you will not be able to make any changes.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit',
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.loaderService.showLoading();
        this.quest.saveFinally({
          email: this.udata.email,
          criteria: this.criteria[0],
          type: this.udata.role,
        }).subscribe((ittem: any) => {
          if (ittem.statuscode == '200') {
            this.finalStatus = false;
            this.toast.showInfo('Finally Saved.');
          }
          else {
            this.toast.showError('Already Submitted.');
          }
          this.loaderService.hideLoading();
        });
      }
    })
  }
  goToSection(sectionName: string, index: number) {
    let rte = 'section'
    if (index !== 0) {
      rte = 'sectionco';
    }
    this.route.navigate([rte, sectionName, 1], { queryParams: { criteria: this.criteria[index] } })
  }
}
