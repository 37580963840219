import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-zeon',
  templateUrl: './zeon.component.html',
  styleUrls: ['./zeon.component.css']
})
export class ZeonComponent implements OnInit {
  udata: any;
  constructor() { }
  ngOnInit(): void {
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
  }
}
