import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  udata: any = {};
  constructor(public router: Router) {
    this.udata = JSON.parse(localStorage.getItem('userdata') || '{}')
  }
  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('userdata')
    localStorage.removeItem('username')
  }
  ngOnInit(): void {
    
  }
}
