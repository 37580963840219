<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body mar-in-20">
    <div>
      <h2>Grand Tally Report</h2>
    </div>
    <ul class="nav nav-tabs bb-1" *ngIf="criteriaArray.length > 1">
      <li class="nav-item" role="presentation" *ngFor="let crt of criteriaArray" [class.active]="criteria == crt"
        (click)="criteriaChange(crt)">
        <a>{{crt}}</a>
      </li>
    </ul>
    <div class="clearfix"></div>
    <div>
      <ul class="d-flex bb-0 list-style-none sub-checklist">
        <li class="nav-tabs-item" role="presentation" [class]="item==this.section_no?'active':''"
          *ngFor="let item of this.section | slice:0:6" (click)="getscore(item)"><a style="font-size: 14px;">
            <span *ngIf="item == 'A' && (criteria == 'Criteria 9' || criteria == 'Criteria 4')">
              Sec-{{item}}
            </span>
            <span *ngIf="item != 'A'">
              Sec-{{item}}
            </span>
          </a>
        </li>
        <li class="nav-tabs-item" data-toggle="modal" data-target="#exampleModal" role="presentation"
          *ngIf="this.section.length>6"><a style="font-size: 14px;"><span>See More</span></a>
        </li>
      </ul>
    </div>
    <div class="lock-header">
      <table class="table">
        <thead>
          <tr>
            <th class="headcol" colspan="2" style="background-color: #87A63F; color: white; z-index: 3;">
              Sub Section</th>
            <th *ngFor="let apps of app_ids; let i=index" style="background: #EAEAEA;">
              {{ applicantData[criteria][apps]['applicantData'][0]['firstName'] + ", " +
              applicantData[criteria][apps]['applicantData'][0]['applicantCity'] }}
            </th>
          </tr>
          <tr>
            <th class="headcol" colspan="2" style="background-color: #87A63F; color: white; z-index: 3;">
              Rising Star</th>
            <th class="text-center" *ngFor="let apps of app_ids; let i=index" style="background: #EAEAEA;"
              [class.rising-star]="this.compareDates(applicantData[criteria][apps]['applicantData'][0]?.operationForm)">
              {{ this.compareDates(applicantData[criteria][apps]['applicantData'][0]?.operationForm) ? 'Yes' : 'No' }}
            </th>
          </tr>
          <tr>
            <th class="headcol" colspan="2" style="background-color: #87A63F; color: white; z-index: 3;">
              Sector</th>
            <th class="text-center" style="background: #EAEAEA;" *ngFor="let apps of app_ids; let i=index">
              {{ applicantData[criteria][apps]['applicantData'][0]?.sector }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemd of subSections;let i=index">
            <th class="headcol" style="background-color: #F8FDEC; text-align: center; vertical-align: middle; min-width: 70px;">
              {{ itemd?._id?.sub_section_no }}
            </th>
            <th class="headcol" style="background-color: #F8FDEC;">
              {{ itemd?._id?.sub_section_title }}
            </th>
            <ng-container *ngFor="let apps of app_ids;let i=index;">
              <td class="long text-center" [ngStyle]="getCellStyle(applicantScores[apps][itemd?._id?.sub_section_no])"
                *ngIf="applicantScores[apps]; else emptyCell">
                {{ applicantScores[apps][itemd?._id?.sub_section_no] || 'NA' }}
              </td>
              <ng-template #emptyCell>
                <td class="long text-center">NA</td>
              </ng-template>
            </ng-container>
          </tr>
          <tr>
            <th class="headcol" colspan="2" style="background-color: #F8FDEC;"><b>Section
                Score</b></th>
            <td class="long text-center" style=" background-color: #FFFF00;" *ngFor="let apps of app_ids;let i=index;">
              <b *ngIf="scores[apps] && scores[apps][section_no] !== undefined">
                {{ scores[apps][section_no] || 'NA' }}
              </b>
            </td>
          </tr>
          <tr>
            <th class="headcol" colspan="2" style="background-color: #F8FDEC;"><b>Total
                Score</b></th>
            <td class="long text-center" style=" background-color: #92D050;" *ngFor="let apps of app_ids;let i=index;">
              <b *ngIf="scores[apps] && scores[apps] !== undefined && scores[apps] !== 0">
                {{ scores[apps]['overallPercentage'] | number: '1.1-2' }}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="mdl-header">
        <h5 class="modal-title" id="staticBackdropLabel">Sub-sections</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="bb-0 list-style-none sub-checklist">
          <li class="nav-tabs-item" role="presentation" data-dismiss="modal" *ngFor="let item of section | slice:6"
            (click)="getscore(item)" [class]="item==section_no?'active':''">
            <a style="font-size: 14px;">
              <span>Sec - {{ item }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
