import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { TosterService } from '../services/toster.service';
import { ToastrService } from 'ngx-toastr';
import { CoApplicantService } from '../services/co-applicant.service';
@Component({
  selector: 'app-final-score-report',
  templateUrl: './final-score-report.component.html',
  styleUrls: ['./final-score-report.component.css'],
})
export class FinalScoreReportComponent implements OnInit {
  applicant_id: any;
  section: any[] = [];
  criteria: any;
  app_email: any;
  questions: any;
  subsection: any;
  answer: any;
  public model: any = {};
  modall: any = [];
  public modelscore: any = {};
  modallscore: any = [];
  udata: any;
  sec_done: any = [];
  section_no: any;
  allocated_id: any;
  assessor_data: any;
  subSections: any = [];
  assessment_data: any;
  all_scores: any = [];
  scoreOne: any;
  SubSecmodel: any = {};
  HighScrmodel: any = {};
  disableStatus: any = {};
  finalScore: any = [];
  tl: boolean = false;
  conper: any = 0;
  highScore: any = 0;
  count: any = 0;
  disableddata: any;
  arrSubSec: any = [];
  activeTab: string = 'applicant';
  averagePerct: any;
  summaryScore: any[] = [];
  percentages: any = 0;
  notApplicables: any = [];
  notAppls: any = {};
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private coapp: CoApplicantService
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.applicant_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.allocated_id = this._Activatedroute.snapshot.paramMap.get('_id');
    this.applicant
      .GetAdminApplicantSingle(this.applicant_id)
      .subscribe((data: any) => {
        this.tl = this.udata._id == data.applicanData[0].teamleader;
        if (
          data.applicanData[0].criteria != '' &&
          data.applicanData[0].userStatus === true
        ) {
          this.criteria = [data.applicanData[0].criteria];
          this.app_email = data.applicanData[0].email;
          this.quest
            .sectionGroupbyCriteria({
              criteria: this.criteria[0],
            })
            .subscribe((elem: any) => {
              this.section = elem;
              this.coapp.listCoApplicant(data.applicanData[0]._id).subscribe((resp: any) => {
                if (resp.result.length) {
                  let criterias = resp.result[0].co_criteria;
                  this.criteria = this.criteria.concat(criterias)
                }
                this.spinner.hide();
              });
            });
        } else {
        }
      });
  }
  getscore(sec: any) {
    let criteria = this.criteria[0]
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.quest
      .getAssessment({
        criteria: criteria,
        applicant_id: this.applicant_id,
        section_no: sec,
      })
      .subscribe((itemassm: any) => {
        this.assessment_data = itemassm;
        this.all_scores = [];
        this.assessment_data.map((item_scr: any) => {
          this.getQuestion(sec, item_scr);
        });
        if (this.assessment_data.length === 0) {
          this.getQuestion(sec, []);
        }
      });
  }
  getQuestion(sec: any, item_scr: any, empty: any = false) {
    let criteria = this.criteria[0]
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show();
    this.conper = 0;
    this.highScore = 0;
    this.count = 0;
    this.section_no = sec;
    this.finalScore = [];
    this.quest
      .getAssessmentScore({
        allocation_id: this.allocated_id,
        applicant_id: this.applicant_id,
        assessment_id: item_scr._id,
      })
      .subscribe((all_items: any) => {
        this.notAppls = {}
        this.notApplicables = [];
        all_items.forEach((asse: any) => {
          this.notApplicables.push(asse.applicable[0]);
        })
        this.notApplicables.reduce((acc: any, obj: any) => {
          Object.keys(obj).forEach(key => {
            if (!acc[key] || obj[key] === true) {
              acc[key] = obj[key];
            }
          });
          return acc;
        }, this.notAppls);
        this.all_scores.push(all_items);
        this.quest
          .getSubSection({
            criteria: criteria,
            section_no: sec,
          })
          .subscribe((itemQ: any) => {
            this.subSections = itemQ;
            this.quest
              .getConsensusScore({
                allocation_id: this.allocated_id,
                criteria: criteria,
                section_no: this.section_no,
              })
              .subscribe((itemvalue: any) => {
                if (itemvalue.length == 0) {
                  this.subSections.map((ssItem: any) => {
                    this.SubSecmodel[ssItem._id.sub_section_no] = '00';
                  });
                } else {
                  this.SubSecmodel = itemvalue.con_score[0].Conscore[0] || '00';
                  for (var el in this.SubSecmodel) {
                    if (this.SubSecmodel.hasOwnProperty(el)) {
                      this.conper += parseFloat(this.SubSecmodel[el]);
                    }
                  }
                }
                this.quest
                  .getHighScore({
                    criteria: criteria,
                    section_no: this.section_no,
                  })
                  .subscribe((hitem: any) => {
                    this.HighScrmodel = {};
                    this.finalScore = [];
                    let scores = hitem[0].score[0];
                    let section_name = Object.keys(scores)[0];
                    for (const key in hitem[0].score[0][section_name]) {
                      if (hitem[0].score[0][section_name].hasOwnProperty(key)) {
                        const newKey = `${section_name}.${key}`;
                        const nk = parseInt(key)
                        const anotherNewKey = `${section_name}${nk}`;
                        const aMoreNewKey = `${section_name}`;
                        const oneMoreNewKey = `${section_name} ${nk}`;
                        this.HighScrmodel[newKey] = hitem[0].score[0][section_name][key];
                        this.HighScrmodel[anotherNewKey] = hitem[0].score[0][section_name][key];
                        this.HighScrmodel[aMoreNewKey] = hitem[0].score[0][section_name][key];
                        this.HighScrmodel[oneMoreNewKey] = hitem[0].score[0][section_name][key];
                      }
                    }
                    // this.highScore = Object.values(hitem[0].score[0][section_name]).reduce((accumulator: number, currentValue) => accumulator + Number(currentValue), 0);
                    this.highScore = 0;
                    this.count = 0;
                    this.percentages = 0;
                    this.subSections.forEach((itemd: any) => {
                      let cnt = ((this.SubSecmodel[itemd._id.sub_section_no] / 100) * this.HighScrmodel[itemd._id.sub_section_no]) || 0
                      this.count += cnt ? cnt : 0;
                      if(!this.notAppls[itemd._id.sub_section_no]) {
                        this.highScore += this.HighScrmodel[itemd._id.sub_section_no]
                      }
                      this.percentages += this.SubSecmodel[itemd._id.sub_section_no] || 0
                      this.finalScore.push({
                        subsectionno: itemd._id.sub_section_no,
                        highestscore: this.HighScrmodel[itemd._id.sub_section_no],
                        score: this.isNum(this.SubSecmodel[itemd._id.sub_section_no]) ? ((this.SubSecmodel[itemd._id.sub_section_no] / 100) * this.HighScrmodel[itemd._id.sub_section_no]).toFixed(1) : this.SubSecmodel[itemd._id.sub_section_no],
                        consensusscore: this.SubSecmodel[itemd._id.sub_section_no] || 0,
                        chkStatus: this.notAppls[itemd._id.sub_section_no],
                      })
                    })
                  });
                this.spinner.hide();
              });
          });
      });
  }
  checkValue(secno: string, i: any): boolean {
    var test = false;
    var data = this.all_scores;
    data.forEach((item: any) => {
      if (item[0]?.applicable[0][secno]) {
        if (i == 0 || i == '0' || i == '' || i == 0.0) {
          test = true;
          this.updateValues(secno);
          this.sec_done.push(secno);
        }
      }
    });
    this.sec_done.push(secno);
    return test;
  }
  updateValues(ssno: any) {
    this.conper = 0;
    this.highScore = 0;
    this.count = 0;
    this.HighScrmodel[ssno] = 0;
    this.SubSecmodel[ssno] = 0;
    // if (true) {
    this.arrSubSec.push(ssno)
    // }
    // else {
    //   var index = this.arrSubSec.findIndex(function (o: any) {
    //     return o === ssno;
    //   });
    //   if (index !== -1) this.arrSubSec.splice(index, 1);
    // }
    this.finalScore.map((itemm: any) => {
      if (itemm.subsectionno === ssno) {
        itemm.score = 0;
        itemm.highestscore = 0;
        itemm.consensusscore = 0;
        itemm.chkStatus = true;
      }
    })
  }
  generateScore() {
    let criteria = this.criteria[0]
    if (this.activeTab == 'coapplicant') {
      criteria = this.criteria[1];
    }
    Swal.fire({
      title: '<strong>Final Score</strong>',
      icon: 'success',
      html:
        'We have generated score of <b>Section ' + this.section_no + '</b><p>Not Applicable Sub Sections: ' + this.arrSubSec + '</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Generate',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText: 'Cancel',
      cancelButtonAriaLabel: 'Thumbs down',
    }).then((result) => {
      if (result.isConfirmed) {
        this.quest
          .saveGeneratedScore({
            applicant_id: this.applicant_id,
            allocation_id: this.allocated_id,
            criteria: criteria,
            section_no: this.section_no,
            finalscore: this.finalScore,
            scoreBy: this.udata.email,
          })
          .subscribe((gitem: any) => {
            Swal.fire('Score Generated!', '', 'success');
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  }
  switchTab(userType: any) {
    let criteria = this.criteria[0];
    if (userType == 'coapplicant') {
      criteria = this.criteria[1];
    }
    this.spinner.show();
    this.section_no = '';
    this.activeTab = userType;
    this.subSections = []
    this.quest
      .sectionGroupbyCriteria({
        criteria: criteria,
      })
      .subscribe((elem: any) => {
        this.section = elem;
        this.spinner.hide();
      });
  }
  sumOf(array: any[]) {
    return array.reduce((accumulator: number, currentValue) => accumulator + Number(currentValue), 0);
  }
  isNum(item: any) {
    return item === +item;
  }
}
