import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class TosterService {
  ngToastr: any = {};
  constructor(private toastr: ToastrService) { }
  showSuccess(message: string) {
    this.toastr.success(message)
    
  }
  showSuccessMsg(message: string) {
    this.toastr.clear();
    this.toastr.success(message)
    this.ngToastr.options = {
      "timeOut": "5000",
    }
  }
  showError(message: string) {
    this.toastr.error(message)
    
  }
  showInfo(message: string) {
    this.toastr.info(message)
  }
  showWarning(message: string) {
    this.toastr.warning(message)
  }
}
