import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicantService } from '../services/applicant.service';
import { QuestionService } from '../services/question.service';
import { TosterService } from '../services/toster.service';
@Component({
  selector: 'app-grand-total',
  templateUrl: './grand-total.component.html',
  styleUrls: ['./grand-total.component.css'],
})
export class GrandTotalComponent implements OnInit {
  applicant_id: any;
  section: any;
  criteria: any;
  app_email: any;
  questions: any;
  subsection: any;
  answer: any;
  public model: any = {};
  modall: any = [];
  public modelscore: any = {};
  modallscore: any = [];
  udata: any;
  section_no: any;
  allocation_id: any;
  assessor_data: any;
  subSections: any;
  assessment_data: any;
  all_scores: any = [];
  scoreOne: any;
  SubSecmodel: any = {};
  HighScrmodel: any = {};
  tl: any;
  conper: any = 0;
  highScore: any = 0;
  count: any = 0;
  record: any;
  totalHS: any = 0;
  totalS: any = 0;
  totalCS: any = 0;
  constructor(
    private router: Router,
    private quest: QuestionService,
    private applicant: ApplicantService,
    private _Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: TosterService
  ) { }
  ngOnInit(): void {
    // this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    let email = this.udata.email;
    this.allocation_id = this._Activatedroute.snapshot.paramMap.get('id');
    this.quest
      .getGeneratedScoreByAssessor(
        { email: this.udata.email },
        this.allocation_id
      )
      .subscribe((item: any) => {
        this.record = this.getUniqueListBy(item);
        this.record.map((it: any) => {
          it.finalscore.map((it_num: any) => {
            if(!it_num.chkStatus) {
              this.totalHS += it_num.highestscore && parseFloat(it_num.highestscore) ? parseFloat(it_num.highestscore) : 0;
              this.totalS += it_num.score && parseFloat(it_num.score) ? parseFloat(it_num.score) : 0;
              this.totalCS += it_num.consensusscore && parseFloat(it_num.consensusscore) ? parseFloat(it_num.consensusscore) : 0;
            }
          });
        });
        this.spinner.hide();
      });
  }
  getUniqueListBy(arr: any) {
    return [
      ...new Map(arr.map((item: any) => [item.section_no, item])).values(),
    ];
  }
  getHighestscore(arr: any, field: any) {
    let hs = 0;
    arr.map((item: any) => {
      if(!item.chkStatus){
        hs += parseFloat(item[field]);
      }
    });
    return hs;
  }
  getscore(arr: any, field: any) {
    let hs = 0;
    arr.map((item: any) => {
      if(!item.chkStatus){
        hs += parseFloat(item[field]);
      }
    });
    return hs;
  }
  getconscore(arr: any, field: any) {
    let hs = 0;
    arr.map((item: any) => {
      if(!item.chkStatus){
        hs += parseFloat(item[field]);
      }
    });
    return hs;
  }
}
