import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuestionService } from '../services/question.service';
import { AllocationService } from '../services/allocation.service';
import { TosterService } from '../service/toster.service'

@Component({
  selector: 'app-sector-tally-report',
  templateUrl: './sector-tally-report.component.html',
  styleUrls: ['./sector-tally-report.component.css']
})
export class SectorTallyReportComponent implements OnInit {
  @ViewChild('dataTable', { static: false }) table!: ElementRef;
  criteria: any;
  applicants: any = {};
  criteriaArray: any = [];
  counted_total: any = {};
  sector: any = [];
  app_ids: any = {};
  headers: any = {
    'Criteria 11': {
      mapping: [
        'Laboratory Management',
        'Leadership and Best Pratices',
      ],
      section: [
        'B',
        'C'
      ]
    },
    'Criteria 2A for SMB': {
      mapping: [],
      section: []
    },
    'Criteria 2 for SMB': {
      mapping: [
        'PRP',
        'HACCP',
        'FSMS',
        'Sector Specific GMP',
        'Change Mgmt'
      ],
      section: [
        'B1',
        'B2',
        'B3',
        'C',
        'D'
      ]
    },
    'Criteria 3 Large': {
      mapping: [
        'PRP',
        'HACCP',
        'FSMS',
        'Sector Specific GMP',
        'Change Mgmt'
      ],
      section: [
        'B1',
        'B2',
        'B3',
        'C',
        'D'
      ]
    },
    'Criteria 3 for SMB': {
      mapping: [
        'PRP',
        'HACCP',
        'FSMS',
        'Sector Specific GMP',
        'Change Mgmt'
      ],
      section: [
        'B1',
        'B2',
        'B3',
        'C',
        'D'
      ]
    },
    'Criteria 4': {
      mapping: [
        'Social & Regulatory Requirement',
        'PRP',
        'HACCP',
        'FSMS',
        'Sector Specific GMP',
        'Change Mgmt'
      ],
      section: [
        'A',
        'B1',
        'B2',
        'B3',
        'C',
        'D'
      ]
    },
    'Criteria 5': [],
    'Criteria 7': {
      mapping: [
        'Total Copplicant Score',
        'Total Applicant Score',
        'Management of Partnerships',
        'Strengthening Partner’s Resources',
        'Quality of Tracking & Reviewing data',
      ],
      section: [
        'TotalCo',
        'Total',
        'B4.1',
        'B4.2',
        'B4.3',
      ]
    },
    'Criteria 9': {
      mapping: [
        'Statutory & Regulatory Requirements & Social Compliance',
        'Pre Requisite Programs',
        'HACCP',
        'FSMS',
        'Good Manufacturing Practices (GMP)',
        'Change Management Initiatives'
      ],
      section: [
        'A',
        'B1',
        'B2',
        'B3',
        'C',
        'D'
      ]
    },
  }
  udata: any = {};
  collapsed: any = {};
  recommendation: { [key: string]: { reviewer?: string, prejury?: string, criteria?: string, sector?: string } } = {};
  sectorColor: any = [ "#F1D4D4", "#D1E7FF", "#B8E1C1", "#F2E5D7", "#F1E4B3", "#E0D1F5", "#D3E1F0", "#E5E7A5", "#F1F0C6", "#E5D8B0", "#D8F8E8", "#F7D6B3", "#D4E6D3", "#F3E5F5", "#D1C8E3", "#E7D3B9", "#E8D2B0", "#B5D0F0", "#F5E0A9", "#D9E2C6", "#F0D4A2", "#E1D6D0", "#C7E8D9", "#D8E1FF", "#F5C4A1", "#D1E8B4", "#E6F2D0", "#CFE2D6", "#F4D1D9", "#D4B7F0"];

  constructor(
    public allocation: AllocationService,
    private quest: QuestionService,
    private spinner: NgxSpinnerService,
    private toast: TosterService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.udata = JSON.parse(localStorage.getItem('userdata') || '{}');
    this.quest.viewCritSecs().subscribe((data: any) => {
      this.criteriaArray = Object.keys(data.criteriaSections);
    });
    this.spinner.hide();
  }

  toggleCollapse(item: string) {
    this.collapsed[item] = !this.collapsed[item];
  }

  getCellStyle(value: number): { [key: string]: string } {
    if (value < 80) {
      return {
        'background-color': 'red',
        color: 'white',
      };
    } else {
      return {};
    }
  }

  criteriaChange(criteria: any) {
    this.spinner.show();
    this.counted_total = {};
    this.sector = [];
    this.app_ids = {};
    this.criteria = criteria;
    this.quest.getCalibration({ criteria: criteria, byRank: true }).subscribe((appsls: any) => {
      Object.keys(appsls.applicants).forEach((sector: any) => {
        this.collapsed[sector] = true;
        appsls.applicants[sector].forEach((applicant: any) => {
          this.recommendation[applicant._id] = { reviewer: applicant.recommendation?.reviewer || '', prejury: applicant.recommendation?.prejury || '', criteria: criteria, sector: sector };
        });
      });
      this.applicants = appsls.applicants;
      this.spinner.hide();
    });
  }

  compareDates(dt: any): boolean | undefined {
    let currentDate = new Date();
    let applicantDate = new Date(dt);
    let threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
    return applicantDate.getTime() >= threeYearsAgo.getTime() && applicantDate.getTime() <= currentDate.getTime();
  }

  saveRecommendation(applicant: any) {
    let applicant_id = applicant._id;
    this.quest.saveCalibration({...this.recommendation[applicant_id], applicant_id: applicant_id}).subscribe()
  }

  getRange(number: any): string {
    number = parseFloat(number);
    if (isNaN(number) || number === null || number === undefined) {
      return '0-4';
    } else {
      if (number == 100) return '100';
      const lowerBound = Math.floor(number / 5) * 5;
      const upperBound = lowerBound + 4;
      return lowerBound + '-' + upperBound;
    }
  }

  get sectors() : string[] {
    return Object.keys(this.applicants);
  }

  showSuccess() {
    this.toast.showSuccess(
      'Congratulation!, Recommendations has been saved.'
    );
  }
}
