<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we take you to your CII FACE Account! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body">
    <div class="d-flex align-items-center mar-in-20">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{udata.role.includes('admin') ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
          Assessment
          Information</span>
        <h2 class="mt-0">Generated Scores</h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocation_id" [applicant_id]="this.applicant_id"></app-commonhead>
      </div>
    </div>
    <div class="mar-in-20">
      <p class="pull-right">
        <!-- <button class="btn btn-info" style="margin-inline-end: 10px;" type="button" (click)="viewall(setStatus)">View
          All</button> -->
        <a href="/grand-total/{{ this.allocation_id }}" class="btn btn-primary">Grand Total</a>
      </p>
      <div class="clearfix"></div>
      <table class="panel panel-default table table-bordered nowrap" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>Sno.</th>
            <th>Score Summary</th>
            <th>Section/Sub Section </th>
            <th>Generated By</th>
            <th>Created On</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemd of record;let i=index">
            <td>{{ i+1 }}</td>
            <td>Score Summary Sheet {{ itemd.version }}</td>
            <td>Section No.: {{ itemd.section_no }}<p *ngIf="getSubsections(itemd.finalscore)==''?false:true">Not
                Applicable: {{ getSubsections(itemd.finalscore) }}</p>
            </td>
            <td>{{ itemd.scoreBy }}</td>
            <td>{{ itemd.updated_at|date:'dd-MM-yyyy' }}</td>
            <td>
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle btn-cls" type="button" id="menu1"
                  data-toggle="dropdown"><span class="app-id"> <i class="fa fa-cog" aria-hidden="true"></i>&nbsp;</span>
                  <span class="caret"></span></button>
                <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                  <li role="presentation" (click)="sentScore(itemd.finalscore)"><a role="menuitem" tabindex="-1"
                      data-toggle="modal" data-target="#myModal"> Generated Score
                    </a></li>
                  <!-- <li role="presentation"><a role="menuitem" tabindex="-1"
                      href="/final-score-report/{{ itemd.applicant_id }}/{{ itemd.allocation_id }}">Re-Generate
                      Score</a></li> -->
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Scores</h4>
      </div>
      <div class="modal-body">
        <table class="table table-bordered nowrap">
          <thead>
            <tr>
              <th>Sub Section</th>
              <th>HighScore</th>
              <th>Score</th>
              <th>Consensus Score</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ab of gscore">
              <td>{{ ab.subsectionno }}</td>
              <td>{{ ab.highestscore }}</td>
              <td>{{ ab.score }}</td>
              <td>{{ ab.consensusscore }}</td>
              <td [class]="ab.chkStatus?'text-danger':'text-success'">{{ ab.chkStatus?'Not Applicable':'Applicable' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
