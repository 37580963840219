import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicantService } from 'src/app/services/applicant.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { TosterService } from 'src/app/services/toster.service';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';
import Swal from 'sweetalert2';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-introduction',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
  animations: [
    trigger('formfade', [
      state('inactive', style({
        opacity: 0
      })),
      state('active', style({
        opacity: 1
      })),
      transition('inactive => active', [
        animate(200)
      ])
    ])
  ]
})
export class IntroductionComponent implements OnInit {
  appForm!: FormGroup;
  udata: any;
  email: any;
  applicantSdata: any;
  _id: any;
  statedata: any;
  statedataBill: any;
  submitbutton: boolean = false;
  stepCounter = 0;
  stepArray = [true, false, false, false, false, false, false, false, false];
  stepsDone = [];
  btnStatusDisabled: boolean = false;
  constructor(private _Activatedroute: ActivatedRoute, private toast: TosterService, private locationS: LocationService, private applicantS: ApplicantService, private spinner: NgxSpinnerService, private router: Router) { }
  ngOnInit(): void {
    this.spinner.show();
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    // if (this.udata.email == 'rajesh.kopparapu@varuna.net'){
    //   window.location.href = '/joint-applicant-varuna';
    // }
    if (this.udata.email == 'suresh.garg@zeon.co.in') {
      window.location.href = '/joint-applicant-zeon';
    }
    // if (this.udata.email == 'negi@tirupatilifesciences.com') {
    //   window.location.href = '/joint-applicant-tirupati';
    // }
    if (this.udata.email == 'info@aicofoods.com') {
      window.location.href = '/joint-applicant-aico';
    }
    if (this.udata.role == 'applicant') {
      this.email = this.udata.email;
      this.applicantS
        .GetAdminIntroSinglebyemail(this.email, this.udata._id)
        .subscribe((data: any) => {
          this.applicantSdata = data.applicanData;
          this._id = data.applicanData._id;
          this.getStateByCountryName('India', '');
          this.getStateByCountryNameBill(
            'India',
            ''
          );
        });
    }
    else {
      this.email = this._Activatedroute.snapshot.paramMap.get('id');
      this._id = this._Activatedroute.snapshot.paramMap.get('id');
      this.applicantS
        .GetAdminIntroSingleById(this.email)
        .subscribe((data: any) => {
          this.applicantSdata = data.applicanData;
          if (this.applicantSdata == null) {
            alert('No data saved/submitted by the applicant yet');
            if (this.udata.role != 'assessor')
              window.location.href = '/dashboard';
            if (this.udata.role == 'assessor')
              window.location.href = '/assessors-assess-information';
          }
          this._id = data.applicanData._id;
          this.getStateByCountryName('India', '');
          this.getStateByCountryNameBill(
            'India',
            ''
          );
          (err: any) => {
            alert('No data saved/submitted by the applicant yet');
            if (this.udata.role == 'admin')
              window.location.href = '/dashboard';
            if (this.udata.role == 'applicant')
              window.location.href = '/dashboard';
          }
        });
    }
    this.appForm = new FormGroup({
      email: new FormControl('', noWhitespaceValidator),
      organizationProfile: new FormGroup({
        locationName: new FormControl('', noWhitespaceValidator),
        locationAddress: new FormControl('', noWhitespaceValidator),
        locationCity: new FormControl('', noWhitespaceValidator),
        locationState: new FormControl('', noWhitespaceValidator),
        locationZip: new FormControl('', noWhitespaceValidator),
        //  locationCountry: new FormControl([r.organizationProfile?.locationCountry == '' ? 'India':r.organizationProfile?.locationCountry]),
        scopeAward: new FormControl('', noWhitespaceValidator),
        locationHeadName: new FormControl('', noWhitespaceValidator),
        locationHeadEmail: new FormControl('', noWhitespaceValidator),
        locationHeadMobile: new FormControl('', noWhitespaceValidator),
        locationContactName: new FormControl('', noWhitespaceValidator),
        locationContactEmail: new FormControl('', noWhitespaceValidator),
        locationContactMobile: new FormControl('', noWhitespaceValidator),
        productsServices: new FormControl('', noWhitespaceValidator),
        customers: new FormControl('', noWhitespaceValidator),
        suppliers: new FormControl('', noWhitespaceValidator),
        annualSales: new FormControl('', noWhitespaceValidator),
        distributors: new FormControl('', noWhitespaceValidator),
        competitors: new FormControl('', noWhitespaceValidator),
        people: new FormControl('', noWhitespaceValidator),
        orgStructure: new FormControl('', noWhitespaceValidator),
        collaborations: new FormControl('', noWhitespaceValidator),
        certifications: new FormControl('', noWhitespaceValidator),
        technologies: new FormControl('', noWhitespaceValidator),
        keyChallenges: new FormControl('', noWhitespaceValidator),
        itFacilities: new FormControl('', noWhitespaceValidator),
        overcome: new FormControl('', noWhitespaceValidator),
        processFlow: new FormControl('', noWhitespaceValidator),
        //   locationHeadEmail: new FormControl([r.organizationProfile?.locationHeadEmail])
      }),
      keyFoodSafety: new FormGroup({
        incidents1920: new FormControl('', noWhitespaceValidator),
        incidents2021: new FormControl('', noWhitespaceValidator),
        incidents2122: new FormControl('', noWhitespaceValidator),
        inspection1920: new FormControl('', noWhitespaceValidator),
        inspection2021: new FormControl('', noWhitespaceValidator),
        inspection2122: new FormControl('', noWhitespaceValidator),
        ncr1920: new FormControl('', noWhitespaceValidator),
        ncr2021: new FormControl('', noWhitespaceValidator),
        ncr2122: new FormControl('', noWhitespaceValidator),
      }),
      organizationPerformance: new FormGroup({
        revenue1920: new FormControl('', noWhitespaceValidator),
        revenue2021: new FormControl('', noWhitespaceValidator),
        revenue2122: new FormControl('', noWhitespaceValidator),
        inventory1920: new FormControl('', noWhitespaceValidator),
        inventory2021: new FormControl('', noWhitespaceValidator),
        inventory2122: new FormControl('', noWhitespaceValidator),
        products1920: new FormControl('', noWhitespaceValidator),
        products2021: new FormControl('', noWhitespaceValidator),
        products2122: new FormControl('', noWhitespaceValidator),
        other1920: new FormControl('', noWhitespaceValidator),
        other2021: new FormControl('', noWhitespaceValidator),
        other2122: new FormControl('', noWhitespaceValidator),
      }),
      trendResults: new FormGroup({
        a_1920: new FormControl('', noWhitespaceValidator),
        a_2021: new FormControl('', noWhitespaceValidator),
        a_2122: new FormControl('', noWhitespaceValidator),
        b_1920: new FormControl('', noWhitespaceValidator),
        b_2021: new FormControl('', noWhitespaceValidator),
        b_2122: new FormControl('', noWhitespaceValidator),
        c_1920: new FormControl('', noWhitespaceValidator),
        c_2021: new FormControl('', noWhitespaceValidator),
        c_2122: new FormControl('', noWhitespaceValidator),
        d_1920: new FormControl('', noWhitespaceValidator),
        d_2021: new FormControl('', noWhitespaceValidator),
        d_2122: new FormControl('', noWhitespaceValidator),
        e_1920: new FormControl('', noWhitespaceValidator),
        e_2021: new FormControl('', noWhitespaceValidator),
        e_2122: new FormControl('', noWhitespaceValidator),
        f_1920: new FormControl('', noWhitespaceValidator),
        f_2021: new FormControl('', noWhitespaceValidator),
        f_2122: new FormControl('', noWhitespaceValidator),
        g_1920: new FormControl('', noWhitespaceValidator),
        g_2021: new FormControl('', noWhitespaceValidator),
        g_2122: new FormControl('', noWhitespaceValidator),
        h_1920: new FormControl('', noWhitespaceValidator),
        h_2021: new FormControl('', noWhitespaceValidator),
        h_2122: new FormControl('', noWhitespaceValidator),
        i_1920: new FormControl('', noWhitespaceValidator),
        i_2021: new FormControl('', noWhitespaceValidator),
        i_2122: new FormControl('', noWhitespaceValidator),
        j_1920: new FormControl('', noWhitespaceValidator),
        j_2021: new FormControl('', noWhitespaceValidator),
        j_2122: new FormControl('', noWhitespaceValidator),
        k_1920: new FormControl('', noWhitespaceValidator),
        k_2021: new FormControl('', noWhitespaceValidator),
        k_2122: new FormControl('', noWhitespaceValidator),
        l_1920: new FormControl('', noWhitespaceValidator),
        l_2021: new FormControl('', noWhitespaceValidator),
        l_2122: new FormControl('', noWhitespaceValidator),
        m_1920: new FormControl('', noWhitespaceValidator),
        m_2021: new FormControl('', noWhitespaceValidator),
        m_2122: new FormControl('', noWhitespaceValidator),
        n_1920: new FormControl('', noWhitespaceValidator),
        n_2021: new FormControl('', noWhitespaceValidator),
        n_2122: new FormControl('', noWhitespaceValidator),
        o_1920: new FormControl('', noWhitespaceValidator),
        o_2021: new FormControl('', noWhitespaceValidator),
        o_2122: new FormControl('', noWhitespaceValidator),
        p_1920: new FormControl('', noWhitespaceValidator),
        p_2021: new FormControl('', noWhitespaceValidator),
        p_2122: new FormControl('', noWhitespaceValidator),
        q_1920: new FormControl('', noWhitespaceValidator),
        q_2021: new FormControl('', noWhitespaceValidator),
        q_2122: new FormControl('', noWhitespaceValidator),
        r_1920: new FormControl('', noWhitespaceValidator),
        r_2021: new FormControl('', noWhitespaceValidator),
        r_2122: new FormControl('', noWhitespaceValidator),
        s_1920: new FormControl('', noWhitespaceValidator),
        s_2021: new FormControl('', noWhitespaceValidator),
        s_2122: new FormControl('', noWhitespaceValidator),
        t_1920: new FormControl('', noWhitespaceValidator),
        t_2021: new FormControl('', noWhitespaceValidator),
        t_2122: new FormControl('', noWhitespaceValidator),
        u_1920: new FormControl('', noWhitespaceValidator),
        u_2021: new FormControl('', noWhitespaceValidator),
        u_2122: new FormControl('', noWhitespaceValidator),
        v_1920: new FormControl('', noWhitespaceValidator),
        v_2021: new FormControl('', noWhitespaceValidator),
        v_2122: new FormControl('', noWhitespaceValidator),
      }),
      sectorPractices: new FormGroup({
        a_1: new FormControl('', noWhitespaceValidator),
        a_2: new FormControl('', noWhitespaceValidator),
        a_3: new FormControl('', noWhitespaceValidator),
        a_4: new FormControl('', noWhitespaceValidator),
        b_1: new FormControl('', noWhitespaceValidator),
        b_2: new FormControl('', noWhitespaceValidator),
        b_3: new FormControl('', noWhitespaceValidator),
        b_4: new FormControl('', noWhitespaceValidator),
        c_1: new FormControl('', noWhitespaceValidator),
        c_2: new FormControl('', noWhitespaceValidator),
        c_3: new FormControl('', noWhitespaceValidator),
        c_4: new FormControl('', noWhitespaceValidator),
        d_1: new FormControl('', noWhitespaceValidator),
        d_2: new FormControl('', noWhitespaceValidator),
        d_3: new FormControl('', noWhitespaceValidator),
        d_4: new FormControl('', noWhitespaceValidator),
        e_1: new FormControl('', noWhitespaceValidator),
        e_2: new FormControl('', noWhitespaceValidator),
        e_3: new FormControl('', noWhitespaceValidator),
        e_4: new FormControl('', noWhitespaceValidator),
        f_1: new FormControl('', noWhitespaceValidator),
        f_2: new FormControl('', noWhitespaceValidator),
        f_3: new FormControl('', noWhitespaceValidator),
        f_4: new FormControl('', noWhitespaceValidator),
        g_1: new FormControl('', noWhitespaceValidator),
        g_2: new FormControl('', noWhitespaceValidator),
        g_3: new FormControl('', noWhitespaceValidator),
        g_4: new FormControl('', noWhitespaceValidator),
        h_1: new FormControl('', noWhitespaceValidator),
        h_2: new FormControl('', noWhitespaceValidator),
        h_3: new FormControl('', noWhitespaceValidator),
        h_4: new FormControl('', noWhitespaceValidator),
        i_1: new FormControl('', noWhitespaceValidator),
        i_2: new FormControl('', noWhitespaceValidator),
        i_3: new FormControl('', noWhitespaceValidator),
        i_4: new FormControl('', noWhitespaceValidator),
        j_1: new FormControl('', noWhitespaceValidator),
        j_2: new FormControl('', noWhitespaceValidator),
        j_3: new FormControl('', noWhitespaceValidator),
        j_4: new FormControl('', noWhitespaceValidator),
        k_1: new FormControl('', noWhitespaceValidator),
        k_2: new FormControl('', noWhitespaceValidator),
        k_3: new FormControl('', noWhitespaceValidator),
        k_4: new FormControl('', noWhitespaceValidator),
      }),
      envImpact: new FormGroup({
        a_1: new FormControl('', noWhitespaceValidator),
        a_2: new FormControl('', noWhitespaceValidator),
        a_3: new FormControl('', noWhitespaceValidator),
        b_1: new FormControl('', noWhitespaceValidator),
        b_2: new FormControl('', noWhitespaceValidator),
        b_3: new FormControl('', noWhitespaceValidator),
        c_1: new FormControl('', noWhitespaceValidator),
        c_2: new FormControl('', noWhitespaceValidator),
        c_3: new FormControl('', noWhitespaceValidator),
        d_1: new FormControl('', noWhitespaceValidator),
        d_2: new FormControl('', noWhitespaceValidator),
        d_3: new FormControl('', noWhitespaceValidator),
        e_1: new FormControl('', noWhitespaceValidator),
        e_2: new FormControl('', noWhitespaceValidator),
        e_3: new FormControl('', noWhitespaceValidator),
        f_1: new FormControl('', noWhitespaceValidator),
        f_2: new FormControl('', noWhitespaceValidator),
        f_3: new FormControl('', noWhitespaceValidator),
      }),
      checkpoints: new FormGroup({
        a_1: new FormControl('', noWhitespaceValidator),
        b_1: new FormControl('', noWhitespaceValidator),
        c_1: new FormControl('', noWhitespaceValidator),
        d_1: new FormControl('', noWhitespaceValidator),
        e_1: new FormControl('', noWhitespaceValidator),
        f_1: new FormControl('', noWhitespaceValidator),
        g_1: new FormControl('', noWhitespaceValidator),
        h_1: new FormControl('', noWhitespaceValidator),
        i_1: new FormControl('', noWhitespaceValidator),
        j_1: new FormControl('', noWhitespaceValidator),
        k_1: new FormControl('', noWhitespaceValidator),
        l_1: new FormControl('', noWhitespaceValidator),
        m_1: new FormControl('', noWhitespaceValidator),
        n_1: new FormControl('', noWhitespaceValidator),
        o_1: new FormControl('', noWhitespaceValidator),
        p_1: new FormControl('', noWhitespaceValidator),
        q_1: new FormControl('', noWhitespaceValidator),
      }),
      foodImprovements: new FormGroup({
        a_1: new FormControl('', noWhitespaceValidator),
        a_2: new FormControl('', noWhitespaceValidator),
        a_3: new FormControl('', noWhitespaceValidator),
        a_4: new FormControl('', noWhitespaceValidator),
        b_1: new FormControl('', noWhitespaceValidator),
        b_2: new FormControl('', noWhitespaceValidator),
        b_3: new FormControl('', noWhitespaceValidator),
        b_4: new FormControl('', noWhitespaceValidator),
        c_1: new FormControl('', noWhitespaceValidator),
        c_2: new FormControl('', noWhitespaceValidator),
        c_3: new FormControl('', noWhitespaceValidator),
        c_4: new FormControl('', noWhitespaceValidator),
        d_1: new FormControl('', noWhitespaceValidator),
        d_2: new FormControl('', noWhitespaceValidator),
        d_3: new FormControl('', noWhitespaceValidator),
        d_4: new FormControl('', noWhitespaceValidator),
        e_1: new FormControl('', noWhitespaceValidator),
        e_2: new FormControl('', noWhitespaceValidator),
        e_3: new FormControl('', noWhitespaceValidator),
        e_4: new FormControl('', noWhitespaceValidator),
        f_1: new FormControl('', noWhitespaceValidator),
        f_2: new FormControl('', noWhitespaceValidator),
        f_3: new FormControl('', noWhitespaceValidator),
        f_4: new FormControl('', noWhitespaceValidator),
        g_1: new FormControl('', noWhitespaceValidator),
        g_2: new FormControl('', noWhitespaceValidator),
        g_3: new FormControl('', noWhitespaceValidator),
        g_4: new FormControl('', noWhitespaceValidator),
        h_1: new FormControl('', noWhitespaceValidator),
        h_2: new FormControl('', noWhitespaceValidator),
        h_3: new FormControl('', noWhitespaceValidator),
        h_4: new FormControl('', noWhitespaceValidator),
        i_1: new FormControl('', noWhitespaceValidator),
        i_2: new FormControl('', noWhitespaceValidator),
        i_3: new FormControl('', noWhitespaceValidator),
        i_4: new FormControl('', noWhitespaceValidator),
        j_1: new FormControl('', noWhitespaceValidator),
        j_2: new FormControl('', noWhitespaceValidator),
        j_3: new FormControl('', noWhitespaceValidator),
        j_4: new FormControl('', noWhitespaceValidator),
        k_1: new FormControl('', noWhitespaceValidator),
        k_2: new FormControl('', noWhitespaceValidator),
        k_3: new FormControl('', noWhitespaceValidator),
        k_4: new FormControl('', noWhitespaceValidator),
      }),
      otherSpecific: new FormGroup({
        primaryChemical: new FormControl('', noWhitespaceValidator),
        waterTreatment: new FormControl('', noWhitespaceValidator),
        ingredients: new FormControl('', noWhitespaceValidator),
        regultions: new FormControl('', noWhitespaceValidator),
      }),
    });
  }
  getStateByCountryName(country: any, f: any) {
    let countryVal = f == 'sett' ? country.target.value : country;
    this.locationS.getStateByCountry(countryVal).subscribe((items: any) => {
      this.statedata = items[0].states;
    });
  }
  getStateByCountryNameBill(country: any, f: any) {
    let countryVal = f == 'sett' ? country.target.value : country;
    this.locationS.getStateByCountry(countryVal).subscribe((items: any) => {
      this.statedataBill = items[0].states;
    });
  }
  stepForward(progress: HTMLElement) {
    this.stepArray[this.stepCounter] = false;
    this.stepCounter++;
    if (this.stepCounter > this.stepArray.length - 1) {
      this.stepCounter = this.stepArray.length - 1;
    }
    this.stepArray[this.stepCounter] = true;
    progress.scrollIntoView({ behavior: 'smooth' });
    
    return true;
  }
  stepBackward(progress: HTMLElement) {
    this.stepArray[this.stepCounter] = false;
    this.stepCounter--;
    if (this.stepCounter < 0) {
      this.stepCounter = 0;
    }
    this.stepArray[this.stepCounter] = true;
    progress.scrollIntoView({ behavior: 'smooth' });
    return true;
  }
  stepStatus(curr: number) {
    // let elem_pos=this.stepArray.indexOf(true);
    if (this.stepCounter > curr)
      return true;
    else
      return false;
  }
  saveIntro() {
    this.spinner.show();
    this.appForm.value.userStatus = false;
    this.appForm.value.email = this.email;
    this.btnStatusDisabled = true;
    this.applicantS
      .updateIntroApplicantSingleEmail(this.appForm.value, this._id)
      .subscribe((data: any) => {
        this.toast.showSuccess(
          'Thanks!, Introduction has been saved.'
        );
        this.spinner.hide();
        setTimeout(() => {
          window.location.href = '/introduction';
        }, 1000);
      });
  }
  submitIntro() {
    this.appForm.value.userStatus = true;
    this.appForm.value.email = this.email;
    this.btnStatusDisabled = true;
    if (this.appForm.valid) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to submit your application for review. Once submitted, you will not be able to make any changes.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.applicantS
            .updateIntroApplicantSingleEmail(this.appForm.value, this._id)
            .subscribe((data: any) => {
              this.toast.showSuccess(
                'Thanks!, Introduction has been submitted.'
              );
              this.spinner.hide();
              setTimeout(() => {
                window.location.href = '/applicant-dashboard';
              }, 1000);
            });
        }
        else {
          this.btnStatusDisabled = false;
        }
      });
    }
    else {
      this.submitbutton = false;
      Swal.fire({
        icon: 'error',
        title: 'Please fill all the fields!',
        text: 'All the fields in introduction form are mandatory.'
      })
    }
  }
  public onNamespaceLoaded() {
    this.spinner.hide();
  }
  checkValid() {
  }
}
