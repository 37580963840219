import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AssessorauthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    let user: any = this.authService.loggedInUser()
    if (user) {
      if (user.role == 'assessor') {
        return true;
      }
      if (user.role == 'applicant') {
        this.router.navigate(['/applicant-dashboard']);
        return false;
      }
      if (user.role == 'admin' || user.role == 'super admin') {
        this.router.navigate(['/dashboard']);
        return false;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}
