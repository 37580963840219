<app-header></app-header>
<app-sidenav-applicant></app-sidenav-applicant>
<div id="content" class="app-content" role="main" *ngIf="isLoaded">
  <div class="app-content-body container" *ngIf="finalStatus">
    <!-- <ng-container *ngIf="secArr?.length>0; else elseTemplate">
      <div class="col-sm-12" *ngFor="let item_section of this.secArr">
        <a (click)="goToSection(item_section.section_no, 0)" *ngIf="this.finalStatus">
          <div class="well well-success"><span class="h3">Section {{item_section.section_no }}</span>
            <span class="pull-right" *ngIf="!item_section.emptycount && item_section.emptycount != 0">
              <i class="label label-danger">
                <i class="fa fa-close "></i> Incomplete
              </i>
            </span>
            <span class="pull-right" *ngIf="item_section.emptycount || item_section.emptycount == 0">
              <i class="label label-success" *ngIf="item_section.emptycount == '0'">
                <i class="fa fa-check-circle "> Completed</i>
              </i>
              <i class="label label-danger" *ngIf="item_section.emptycount != '0'">
                <i class="fa fa-close "></i> Pending
              </i>
            </span>
            <br />
            <small class="text-danger"> Click here to move in the Section.</small>
          </div>
        </a>
      </div>
    </ng-container> -->
    <ng-container *ngIf="secArr; else elseTemplate">
      <div class="col-sm-12" *ngFor="let criteria of this.secArr | keyvalue; index as i">
        <h3 *ngIf="criteria.value.length">
          {{criteria.key}}
        </h3>
        <div *ngFor="let section of criteria.value">
          <a (click)="goToSection(section.section_no, i)" *ngIf="this.finalStatus">
            <div class="well well-success"><span class="h3">Section {{ section.section_no }}</span>
              <span class="pull-right" *ngIf="section.emptycount != 0">
                <i class="label label-danger">
                  <i class="fa fa-close "></i> Incomplete {{section.emptycount}}
                </i>
              </span>
              <span class="pull-right" *ngIf="section.emptycount == '0'">
                <i class="label label-success" *ngIf="section.isreviewed">
                  <i class="fa fa-check-circle "> Completed</i>
                </i>
                <i class="label label-danger" *ngIf="!section.isreviewed">
                  <i class="fa fa-close "></i> Review Pending
                </i>
                <!-- <i class="label label-danger" *ngIf="!section.isreviewed">
                  <i class="fa fa-close "></i> Pending
                </i> -->
              </span>
              <br />
              <small class="text-danger"> Click here to move in the Section.</small>
            </div>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div style="margin-left: 10%;">
        <img style="
        margin-top: 87px;
        width: 90%;" src="assets/img/not-found.jpeg" height="400px" width="100%" />
      </div>
    </ng-template>
    <div class="col-sm-12" *ngIf="this.finalStatus">
      <button class="btn btn-success" *ngIf="this.btnDisabled" (click)="onsubmit()">Final Submit</button>
      <br />
      <hr />
      <br />
    </div>
  </div>
  <div class="col-sm-12" *ngIf="!this.finalStatus">
    <div class="col-sm-12 card text-success text-center">
      <h2>Congratulations, Your Assessment has been successfully completed.</h2>
    </div>
  </div>
</div>