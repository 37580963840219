import { AbstractControl } from '@angular/forms';

export function noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    // Check for strings
    if (control.value && typeof control.value === 'string') {
        const isWhitespace = (control.value || '').trim().length === 0;
        return !isWhitespace ? null : { 'whitespace': true };
    }

    return null;
}
