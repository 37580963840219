<div id="cover-spin"></div>
<div class="app app-header-fixed ">
  <!-- header -->
  <header id="header" class="app-header navbar" role="menu">
    <!-- navbar header -->
    <div class="navbar-header bg-light lter">
      <button class="pull-right visible-xs dk" ui-toggle-class="show" target=".navbar-collapse">
        <i class="glyphicon glyphicon-cog"></i>
      </button>
      <button class="pull-right visible-xs" ui-toggle-class="off-screen" target=".app-aside" ui-scroll="app">
        <i class="glyphicon glyphicon-align-justify"></i>
      </button>
      <!-- brand -->
      <a href="#/" class="navbar-brand text-lt" style="background-color: #f6f8f8;">
        <span class="hidden-folded">
          <img src="../../../assets/img/CII-Face.png" alt="bigleap">
        </span>
      </a>
      <!-- / brand -->
    </div>
    <!-- / navbar header -->
    <!-- navbar collapse -->
    <div class="collapse pos-rlt navbar-collapse box-shadow bg-white-only">
      <!-- buttons -->
      <div class="nav navbar-nav hidden-xs">
        <a href="#" class="btn no-shadow navbar-btn g-px-5" ui-toggle-class="app-aside-folded" target=".app">
          <i class="fa fa-dedent fa-fw text"></i>
          <i class="fa fa-indent fa-fw text-active"></i>
        </a>
        <a class="btn no-shadow navbar-btn g-px-0 m-t-10">
        </a>
      </div>
      <!-- / buttons -->
      <!-- nabar right -->
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown">
          <a href="/" class="dropdown-toggle">
            <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
          </a>
        </li>
        <li class="dropdown">
          <a href="#" data-toggle="dropdown" class="dropdown-toggle clear" data-toggle="dropdown">
            <span class="hidden-sm hidden-md">Hi <span
                class="text-uppercase user-profile-name">{{this.username}}</span></span> <b class="caret"></b>
          </a>
          <!-- dropdown -->
          <ul class="dropdown-menu animated fadeInRight w">
            <!-- <li>
                  <a ui-sref="app.page.profile" class="user-update-profile">Profile</a>
               </li> -->
            <li>
              <a ui-sref="app.page.profile" class="user-change-password" data-toggle="modal"
                data-target="#exampleModalCenter">Change Password</a>
            </li>
            <!-- <li>
                  <a ui-sref="app.docs">
                  <span class="label bg-info pull-right">new</span>
                  Help
                  </a>
               </li> -->
            <li class="divider"></li>
            <li>
              <a (click)="logout()">Logout</a>
            </li>
          </ul>
          <!-- / dropdown -->
        </li>
      </ul>
      <!-- / navbar right -->
    </div>
    <!-- / navbar collapse -->
  </header>
  <!-- / header -->
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="width: 35%;">
      <div class="modal-content">
        <div class="modal-header main">
          <h5 class="modal-title" id="exampleModalLongTitle" style="padding-top: 1%;color:black;">Change Password </h5>
        </div>
        <div class="modal-body">
          <form [formGroup]="appForm" (ngSubmit)="changeSubmit()">
            <div class="col-md-12">
              <div class="form-group">
                <label>Enter the current password</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                  <input id="password" placeholder="Password" class="form-control" type="password"
                    formControlName="old_pass" autocomplete="current-password">
                </div>
              </div>
              <div *ngIf="f.old_pass.errors &&  isValidFormSubmitted" class="text-danger text-left mb-1 errorcontrols">
                <div *ngIf="f.old_pass.errors.required"><small>Old Password is
                    required.</small>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Enter new password</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                  <input id="password2  " placeholder="Password" class="form-control" type="password"
                    formControlName="new_pass" autocomplete="new-password">
                </div>
              </div>
              <div *ngIf="f.new_pass.errors &&  isValidFormSubmitted" class="text-danger text-left mb-1 errorcontrols">
                <div *ngIf="f.new_pass.errors.required"><small>New Password is
                    required.</small>
                </div>
                <div *ngIf="f.new_pass.errors.pattern"><small>Minimum 8 character password.</small></div>
                <div *ngIf="f.new_pass.errors.pattern"><small>Use uppercase letters(e.g. A-Z)</small></div>
                <div *ngIf="f.new_pass.errors.pattern"><small>Use lowecase letters(e.g. a-z)</small></div>
                <div *ngIf="f.new_pass.errors.pattern"><small>Use a number(e.g 123)</small></div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Confirm new password</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                  <input id="confirm_password" formControlName="c_pass" placeholder="Confirm Password"
                    class="form-control" type="password" autocomplete="new-password">
                </div>
              </div>
              <div *ngIf="f.c_pass.errors && f.c_pass.errors.required && isValidFormSubmitted"
                class="text-danger text-left mb-1 errorcontrols">
                <div *ngIf="f.c_pass.errors.required"><small>Confirm Password is required.</small>
                </div>
              </div>
              <div *ngIf="f.c_pass.errors && f.c_pass.errors.mustMatch"
                class="text-danger text-left mb-1 errorcontrols">
                <div *ngIf="f.c_pass.errors.mustMatch"><small>New Password and Confirm Password is not match.</small>
                </div>
              </div>
            </div>
            <div class="modal-footer" style="border-top: none;">
              <button type="button" class="btn btn-dark" data-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-danger">Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- mode end -->