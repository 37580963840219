<app-header></app-header>
<app-sidenav *ngIf="this.udata.role!='assessor'"></app-sidenav>
<app-sidenav-assessors *ngIf="this.udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Hold on while we get the data! </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2 style="color: darkseagreen;">Grand Total</h2>
    <div class="card">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th style=" background-color: lemonchiffon;">Section</th>
            <th style=" background-color: lemonchiffon;">Highest Score</th>
            <th style=" background-color: lemonchiffon;">Evaluated Score</th>
            <th style=" background-color: lemonchiffon;">Percentage</th>
            <th style=" background-color: lemonchiffon;">Generated Date</th>
            <!-- <th>Consensus Score</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let itemd of record;let i=index">
            <td>{{ itemd.section_no }}</td>
            <td>{{ this.getHighestscore(itemd.finalscore,'highestscore') }}</td>
            <td>{{ this.getscore(itemd.finalscore,'score') ? (this.getscore(itemd.finalscore,'score')).toFixed(2) : '--'
              }}</td>
            <td>
              <span
                *ngIf="this.getscore(itemd.finalscore,'score') && this.getHighestscore(itemd.finalscore,'highestscore'); else elseBlock">
                {{
                (this.getscore(itemd.finalscore,'score')*100/this.getHighestscore(itemd.finalscore,'highestscore')).toFixed(2)
                +'%'
                }}
              </span>
              <ng-template #elseBlock>
                --
              </ng-template>
            </td>
            <td>{{ itemd.updated_at | date: 'yyyy-MM-dd hh:mm a' }}</td>
            <!-- <td>{{ this.getconscore(itemd.finalscore,'consensusscore') }}</td> -->
          </tr>
          <tr>
            <th>Total</th>
            <th>{{ totalHS }}</th>
            <th>{{ totalS.toFixed(2) }}</th>
            <th>{{ (totalS.toFixed(2)*100/totalHS).toFixed(2) + '%' }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>