import { DatePipe } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicantService } from 'src/app/services/applicant.service';
import { TosterService } from 'src/app/services/toster.service';
import { noWhitespaceValidator } from 'src/shared/whitespace.validator';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dashboard-applicant',
  templateUrl: './dashboard-applicant.component.html',
  styleUrls: ['./dashboard-applicant.component.css'],
})
export class DashboardApplicantComponent implements OnInit {
  udata: any;
  applicantData: any;
  allocationlist: any;
  scheduledata: any = 'pending';
  imageUrl: any;
  myForm!: FormGroup;
  appdoc: any;
  introData: any;
  introStatus: Boolean = false;
  seniorAssessor: any = '';
  uploading: boolean = false;
  uploadProgress: number = 0;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private applicants: ApplicantService,
    private fb: FormBuilder,
    private toast: TosterService,
    private datePipe: DatePipe
  ) {
    this.formInit();
  }
  transformDate(date: any) {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  ngOnInit(): void {
    this.udata = localStorage.getItem('userdata');
    this.udata = JSON.parse(this.udata);
    this.applicants
      .getApplicantSearch({ email: this.udata.email })
      .subscribe((iten: any) => {
        this.applicantData = iten.applicanData[0];
        this.allocationlist = iten.applicanData[0].allocationlistData;
        if (this.allocationlist.length == 0) {
          this.scheduledata = 'Pending';
        } else {
          this.allocationlist.map((item: any) => {
            if (item.teamleader)
              this.applicants.getUsername(item.assessor_id).subscribe((data: any) => {
                this.seniorAssessor = data.user
              })
            this.scheduledata =
              (item.period_from == null
                ? 'Pending'
                : this.transformDate(item.period_from)) +
              (item.period_to == null ? '' : " to " + this.transformDate(item.period_to));
            // this.teamleader=item.
          });
        }
        this.applicants.getAppDoc(this.udata._id).subscribe((datar: any) => {
          this.appdoc = datar
        });
      });
    this.applicants
      .GetAdminIntroSinglebyemail(this.udata.email, this.udata._id)
      .subscribe((data: any) => {
        this.introData = data.applicantData;
        if (this.introData == null) {
          this.introStatus = false;
        }
        else
          this.introStatus = this.introData.userStatus;
        (err: any) => {
          this.introStatus = false;
        }
      });
  }
  formInit() {
    this.myForm = this.fb.group({
      documentname: ['', [Validators.required, noWhitespaceValidator]],
      file: ['', Validators.required],
      applicant_id: ['']
    })
  }
  get f() {
    return this.myForm.controls;
  }
  submit() {
    this.myForm.markAllAsTouched();
    if (this.myForm.valid) {
      this.myForm.value.file = this.imageUrl
      this.myForm.value.applicant_id = this.udata._id;
      this.applicants.uploadDoc(this.myForm.value).subscribe((ress: any) => {
        window.location.reload()
      })
    }
    else {
      this.toast.showError(
        'Please fill in all the required fields'
      );
    }
  }
  handleUpload(e: any) {
    const file = e.target.files[0];
    if (!file) return;
    this.uploading = true;
    this.uploadProgress = 0;
    this.applicants.upload(file).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            this.uploadProgress = Math.round((event.loaded / event.total) * 100);
          }
        } else if (event.type === HttpEventType.Response) {
          this.imageUrl = event.body;
          this.uploading = false;
        }
      },
      error: (err) => {
        console.error('Upload failed', err);
        this.uploading = false;
      }
    });
  }
  deleteFile(id: string, index: number) {
    Swal.fire({
      text: 'Are you sure you want to delete?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.applicants.deleteDoc(id).subscribe((ress: any) => {
          this.toast.showSuccess('Document deleted.')
          this.appdoc.splice(index, 1)
        })
      } else if (result.isDismissed) {
      }
    });
  }
  resetForm() {
    this.myForm.reset();
    this.uploading = false;
    this.uploadProgress = 0;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
