import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-ass-resources',
  templateUrl: './ass-resources.component.html',
  styleUrls: ['./ass-resources.component.css']
})
export class AssResourcesComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
