<app-header></app-header>
<app-sidenav *ngIf="udata.role=='admin' || udata.role=='super admin'"></app-sidenav>
<app-sidenav-assessors *ngIf="udata.role=='assessor'"></app-sidenav-assessors>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container" id="print-section">
    <div class="d-flex align-items-center mar-in-20">
      <div class="col-md-10 my-3">
        <span style="color: #939393;" class="cursor-pointer"
          routerLink="{{udata.role.includes('admin') ? '/list-allocation' : '/assessors-assess-information'}}"><i
            class="fa fa-long-arrow-left" aria-hidden="true"></i>
            Assessment Information
        </span>
        <h2 class="mt-0">Executive Summary</h2>
      </div>
      <div class="col-md-2">
        <app-commonhead [allocation_id]="this.allocationId" [applicant_id]="this.applicantId"></app-commonhead>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="panel">
          <!-- <div class="panel-heading panel-title">
            Executive Summary
          </div> -->
          <div class="panel-body" *ngIf="isValidFormSubmitted">
            <form [formGroup]="executiveForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div>
                      <p class="ComText">Company & Unit name: </p>
                      <span>{{ record[0]?.applicantData[0].firstName }}</span>
                    </div>
                    <div>
                      <p class="ComText">Address: </p>
                      <span>{{ record[0]?.applicantData[0].applicantAddress1 }}</span>
                    </div>
                    <div>
                      <p class="ComText">Size(Large/Medium/Small): </p>
                      <span>{{ record[0]?.applicantData[0].classification }}</span>
                    </div>
                    <div>
                      <p class="ComText">Criteria of Assessment: </p>
                      <span>{{ record[0]?.applicantData[0].criteria }}</span>
                    </div>
                    <div>
                      <p class="ComText">Date in which Operation Started: </p>
                      <span>{{ record[0]?.applicantData[0].operationForm|date:'dd-MM-yyyy'}}</span>
                    </div>
                    <div>
                      <p class="ComText">Date of Assessment: </p>
                      <span>{{ record[0]?.allocationData[0].period_from |date:'dd-MM-yyyy' }} - {{
                        record[0]?.allocationData[0].period_to|date:'dd-MM-yyyy' }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Scope of Application</label>
                    <input formControlName="scope_of_application" class="form-control mb"
                      placeholder="Enter Scope of Application">
                    <label for="exampleInputEmail1">Product</label>
                    <input formControlName="product" class="form-control mb" placeholder="Enter Product">
                    <label for="exampleInputEmail1">Processes</label>
                    <textarea formControlName="processes" class="form-control" placeholder="Enter Processes"></textarea>
                  </div>
                  <p class="ComText">Is the Unit Licensed:
                    <select class="YesNoBtn" formControlName="unit_licensed">
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <p>The Food Safety Award Assessment has been conducted through on site assessment of the unit&#39;s
                      implementation effectiveness of food safety system and practices coupled with the
                      documentation and test records presented to the assessors. There were no additional analysis and
                      testing carried out by the assessors and all findings relate to the samples examined.</p>
                    <p>It may hereby be noted that all information shared by the company and findings by the assessors
                      would be kept confidential by CII FACE and the assessors.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Key Strengths and OFIs on PRPs, HACCP, FSMS, Sector specific GMP,
                      Change Management
                      Initiatives, any significant lapses in Social and Regulatory Compliances if any</label>
                    <angular-editor formControlName="key_strength" [config]="editorConfig"></angular-editor>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">KEY OFIs:</label>
                    <angular-editor formControlName="ofis" [config]="editorConfig"></angular-editor>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Overall Scoring and Maturity band:</label>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>5-9</th>
                          <th>10-19</th>
                          <th>20-29</th>
                          <th>30-39</th>
                          <th>40-49</th>
                          <th>50-59</th>
                          <th>60-69</th>
                          <th>70-79</th>
                          <th>80-89</th>
                          <th>90-95</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><input type="number" min="0" formControlName="scr1" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr2" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr3" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr4" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr5" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr6" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr7" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr8" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr9" class="form-control" /></td>
                          <td><input type="number" min="0" formControlName="scr10" class="form-control" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Conclusion:</label>
                    <textarea formControlName="conclusion" class="form-control"
                      placeholder="Enter Conclusion:"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Team Info:</label>
                    <div class="TeamInfo ">
                      <ul>
                        <li class="ComText" *ngFor="let item of arr">{{ item.assessorData[0].firstName }}
                          <span *ngIf="item.assessorData[0].designation || item.assessorData[0].Organization">
                            ({{ item.assessorData[0].designation }}<span *ngIf="item.assessorData[0].Organization">, </span>{{ item.assessorData[0].Organization }})
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Recommendation of the Assessor Team: (Please identify relevant
                      section)</label>
                    <div class="TeamInfo" *ngIf="criteria == 'Criteria 11'">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault1"
                          value="Award for Outstanding Laboratory Performance" />
                        <label class="form-check-label Label" for="flexRadioDefault1">
                          Fit for "Award for Outstanding Laboratory Performance"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault2"
                          value="Significant Achievement for Laboratory Performance" />
                        <label class="form-check-label Label" for="flexRadioDefault2">
                          Fit for "Significant Achievement for Laboratory Performance"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault3"
                          value="Strong Commitment to Excel in Laboratory Performance" />
                        <label class="form-check-label Label" for="flexRadioDefault3">
                          Fit for "Strong Commitment to Excel in Laboratory Performance"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault4"
                          value="Certificate of Appreciation for Laboratory Performance" />
                        <label class="form-check-label Label" for="flexRadioDefault4">
                          Fit for "Certificate of Appreciation for Laboratory Performance"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault6"
                          value="None of the above" />
                        <label class="form-check-label Label" for="flexRadioDefault6">
                          None of the above
                        </label>
                      </div>
                    </div>
                    <div class="TeamInfo" *ngIf="criteria == 'Criteria 2A for SMB' || criteria == 'Criteria 1'">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault1"
                          value="Gold" />
                        <label class="form-check-label Label" for="flexRadioDefault1">
                          Fit for Gold
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault2"
                          value="Silver" />
                        <label class="form-check-label Label" for="flexRadioDefault2">
                          Fit for Silver
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault3"
                          value="Bronze" />
                        <label class="form-check-label Label" for="flexRadioDefault3">
                          Fit for Bronze
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault6"
                          value="Certificate of appreciation" />
                        <label class="form-check-label Label" for="flexRadioDefault6">
                          Certificate of appreciation
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault6"
                          value="None of the above" />
                        <label class="form-check-label Label" for="flexRadioDefault6">
                          None of the above
                        </label>
                      </div>
                    </div>
                    <div class="TeamInfo" *ngIf="criteria != 'Criteria 11' && criteria != 'Criteria 2A for SMB' && criteria != 'Criteria 1'">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault1"
                          value="Award for Outstanding Performance in Food Safety" />
                        <label class="form-check-label Label" for="flexRadioDefault1">
                          Fit for "Award for Outstanding Performance in Food Safety"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault2"
                          value="Significant Achievement on Food Safety" />
                        <label class="form-check-label Label" for="flexRadioDefault2">
                          Fit for "Significant Achievement on Food Safety"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault3"
                          value="Strong Commitment in Food Safety" />
                        <label class="form-check-label Label" for="flexRadioDefault3">
                          Fit for "Strong Commitment in Food Safety"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault4"
                          value="Strong Commitment in GHP / GMP" />
                        <label class="form-check-label Label" for="flexRadioDefault4">
                          Fit for "Strong Commitment in GHP / GMP"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault5"
                          value="Certificate of Appreciation" />
                        <label class="form-check-label Label" for="flexRadioDefault5">
                          Fit for "Certificate of Appreciation"
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="recommendation"
                          (click)="setradio($event)" name="recommendation" id="flexRadioDefault6"
                          value="None of the above" />
                        <label class="form-check-label Label" for="flexRadioDefault6">
                          None of the above
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button type="button" class="SubmitBtn" (click)="saveExecutiveForm('draft')">Save</button>
                <button type="button" class="SubmitBtn m" (click)="askToSubmit()" *ngIf="udata.role=='admin' || udata.role=='super admin'">Final submit</button>
              </div>
            </form>
          </div>
          <div class="panel-body" *ngIf="isValidFormSubmitted1">
            <button class="btn btn-success m" (click)="exportToPdf()">Export to PDF</button>
            <div id="print-section">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div>
                      <p class="ComText">Company & Unit name: </p>
                      <span>{{ record[0]?.applicantData[0].organizationName }}</span>
                    </div>
                    <div>
                      <p class="ComText">Address: </p>
                      <span>{{ record[0]?.applicantData[0].applicantAddress1 }}</span>
                    </div>
                    <div>
                      <p class="ComText">Size(Large/Medium/Small): </p>
                      <span>{{ record[0]?.applicantData[0].classification }}</span>
                    </div>
                    <div>
                      <p class="ComText">Criteria of Assessment: </p>
                      <span>{{ record[0]?.applicantData[0].criteria }}</span>
                    </div>
                    <div>
                      <p class="ComText">Date in which Operation Started: </p>
                      <span>{{ record[0]?.applicantData[0].operationForm|date:'dd-MM-yyyy'}}</span>
                    </div>
                    <div>
                      <p class="ComText">Date of Assessment: </p>
                      <span>{{ record[0]?.allocationData[0].period_from |date:'dd-MM-yyyy' }} - {{
                        record[0]?.allocationData[0].period_to|date:'dd-MM-yyyy' }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Scope of Application</label>
                    <p>{{ summary.scope_of_application }}</p>
                    <label for="exampleInputEmail1">Product</label>
                    <p>{{ summary.product }}</p>
                    <label for="exampleInputEmail1">Processes</label>
                    <p>{{ summary.processes }}</p>
                  </div>
                  <p class="ComText">Is the Unit Licensed:
                    <span>{{ summary.unit_licensed }}</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <p>The Food Safety Award Assessment has been conducted through on site assessment of the unit&#39;s
                      implementation effectiveness of food safety system and practices coupled with the
                      documentation and test records presented to the assessors. There were no additional analysis and
                      testing carried out by the assessors and all findings relate to the samples examined.</p>
                    <p>It may hereby be noted that all information shared by the company and findings by the assessors
                      would be kept confidential by CII FACE and the assessors.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Key Strengths and OFIs on PRPs, HACCP, FSMS, Sector specific GMP,
                      Change
                      Management
                      Initiatives, any significant lapses in Social and Regulatory Compliances if any</label>
                    <div [innerHTML]="summary.key_strength"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">OFIs:</label>
                    <div [innerHTML]="summary.ofis"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Overall Scoring and Maturity band:</label>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>5-9</th>
                          <th>10-19</th>
                          <th>20-29</th>
                          <th>30-39</th>
                          <th>40-49</th>
                          <th>50-59</th>
                          <th>60-69</th>
                          <th>70-79</th>
                          <th>80-89</th>
                          <th>90-95</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="height:30px ;padding:0px !important" *ngIf="!exporting">
                          <td>{{summary.scr1}}</td>
                          <td>{{summary.scr2}}</td>
                          <td>{{summary.scr3}}</td>
                          <td>{{summary.scr4}}</td>
                          <td>{{summary.scr5}}</td>
                          <td>{{summary.scr6}}</td>
                          <td>{{summary.scr7}}</td>
                          <td>{{summary.scr8}}</td>
                          <td>{{summary.scr9}}</td>
                          <td>{{summary.scr10}}</td>
                        </tr>
                        <tr style="height:30px ;padding:0px !important" *ngIf="exporting">
                          <td [ngStyle]="{'background': summary.scr1 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr2 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr3 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr4 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr5 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr6 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr7 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr8 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr9 ? '#c0c0c0' : ''}"></td>
                          <td [ngStyle]="{'background': summary.scr10 ? '#c0c0c0' : ''}"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Conclusion:</label>
                    <p>{{ summary.conclusion }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Team Info:</label>
                    <div class="TeamInfo ">
                      <ul>
                        <li class="ComText" *ngFor="let item of arr">{{ item.assessorData[0].firstName }} ({{
                          item.assessorData[0].designation }})</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Recommendation of the Assessor Team: {{ summary.recommendation
                      }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
