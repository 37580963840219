import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ApplicantService } from 'src/app/services/applicant.service';
import { DataTableDirective } from 'angular-datatables';
import { LocationService } from 'src/app/services/location.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FinancialsService } from 'src/app/service/financials.service';
@Component({
  selector: 'app-applicant-new-view',
  templateUrl: './applicant-new-view.component.html',
  styleUrls: ['./applicant-new-view.component.css'],
})
export class ApplicantNewViewComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  assessors: any;
  assessorsForm: any;
  isValidFormSubmitted: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  totalApplicant: any = 0;
  totalLOI: any = 0;
  introSubmitted: any = 0;
  totalInstruction: any = 0;
  totalChecklist: any = 0;
  statedata: any;
  sector: any;
  query: any;
  applicante_id: string = '';
finYears: any;
  constructor(
    private applicantS: ApplicantService,
    private locationS: LocationService,
    private _Activatedroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private financialService: FinancialsService
  ) { }
  term: any;
  cc: any = '';
  ngOnInit(): void {
    this.spinner.show();
    this._Activatedroute.paramMap.subscribe((params: any) => {
      this.query = params.get('id');
      this.rerender();
    });
    this.initilizeData();
    this.financialService.getFinYears().subscribe({
      next: (resp: any) => {
        this.finYears = resp.data;
      },
      error: (err: any) => {}
    })
  }
  initilizeData(): void {
    $.fn['dataTable'].ext.search.push(
      (settings: any, data: any, dataIndex: any) => {
        const criteria = parseFloat(data[8]) || ''; // use data for the id column
        if (isNaN(this.cc) || this.cc == criteria) {
          return true;
        }
        return false;
      }
    );
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      processing: true,
      dom: 'lBfrtip',
      data: this.assessors
    };
    this.totalApplicant = 0;
    this.totalLOI = 0;
    this.totalInstruction = 0;
    this.totalChecklist = 0;
    this.locationS.getallstates().subscribe((data: any) => {
      this.statedata = data.result;
    });
    this.applicantS.getSector().subscribe((items: any) => {
      this.sector = items.result;
    });
    this.applicantS.getApplicant().subscribe((data: any) => {
      this.assessors = data.applicanData.reverse();
      this.spinner.hide();
      this.dtTrigger.next();
      this.totalApplicant = this.assessors.length;
      this.assessors.map((item: any) => {
        if (item.userStatus) {
          ++this.totalLOI;
        }
        if (item.Intro && item.Intro.userStatus) ++this.introSubmitted
        // if (item.InstructionData.length == 1) {
        //   ++this.totalInstruction;
        // }
        if (item.finalassessmentsData.length == 1) {
          ++this.totalChecklist;
        }
      });
    });
    this.dtTrigger.next();
  }
  rerender(): void {
    if (this.datatableElement) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn.dataTable.ext.search.pop();
  }
  getData(e: any, field: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (field == 'criteria') {
        dtInstance
          .column(14)
          .search('^' + e.target.value + '$', true, false, true)
          .draw();
        if (e.target.value == '' || !e.target.value) {
          dtInstance.column(14).search('', true, false, true).draw();
        }
      } else if (field == 'state') {
        dtInstance
          .column(16)
          .search('^' + e.target.value + '$', true, false, true)
          .draw();
        if (e.target.value == '' || !e.target.value) {
          dtInstance.column(16).search('', true, false, true).draw();
        }
      } else {
        dtInstance
          .column(15)
          .search('^' + e.target.value + '$', true, false, true)
          .draw();
        if (e.target.value == '' || !e.target.value) {
          dtInstance.column(15).search('', true, false, true).draw();
        }
      }
    });
  }
  compareDates(dt: any, comparingDate: any): boolean | undefined {
    let currentDate = new Date(comparingDate);
    let applicantDate = new Date(dt);
    let threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
    if (applicantDate.getTime() >= threeYearsAgo.getTime() && applicantDate.getTime() <= currentDate.getTime()) {
      return true;
    }
    return false;
  }
  deleteApplicante(id: any): void {
    Swal.fire({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.applicantS.DeleteApplicant(id).subscribe({
          next: (resp: any) => {
            if (resp.success) {
              Swal.fire('Applicante deleted successfully.', '', 'success');
              window.location.reload();
            } else {
              Swal.fire(resp.message, '', 'error');
            }
          },
          error: (err: any) => {
            Swal.fire('Something went wrong!', '', 'error');
          }
        });
      } else if (result.isDismissed) {
      }
    });
  }
}
