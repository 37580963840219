<app-header></app-header>
<app-sidenav-assessors></app-sidenav-assessors>
<div id="content" class="app-content" role="main">
  <div class="app-content-body container">
    <h2>Assessment Information</h2>
    <div class="col-md-12 table-responsive panel">
      <div
        style="padding: 20px;color: black;background: #eb171742;border-radius: 10px;border: 1px solid #ff000069;margin-block: 15px 8px;"
        *ngIf="shownotif">Some hidden data available, they'll be available after final
        submission of checklists.</div>
      <img *ngIf="loading" src="https://i.pinimg.com/originals/3f/2c/97/3f2c979b214d06e9caab8ba8326864f3.gif" />
      <table class="panel panel-default table table-dark nowrap" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th scope="col">Applicant Detail</th>
            <th scope="col">Assessment Schedule</th>
            <th scope="col">Designation</th>
            <th scope="col">Created On</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let allval of AllocationList;let i=index;">
            <td [class]="allval.enableStatus  ? 'blurry-text' : ''">
              <span>Name: {{ allval?.applicantData[0]?.firstName}}</span><br />
              <span>Email: {{ allval?.applicantData[0]?.email}}</span><br />
              <span>{{ allval?.applicantData[0]?.criteria}}, {{ allval?.applicantData[0]?.sector}}</span><br>
              <span>Operation On: {{ allval?.applicantData[0]?.operationForm | date:'dd-MM-yyyy' }}</span><br />
              <span>Rising Star: {{
                this.compareDates(allval?.applicantData[0]?.operationForm)?'Yes':'No' }}</span>
            </td>
            <td [class]="allval.enableStatus  ? 'blurry-text' : ''">
              From:<b> {{ allval?.allocationData[0]?.period_from|date:'dd-MM-yyyy' }} </b><br />
              To:<b> {{ allval?.allocationData[0]?.period_to|date:'dd-MM-yyyy' }}</b>
            </td>
            <td [class]="allval.enableStatus  ? 'blurry-text' : ''">
              <ul class="nav nav-stacked">
                <li class="" *ngIf="allval.teamleader">Team Leader</li>
                <li class="" *ngIf="allval.calibrator">Calibrator</li>
                <li class="" *ngIf="!allval.calibrator && !allval.teamleader">Assessor</li>
              </ul>
            </td>
            <td [class]="allval.enableStatus  ? 'blurry-text' : ''"> {{ allval.created_at|date:'dd-MM-yyyy' }} <br />{{
              allval.allocationliststatus }}</td>
            <td style="display: flex;flex-direction: column;justify-content: center;gap: 10px;align-items: center;">
              <div class="pull-right w-100">
                <button class="btn btn-sm w-100"
                  style="color: #ffffff !important;background-color: #64ae3e;border-color: #569235;" type="button"
                  (click)="updateView(i,allval.enableStatus)">
                  <i [class]="allval.enableStatus  ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                </button>
              </div>
              <div class="dropdown pull-right w-100">
                <button class="btn btn-default w-100 dropdown-toggle btn-cls" type="button" id="menu1"
                  data-toggle="dropdown"><span class="app-id"> <i class="fa fa-cog" aria-hidden="true"></i>&nbsp;</span>
                  <span class="caret"></span></button>
                <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                  <ng-container *ngIf="allval.allocationliststatus=='accepted' && allval.finalassessmentsData.length">
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/view-introduction/{{ allval?.applicantData[0]?._id }}">Introduction Form</a></li>
                    <li role="presentation"><a role="menuitem"
                        (click)="exportbyid(allval?.applicantData[0]?._id,allval.allocation_id)">Export Excel</a></li>
                    <li role="presentation"><a role="menuitem"
                        (click)="exportconsolidatedbyid(allval?.applicantData[0]?._id,allval.allocation_id)">Export
                        Consolidated Excel</a></li>
                    <li role="presentation"><a role="menuitem"
                        (click)="exportconsolidatedwordbyid(allval?.applicantData[0]?._id,allval.allocation_id)">Export
                        Summary Word</a></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/team-allocation/{{ allval.allocation_id }}" *ngIf="allval.teamleader">Manage Assessment
                      </a></li>
                    <li role="presentation" *ngIf="allval?.applicantData[0]?.appdoc">
                      <a role="menuitem" tabindex="-1" href="/view-document/{{ allval?.applicantData[0]?._id }}">View
                        Document</a>
                    </li>
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/executive-summary/{{ allval.allocation_id }}"
                        *ngIf="allval.teamleader||allval.calibrator">Executive Summary</a></li>
                    <li role="presentation">
                      <a role="menuitem" tabindex="-1"
                        href="/assess-checklist/{{ allval?.applicantData[0]?._id }}/{{ allval.allocation_id }}">Assess
                        Applicant </a>
                    </li>
                    <!-- <li role="presentation"><a role="menuitem" tabindex="-1"
                      href="/view-app/{{ allval?.applicantData[0]?._id }}">Applicant LOI</a></li>
                      <li role="presentation"><a role="menuitem" tabindex="-1"
                      href="/view-checklist/{{ allval?.applicantData[0]?._id }}">Applicant Checklist</a></li>
                      -->
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/score-report/{{ allval?.applicantData[0]?._id }}/{{ allval.allocation_id }}">Consensus
                        Score Sheet</a></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/final-score-report/{{ allval?.applicantData[0]?._id }}/{{ allval.allocation_id }}">Score
                        Summary Sheet</a></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/checklist-summary/{{ allval?.applicantData[0]?._id }}/{{ allval.allocation_id }}">Checklist
                        Summary</a></li>
                    <li role="presentation"><a role="menuitem" tabindex="-1"
                        href="/grand-list/{{ allval.allocation_id }}/{{ allval?.applicantData[0]?._id }}">Consolidated
                        List</a></li>
                  </ng-container>
                  <ng-container *ngIf="allval.allocationliststatus=='accepted' && !allval.finalassessmentsData.length">
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1">
                        Introduction Form
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem">
                        Export Excel
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem">
                        Export Consolidated Excel
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem">
                        Export Summary Word
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1" *ngIf="allval.teamleader">
                        Manage Assessment
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)"
                      *ngIf="allval?.applicantData[0]?.appdoc">
                      <a role="menuitem" tabindex="-1">
                        View Document
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1" *ngIf="allval.teamleader||allval.calibrator">
                        Executive Summary
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)"
                      *ngIf="this.checkValidity(allval.allocationliststatus,allval?.allocationData[0]?.period_from,allval?.allocationData[0]?.period_to)">
                      <a role="menuitem" tabindex="-1">
                        Assess Applicant
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1">
                        Consensus Score Sheet
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1">
                        Score Summary Sheet
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1">
                        Checklist Summary
                      </a>
                    </li>
                    <li role="presentation" (click)="notify(allval.applicantData[0].firstName)">
                      <a role="menuitem" tabindex="-1">
                        Consolidated List
                      </a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="allval.allocationliststatus=='pending'">
                    <li role="presentation" *ngIf="allval.allocationliststatus=='pending'"
                      (click)="updateStatus($event, allval._id , 'accepted',allval?.applicantData[0]?.email,allval.teamleader,allval?.applicantData[0]?.firstName)">
                      <a role="menuitem" tabindex="-1">Accept</a>
                    </li>
                    <li role="presentation" *ngIf="allval.allocationliststatus=='pending'"
                      (click)="updateStatus($event, allval._id , 'rejected',allval?.applicantData[0]?.email,allval.teamleader,allval?.applicantData[0]?.firstName)">
                      <a role="menuitem" tabindex="-1">Reject</a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>